import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import axiosInstance from "../services/axiosInstance";
import LoadingAnimation from "../assets/LoadingAnimation.svg";
import DownArrowIcon from '../assets/ExamplesPageAssets/DownArrowIcon.svg';
import { formatCustomDateTime, getStoredValue } from "../services/logics";
import {AccountSPageShimmer} from "../components/DocumentSkeleton"
import { useClickOutsideDropdown } from "../services/emailUtils.js";
const AccountPage = () => {
  const [user, setUser] = useState({
    email: "",
    fullname: "",
    profileImage: "",
    phoneNumber: "",
  });
  const store_id = getStoredValue("storeid");
  const storedata = getStoredValue("storedata");
  const signed_on = getStoredValue("signed-on");
  const user_id = getStoredValue("id");
  const role = getStoredValue("role");
  const API_URL = process.env.REACT_APP_API_URL;
  const [storeData, setStoreData] = useState({
    ownerName: "",
    storeName: "",
    storeURL: "",
  });
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [isEditingStore, setIsEditingStore] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isRoleChanging, setIsRoleChanging] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [inviteListData, setInviteListData] = useState([]);
  const [loadingUserId, setLoadingUserId] = useState(null); // State to track which user is being updated
  const [openDropdownId, setOpenDropdownId] = useState(null); // Track the open dropdown by user ID
  const [isFetchingInvites, setFetchingInvites] = useState(true);
  const dropdownRef = useRef([]);
  // Count the number of admins
  const adminCount = usersData.filter(userData => userData.role_name === 'admin')?.length;
  useEffect(() => {
    const fetchUserData = async () => {
      const firstname = sessionStorage.getItem("firstname")
      ? sessionStorage.getItem("firstname")
      : localStorage.getItem("firstname")
      ? localStorage.getItem("firstname")
      : "";
      const lastname = sessionStorage.getItem("lastname")
      ? sessionStorage.getItem("lastname")
      : localStorage.getItem("lastname")
      ? localStorage.getItem("lastname")
      : "";

      setUser((prev) => ({
        ...prev,
        fullname: `${firstname + " " + lastname}`,
        email: getStoredValue("email"),
        profileImage: getStoredValue("profile"),
      }));

      setStoreData((prev) => ({
        ...prev,
        ownerName: getStoredValue("firstname"),
        storeName: getStoredValue("storename"),
      }));
    };

    fetchUserData();
    fetchStoreUsers();
    { role?.toLowerCase() === "admin" && fetchInviteList(); }
  }, []);

  useClickOutsideDropdown(dropdownRef, setOpenDropdownId, true);

  const handleSendInvite = async () => {
    setSendingInvite(true);
    const email = inputEmail;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email && !emailRegex.test(email)) {
      toast.error("Enter a valid email address");
      setSendingInvite(false);
      return;
    }
    const details = {
      email: email,
      store_id: store_id,
    };
    try {
      const res = await axiosInstance.post(`${API_URL}/v1/invites/send`, details, {
      });

      // Clear the input field after successfully sending the invite
      setInputEmail("");
      // Set inviteSent to true
      setInviteSent(true);
      fetchStoreUsers();
      fetchInviteList();
      // Display success message
      toast.success("Invite sent successfully");

    } catch (error) {
      setInputEmail("");
      toast.error(`${error.response?.data?.detail}`);
    }

    setSendingInvite(false);
  };

  const fetchStoreUsers = async () => {
    setIsLoadingUser(true)
    try {
      const res = await axiosInstance.get(`${API_URL}/v1/stores/${store_id}/users`, {
      });
      setUsersData(res?.data)
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }

    setIsLoadingUser(false);
  };
  const handleRoleChange = async (newRole, userId, storeId) => {
    setLoadingUserId(userId); // Set the loading user ID when starting the role change
    setIsRoleChanging(true)
    try {
      // API call to update the role
      const response = await axiosInstance.put(
        `${API_URL}/v1/stores/${storeId}/users/${userId}?role=${newRole}`
      );
      toast.success('Role updated successfully');
      fetchStoreUsers()

    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsRoleChanging(false)
    setLoadingUserId(null); // Remove loading state after completion

  };

  const fetchInviteList = async () => {
    setFetchingInvites(true)
    try {
      const res = await axiosInstance.get(`${API_URL}/v1/stores/${store_id}/invites`, {
      });
      setInviteListData(res?.data)
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setFetchingInvites(false)

  };

  const handleResendInvite = async (inviteId) => {
    try {
      await axiosInstance.post(`${API_URL}/v1/invites/${inviteId}/resend`);
      toast.success("Invite resent successfully");
      fetchInviteList();
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };

  const handleDeleteInvite = async (inviteId) => {
    try {
      await axiosInstance.delete(`${API_URL}/v1/invites/${inviteId}`);
      fetchInviteList();
      toast.success("Invite canceled successfully");
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };

  const handleRemoveUser = async (userId, storeId) => {
    try {
      await axiosInstance.delete(`${API_URL}/v1/stores/${storeId}/users/${userId}`);
      toast.success("User removed successfully");
      fetchStoreUsers(); // Refresh user data
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };


  return (
    <div className="bg-[#FFFFFF] font-dmsans mb-20">
    <div className={`transition-all duration-300  py-2 ${isSidebarOpen ? "px-[10px] sm:px-4 lg:px-0 lg:ml-[230px] xl:ml-[250px]" : "px-[10px] sm:px-5 lg:px-3 lg:ml-[80px] xl:ml-[80px]"}`}>
    {/* Left-aligned Heading */}
    {location.pathname.includes("accounts-page") && (
      <div className="font-dmsans font-medium text-[#000000] pl-[5px] lg:pl-4 xl:text-[20px]">
        Accounts
      </div>
    )}
    </div>
      <div className={`transition-all duration-300 ${isSidebarOpen ? "lg:ml-[230px]" : "lg:ml-[100px]"} flex justify-center`}>
        <div className="max-w-4xl w-full px-[8px] sm:px-4 py-6">
          <div className="flex flex-col items-center space-y-4">
            {/* Profile Image */}
            <div className="w-full flex justify-center">
              <img
                src={user.profileImage}
                alt="User Avatar"
                className="w-[80px] h-[80px] lg:w-[120px] lg:h-[120px] rounded-full object-cover"
              />
            </div>

            {/* User Settings & Signature */}
            <div className="w-full">
              {/* User Settings */}
              <div className="py-2 mb-2 relative">
                <div className="flex justify-between items-center bg-[#D7FDED85] py-1">
                  <div className="text-base sm:text-lg text-[#1B1A22] font-medium pl-2 ">Store details</div>
                </div>

                {/* Profile Info */}
                <div className="grid grid-cols-1 gap-3 mt-3 relative pl-2">
                  <div className="flex items-center justify-between border-b border-[#868CA233] py-1">
                    <span className="text-sm font-medium text-[#868CA2] w-[30%]">Store ID</span>
                    <div
                      className={`mt-1 text-[#2B2E3A] font-medium sm:text-[15px] min-w-[200px] sm:min-w-[300px] max-w-[70%] pr-1 bg-[#FFFFFF] focus:outline-none border-none
                      text-right text-sm`}
                      contentEditable={isEditingUser}
                      suppressContentEditableWarning={true}
                    >
                      #{storedata}
                    </div>
                  </div>
                  <div className="flex items-center justify-between border-b border-[#868CA233] py-1">
                    <span className="text-sm font-medium text-[#868CA2] w-[30%]">Store name</span>
                    <div
                      className={`mt-1 text-[#2B2E3A] text-right font-medium sm:text-[15px] min-w-[200px] sm:min-w-[300px] max-w-[70%] pr-1  bg-[#FFFFFF] focus:outline-none border-none
                      text-sm`}
                      contentEditable={isEditingStore}
                      suppressContentEditableWarning={true}
                    >
                      {storeData.storeName}
                    </div>
                  </div>
                  <div className="flex items-center justify-between border-b border-[#868CA233] py-1">
                    <span className="text-sm font-medium text-[#868CA2] w-[30%]">Owner name</span>
                    <div
                      className={`mt-1 text-[#2B2E3A] font-medium sm:text-[15px] min-w-[200px] sm:min-w-[300px] max-w-[70%] pr-1  bg-[#FFFFFF] focus:outline-none border-none
                      text-right text-sm`}
                      contentEditable={isEditingStore}
                      suppressContentEditableWarning={true}
                    >
                      {storeData.ownerName}
                    </div>
                  </div>
                  <div className="flex items-center justify-between border-b border-[#868CA233] py-1">
                    <span className="text-sm font-medium text-[#868CA2] w-[30%]">Synced on</span>
                    <div
                      className={`mt-1 text-[#2B2E3A] font-medium sm:text-[15px] min-w-[200px] sm:min-w-[300px] max-w-[70%] pr-1  bg-[#FFFFFF] focus:outline-none border-none
                      text-right text-sm`}
                      contentEditable={isEditingUser}
                      suppressContentEditableWarning={true}
                    >
                      {formatCustomDateTime(signed_on, true)}
                    </div>
                  </div>
                </div>
              </div>

              {/* Signature */}
              <div className="py-2 sm:py-6 relative">
                <div className="flex justify-between  items-center bg-[#D7FDED85] py-1">
                  <div className="text-base sm:text-lg text-[#1B1A22] font-medium pl-2">Store users</div>

                </div>
                <div className="grid grid-cols-1 gap-2 mt-3 pl-2">
                  {/* Invite Users Section */}
                  {role?.toLowerCase() === "admin" && <div className="flex flex-col gap-[8px] mb-2">
                    <h5 className="font-medium text-sm ">Invite users</h5>
                    <div className="flex items-center border border-[#FCFEFF] rounded-md bg-[#F5F7F8] h-[36px] sm:h-[48px]">
                      <input
                        type="email"
                        placeholder="Enter email"
                        className="pl-3 bg-[#F5F7F8] w-[85%] h-full text-[#000000] text-[15px] focus:outline-none"
                        value={inputEmail}
                        onChange={(e) => (setInputEmail(e.target.value), setInviteSent(false))}
                      />
                      <button
                        className="ml-4 h-[30px] text-[#5D5C6A] w-[102px] text-xs sm:text-[15px] rounded-lg bg-white flex justify-center items-center"
                        onClick={handleSendInvite}
                        disabled={sendingInvite}
                      >
                        {sendingInvite ? (
                          <img src={LoadingAnimation} alt="Loading..." className="h-12 w-12" />
                        ) : (
                          "Send invite"
                        )}
                      </button>
                    </div>

                  </div>}
                  {isLoadingUser ?
                   <AccountSPageShimmer items={2}/>
                    :
                  usersData.map((userData, index) => (
                    <div key={index} className="flex justify-between items-center bg-[#FFFFFF] min-h-[52px] lg:h-[60px] w-full">
                      {/* Profile Image */}
                      <div className="flex shrink-0 items-center w-[67%] sm:w-auto">
                        <img
                          src={userData.user.profile_image}
                          alt={``}
                          className="w-7 h-7 lg:h-10 lg:w-10 rounded-full"
                        />
                        <div className="lg:ml-3 ml-2 flex-1">
                          {/* Display Full Name */}
                          <div className="font-medium text-[#000000] text-sm lg:text-base whitespace-normal break-all">
                            {userData.user.first_name} {userData.user.last_name}
                          </div>
                          {/* Display Email */}
                          <div className="text-[#868CA2] text-xs lg:text-[13px] whitespace-normal break-all">{userData.user.email}</div>
                        </div>
                      </div>

                      {/* Role Display and Dropdown */}
                      <div className="relative ml-auto flex-shrink-0" ref={(el) => (dropdownRef.current[index] = el)}>
                        <button
                            onClick={(e) => {
                            e.stopPropagation();
                            if (role?.toLowerCase() === "admin") {
                              setOpenDropdownId(openDropdownId === userData.id ? null : userData.id);
                            }
                          }}
                          className="border border-[#E7EAEE] bg-white text-[#000000] text-xs w-[90px] h-[30px] lg:w-[100px] lg:h-[40px] rounded-md focus:outline-none flex items-center justify-center"
                          disabled={userData.role_name === 'admin' && adminCount === 1 || role?.toLowerCase() === "member"}
                        >
                          {loadingUserId === userData?.id && isRoleChanging ? (
                            <img src={LoadingAnimation} alt="Loading" className="lg:h-12 lg:w-16 h-10 w-10" />
                          ) : (
                            <div className="flex flex-row">
                            <span>{userData.role_name.charAt(0).toUpperCase() + userData.role_name.slice(1)}</span>
                                {!(userData.role_name === 'admin' && adminCount === 1 && userData.user.id === user_id) && (
                                  <img src={DownArrowIcon} alt="" className="ml-[6px]" />)}
                            </div>
                          )}

                        </button>

                        {openDropdownId === userData.id && (
                          <ul className="absolute mt-2 bg-white border border-gray-200 right-0 xl:right-auto rounded-md shadow-md w-40 z-10">
                            {userData.role_name === "admin" && adminCount > 1? (
                              <li
                                onClick={() => {
                                  handleRoleChange("member", userData?.id, userData?.store_id);
                                  setOpenDropdownId(null); 
                                }}
                                className="block px-2 py-2 text-xs text-gray-700 cursor-pointer hover:bg-gray-100"
                              >
                                Change role to member
                              </li>
                            ) : (
                              <li
                                onClick={() => {
                                  handleRoleChange("admin", userData?.id, userData?.store_id);
                                  setOpenDropdownId(null); 
                                }}
                                className="block px-2 py-2 text-xs text-gray-700 cursor-pointer hover:bg-gray-100"
                              >
                                Change role to admin
                              </li>
                            )}
                            <li
                              onClick={() => {
                                handleRemoveUser(userData?.id, userData?.store_id);
                                setOpenDropdownId(null); 
                              }}
                              className="block px-2 py-2 text-xs text-gray-700 cursor-pointer hover:bg-gray-100"
                            >
                              {userData.user.id===user_id ?"Leave Store":"Remove from Store"}
                            </li>
                          </ul>
                        )}
                      </div>

                    </div>
                  ))}

                  {/* Pending Invites Section */}
                  {isFetchingInvites ?
                   <AccountSPageShimmer items={2}/>
                    :
                  inviteListData?.length > 0 && (
                    <div>
                      <div className="grid grid-cols-1 ">
                        {inviteListData.map((invite) => (
                          <div key={invite.id} className="flex justify-between items-center bg-[#FFFFFF] min-h-[52px] lg:h-[60px] w-full ">
                            {/* Display invitee email and pending status */}
                            <div className="flex items-center">
                              <div className="flex-shrink-0 w-7 h-7 lg:h-10 lg:w-10 bg-[#1B1A22] text-uppercase rounded-full flex items-center justify-center text-[#39F6A3] text-base font-medium">
                                {invite.email?.[0] || ''}
                              </div>
                              <div className="lg:ml-3 ml-2 flex flex-col sm:flex-row">
                                <div className="font-medium text-[#868CA2] text-xs lg:text-sm whitespace-normal break-all">
                                  {invite.email}  </div>    <div className="text-[#7B7B7B] text-[10px] lg:text-xs font-normal sm:ml-5 mt-1 sm:mt-0"> Pending
                                </div>
                              </div>
                            </div>

                            {/* Resend and Cancel buttons */}
                           <div className="flex space-x-3 ml-auto">
                              <button
                                className="bg-transparent text-[10px] lg:text-xs border border-[#D0D5DD] text-[#344054] h-[28px] lg:h-[32px] w-[52px] lg:w-[88px] rounded-md"
                                onClick={() => handleDeleteInvite(invite.id)}
                              >
                                Cancel
                              </button>
                              <button
                                className="bg-[#D7FDED] text-[10px] lg:text-xs text-[#1B1A22] h-[28px] lg:h-[32px] w-[52px] lg:w-[88px] rounded-md"
                                onClick={() => handleResendInvite(invite.id)}
                              >
                                Resend
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
