import React, { useState } from "react";
import { toast } from "react-toastify";
import LoadingAnimation from "../../assets/LoadingAnimation.svg";
import { AiOutlineClose } from "react-icons/ai";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
import axiosInstance from "../../services/axiosInstance.js";
import { IoEyeOffOutline,IoEyeOutline  } from "react-icons/io5";

export const ShopifyAccountDetails = ({ closeModal ,fetchStores}) => {
  const [formData, setFormData] = useState({
    storeName: "",
    accessToken: "",
    APIkey: "",
    APIsecret: "",
  });
  const [visibility, setVisibility] = useState({
   accessToken: false,
    APIkey: false,
    APIsecret: false,
  });
  const [isFetching, setIsFetching] = useState(false);
  const [storeValid, setStoreValid] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { storeName, accessToken, APIkey, APIsecret } = formData;

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name === "storeName" && storeValid) {
      setStoreValid(false);
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!storeValid) {
      toast.error("Store name is not validated");
      return;
    }
    const storeDomain = `${storeName}.myshopify.com`;

    try {
      setIsSubmitting(true)
      const response = await axiosInstance.post(
        `${API_URL}/v1/store/shopify/accounts`,
        {
          shop_domain: storeDomain,
          api_key: APIkey,
          api_secret: APIsecret,
          access_token: accessToken,
        }
      );
      fetchStores();
      toast.success("Account details submitted successfully");
      closeModal();
      setIsSubmitting(false)
    } catch (error) {
      toast.error(error.response?.data?.detail || "Error initiating Shopify OAuth");
      setIsSubmitting(false)
    }

    setFormData({
      storeName: "",
      accessToken: "",
      APIkey: "",
      APIsecret: "",
    });
    setStoreValid(false);
  };

  const handleShopNameValidation = async () => {
    setIsFetching(true);
    const storeDomain = `${storeName}.myshopify.com`;
    try {
      const response = await axiosInstance.get(`${API_URL}/v1/store/shopify/verify-shop?shop_domain=${storeDomain}` );
      if (response.status === 200 || response.status === 301) {
        setStoreValid(true);
        toast.success("Store name is valid");
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || "Store name is invalid or request failed");
    }
    setIsFetching(false);
  };

  const toggleVisibility = (field) => {
    setVisibility({ ...visibility, [field]: !visibility[field] });
  };

  return (
    <div className="md:w-[560px] sm:w-[500px] md:h-[394px] font-dmsans min-w-[300px] ">
      <div className="relative flex items-center mb-[30px] md:mb-10">
        <div className="flex justify-between w-full">
          <div className="text-[16px] sm:text-lg font-medium md:ml-2">Add shopify store</div>
          <button className="text-[#2B2E3A] mt-[-8px]" onClick={closeModal}>
            <AiOutlineClose size={18} />
          </button>
        </div>
      </div>

      <div className=" rounded-lg ">
        <form onSubmit={handleOnSubmit} className="w-full flex justify-center">
          <div className="grid grid-cols-1 gap-8">
            <div className="relative flex border-[1px] border-[#000000] rounded md:w-[474px] sm:w-[420px] h-[38px] min-w-[300px]">
              <input
                required
                id="storeName"
                type="text"
                name="storeName"
                placeholder=""
                value={storeName}
                autoComplete="off"
                onChange={handleOnChange}
                disabled={isFetching}
                className="peer appearance-none bg-transparent text-[#000000] leading-tight focus:outline-none focus:shadow-outline sm:w-[240px] md:w-[280px] sm:pl-4 pl-[4px] pr-40 sm:pr-0 text-sm md:text-base"
              />
              <span className="absolute right-16 sm:right-20 text-[#868CA2]  flex px-2 items-center h-full py-0 md:text-sm text-[12px]">
                .myshopify.com
              </span>
              {isFetching ? (
                <div className="flex items-center justify-center absolute right-2 h-full">
                  <img src={LoadingAnimation} alt="loading..." className="text-green-500 md:w-14 md:h-14 w-12 h-12" />
                </div>
              ) : (
                <button
                  type="button"
                  className="absolute right-1 text-[#F3F3F3] bg-[#2B2E3A] sm:w-[80px] w-[60px] flex items-center justify-center h-[80%] mt-1 md:text-sm text-[12px]"
                  onClick={handleShopNameValidation}
                >
                  {storeValid ? <p>Verified</p> : <p>Verify</p>}
                </button>
              )}
              <label
                htmlFor="storeName"
                className="absolute left-3 -top-3 px-1 text-[14px] md:text-base text-[#000000] transition-all bg-[#FFFFFF]"
              >
                Store domain
              </label>
            </div>

            <div className="relative flex border-[1px] border-[#000000] rounded md:w-[474px] sm:w-[420px] h-[38px] min-w-[300px]">
              <input
                id="APIsecret"
                type={visibility.APIsecret ? "text" : "password"}
                placeholder=" "
                name="APIsecret"
                onChange={handleOnChange}
                required
                value={APIsecret}
                autoComplete="off"
                className="peer appearance-none bg-transparent text-[#000000] leading-tight focus:outline-none focus:shadow-outline w-full sm:pl-4 pl-[4px] pr-10 text-sm md:text-base"
              />
              <button
                  type="button"
                  className="absolute right-1  w-[30px] flex items-center justify-center h-[80%] mt-1 text-sm"
                  onClick={() => toggleVisibility("APIsecret")}
                >
                  {visibility.APIsecret ? <IoEyeOffOutline/> : <IoEyeOutline/>}
                </button>
              <label
                htmlFor="APIsecret"
                className="absolute left-3 -top-3 px-1 text-[14px] md:text-base text-[#000000] transition-all bg-[#FFFFFF]"
              >
                API secret
              </label>
            </div>

            <div className="relative flex border-[1px] border-[#000000] rounded md:w-[474px] sm:w-[420px] h-[38px] min-w-[300px]">
              <input
                id="APIkey"
                type={visibility.APIkey ? "text" : "password"}
                name="APIkey"
                placeholder=" "
                onChange={handleOnChange}
                required
                value={APIkey}
                autoComplete="off"
                className="peer appearance-none bg-transparent text-[#000000] leading-tight focus:outline-none focus:shadow-outline w-full sm:pl-4 pl-[4px] pr-10 text-sm md:text-base"
              />
              <button
                  type="button"
                  className="absolute right-1  w-[30px] flex items-center justify-center h-[80%] mt-1 text-sm"
                  onClick={() => toggleVisibility("APIkey")}
                >
                  {visibility.APIkey ? <IoEyeOffOutline/> : <IoEyeOutline/>}
                </button>
              <label
                htmlFor="APIkey"
                className="absolute left-3 -top-3 px-1 text-[14px] md:text-base text-[#000000] transition-all bg-[#FFFFFF]"
              >
                API key
              </label>
            </div>

            <div className="relative flex border-[1px] border-[#000000] rounded md:w-[474px] sm:w-[420px] h-[38px] min-w-[300px]">
              <input
                required
                id="accessToken"
                type={visibility.accessToken ? "text" : "password"}
                name="accessToken"
                value={accessToken}
                autoComplete="off"
                onChange={handleOnChange}
                className="peer appearance-none bg-transparent text-[#000000] leading-tight focus:outline-none focus:shadow-outline w-full sm:pl-4 pl-[4px] pr-10 text-sm md:text-base"
              />
               <button
                  type="button"
                  className="absolute right-1  w-[30px] flex items-center justify-center h-[80%] mt-1 text-sm"
                  onClick={() => toggleVisibility("accessToken")}
                >
                  {visibility.accessToken? <IoEyeOffOutline/> : <IoEyeOutline/>}
                </button>
              <label
                htmlFor="accessToken"
                className="absolute left-3 -top-3 px-1 text-[14px] md:text-base text-[#000000] transition-all bg-[#FFFFFF]"
              >
                Access token
              </label>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                disabled={isSubmitting}
                style={{ boxShadow: "0px 4px 15px 0px #00000026 inset" }}
                className="bg-[#96F3C3] w-[140px] md:text-base text-sm sm:w-[173px] h-[38px] rounded-[8px] text-[#2B2E3A] font-normal md:px-4 focus:outline-none focus:shadow-outline flex items-center justify-center"
              >
                {isSubmitting? (
                  <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                ) : (
                  "Verify & Submit"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
