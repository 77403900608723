import React, { useState } from "react";
import { toast } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
import axiosInstance from "../../services/axiosInstance.js";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

export const CustomIMAPDetails = ({ closeModal,
    handleOAuthSuccess,
    setStoreIdToDelete,
    setSelectedAccountType,
    setConfigSMTPData, configSMTPData,
    showRestoreShopifyModal,
    handleUpdateAccount
}) => {
    const [formData, setFormData] = useState({
        email: configSMTPData?.email || "",
        password: configSMTPData?.password || "",
        imap_host: configSMTPData?.imap_host || "",
        imap_port: configSMTPData?.imap_port || "",
        smtp_host: configSMTPData?.smtp_host || "",
        smtp_port: configSMTPData?.smtp_port || "",
        use_ssl: configSMTPData?.use_ssl || false,
        use_tsl: configSMTPData?.use_tsl || false,
    });

    const [visibility, setVisibility] = useState({
        password: false,
    });
    const API_URL = process.env.REACT_APP_API_URL;
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { email, password, imap_host, imap_port, smtp_host, smtp_port, use_ssl, use_tsl } = formData;

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSecureConnectionChange = (value) => {
        setFormData({
            ...formData,
            use_ssl: value === "SSL",
            use_tsl: value === "TSL",
        });
    };

    const handleSendIMAPAccountDetails = async (e) => {
        e.preventDefault();
        try {
            setIsSubmitting(true)
            const payload = {
                email: email,
                password: password,
                imap_host: imap_host,
                imap_port: imap_port ? Number(imap_port) : null,
                smtp_host: smtp_host,
                smtp_port: smtp_port ? Number(smtp_port) : null,
                use_ssl: use_ssl,
                use_tsl: use_tsl,
            };
            if (!showRestoreShopifyModal) {
                const res = await axiosInstance.post(
                    `${API_URL}/v1/store/imap-smtp/accounts`,
                    payload
                );
                const data = res.data;
                toast.success("Account details submitted successfully");
                setStoreIdToDelete(data?.id)
            }
            setConfigSMTPData(payload);
            setIsSubmitting(false);
            closeModal();
            handleOAuthSuccess();
            setSelectedAccountType("imap_smtp")

        } catch (error) {
            toast.error(error.response?.data?.detail || "Error adding account details");
            setIsSubmitting(false)
        }
    };

    const toggleVisibility = (field) => {
        setVisibility({ ...visibility, [field]: !visibility[field] });
    };

    return (
        <div className="sm:w-[500px] font-dmsans min-w-[300px] mx-3">
            <div className="relative flex items-center mb-[30px] md:mb-6">
                <div className="flex justify-between w-full">
                    <div className="text-[16px] sm:text-lg font-medium">IMAP configuration</div>
                    <button className="text-[#2B2E3A] mt-[-8px]" onClick={closeModal}>
                        <AiOutlineClose size={18} />
                    </button>
                </div>
            </div>

            <div className="rounded-lg ">
                <form onSubmit={handleSendIMAPAccountDetails} className="w-full flex justify-center">
                    <div className="grid grid-cols-1 gap-6 w-full">
                        <div className="relative flex border-[1px] border-[#868CA280] rounded h-[38px] w-full">
                            <input
                                required
                                id="email"
                                type="text"
                                name="email"
                                placeholder=""
                                value={email}
                                autoComplete="off"
                                onChange={handleOnChange}
                                className="peer appearance-none w-full bg-transparent text-[#000000] leading-tight focus:outline-none focus:shadow-outline sm:px-4 pl-[4px] text-sm "
                            />
                            <label
                                htmlFor="email"
                                className="absolute left-3 -top-3 px-1 text-[14px]  text-[#2B2E3A] transition-all bg-[#FFFFFF] font-medium"
                            >
                                Email
                            </label>
                        </div>

                        <div className="relative flex border-[1px] border-[#868CA280] rounded h-[38px] w-full">
                            <input
                                id="password"
                                type={visibility.password ? "text" : "password"}
                                placeholder=" "
                                name="password"
                                onChange={handleOnChange}
                                required
                                value={password}
                                autoComplete="off"
                                className="peer appearance-none bg-transparent text-[#000000] leading-tight focus:outline-none focus:shadow-outline w-full sm:pl-4 pl-[4px] pr-10 text-sm "
                            />
                            <button
                                type="button"
                                className="absolute right-1  w-[30px] flex items-center justify-center h-[80%] mt-1 text-sm"
                                onClick={() => toggleVisibility("password")}
                            >
                                {visibility.password ? <IoEyeOffOutline /> : <IoEyeOutline />}
                            </button>
                            <label
                                htmlFor="password"
                                className="absolute left-3 -top-3 px-1 text-[14px]  text-[#2B2E3A] transition-all bg-[#FFFFFF] font-medium"
                            >
                                Password
                            </label>
                        </div>

                        <div className="flex gap-4 w-full">
                            {/* IMAP Host Input */}
                            <div className="relative flex border-[1px] border-[#868CA280] rounded h-[38px] w-full">
                                <input
                                    id="imap_host"
                                    type="text"
                                    name="imap_host"
                                    placeholder=" "
                                    onChange={handleOnChange}
                                    required
                                    value={imap_host}
                                    autoComplete="off"
                                    className="peer appearance-none w-full bg-transparent text-[#000000] leading-tight focus:outline-none focus:shadow-outline sm:px-4 pl-[4px] text-sm " />
                                <label
                                    htmlFor="imap_host"
                                    className="absolute left-3 -top-3 px-1 text-[14px]  text-[#2B2E3A] transition-all bg-[#FFFFFF] font-medium"
                                >
                                    IMAP host
                                </label>
                            </div>

                            {/* IMAP Port Input */}
                            <div className="relative flex border-[1px] border-[#868CA280] rounded h-[38px] w-[200px]">
                                <input
                                    required
                                    id="imap_port"
                                    type="text"
                                    name="imap_port"
                                    value={imap_port}
                                    autoComplete="off"
                                    onChange={handleOnChange}
                                    className="peer appearance-none w-full bg-transparent text-[#000000] leading-tight focus:outline-none focus:shadow-outline sm:px-4 pl-[4px] text-sm " />
                                <label
                                    htmlFor="imap_port"
                                    className="absolute left-3 -top-3 px-1 text-[14px]  text-[#2B2E3A] transition-all bg-[#FFFFFF] font-medium"
                                >
                                    IMAP port
                                </label>
                            </div>
                        </div>
                        <div className="flex gap-4 w-full">
                            <div className="relative flex border-[1px] border-[#868CA280] rounded h-[38px] w-full">
                                <input
                                    required
                                    id="smtp_host"
                                    type="text"
                                    name="smtp_host"
                                    value={smtp_host}
                                    autoComplete="off"
                                    onChange={handleOnChange}
                                    className="peer appearance-none w-full bg-transparent text-[#000000] leading-tight focus:outline-none focus:shadow-outline sm:px-4 pl-[4px] text-sm "
                                />
                                <label
                                    htmlFor="smtp_host"
                                    className="absolute left-3 -top-3 px-1 text-[14px]  text-[#2B2E3A] transition-all bg-[#FFFFFF] font-medium"
                                >
                                    SMTP host
                                </label>
                            </div>

                            <div className="relative flex border-[1px] border-[#868CA280] rounded h-[38px] w-[200px]">
                                <input
                                    required
                                    id="smtp_port"
                                    type="text"
                                    name="smtp_port"
                                    value={smtp_port}
                                    autoComplete="off"
                                    onChange={handleOnChange}
                                    className="peer appearance-none w-full bg-transparent text-[#000000] leading-tight focus:outline-none focus:shadow-outline sm:px-4 pl-[4px] text-sm "
                                />
                                <label
                                    htmlFor="smtp_port"
                                    className="absolute left-3 -top-3 px-1 text-[14px]  text-[#2B2E3A] transition-all bg-[#FFFFFF] font-medium"
                                >
                                    SMTP port
                                </label>
                            </div>
                        </div>

                        <div className="relative flex flex-col border-[1px] border-[#868CA280] rounded h-[48px] w-full px-3 justify-center">
                            <label
                                htmlFor="secure_connection"
                                className="absolute left-3 -top-3 px-1 text-[14px]  text-[#2B2E3A] transition-all bg-[#FFFFFF] font-medium"
                            >
                                Secure connection type
                            </label>
                            <div className="grid grid-cols-3 gap-4 items-center">
                                {/* SSL Option */}
                                <label className="flex items-center justify-center space-x-2">
                                    <input
                                        type="radio"
                                        name="secure_connection"
                                        value="SSL"
                                        checked={use_ssl === true}
                                        onChange={() => handleSecureConnectionChange("SSL")}
                                        className="appearance-none w-[14px] h-[14px] border-1  border-[#868CA280] rounded-full focus:outline-none checked:bg-emerald-400 checked:ring-1  checked:ring-offset-1 checked:ring-emerald-400"/>
                                    <span className="text-[14px] text-[#000000]">SSL</span>
                                </label>

                                {/* TLS Option */}
                                <label className="flex items-center justify-center space-x-2">
                                    <input
                                        type="radio"
                                        name="secure_connection"
                                        value="TSL"
                                        checked={use_tsl === true}
                                        onChange={() => handleSecureConnectionChange("TSL")}
                                        className="appearance-none w-[14px] h-[14px] border-1  border-[#868CA280] rounded-full focus:outline-none checked:bg-emerald-400 checked:ring-1 checked:ring-offset-1 checked:ring-emerald-400"/>
                                    <span className="text-[14px] text-[#000000]">TSL</span>
                                </label>

                                {/* None Option */}
                                <label className="flex items-center justify-center space-x-2">
                                    <input
                                        type="radio"
                                        name="secure_connection"
                                        value="None"
                                        checked={!use_ssl && !use_tsl}
                                        onChange={() => handleSecureConnectionChange("None")}
                                        className="appearance-none w-[14px] h-[14px] border-1  border-[#868CA280] rounded-full focus:outline-none checked:bg-emerald-400 checked:ring-1 checked:ring-offset-1 checked:ring-emerald-400"/>
                                    <span className="text-[14px] text-[#000000]">None</span>
                                </label>
                            </div>

                        </div>

                        <div className="flex justify-center">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                style={{ boxShadow: "0px 4px 15px 0px #00000026 inset" }}
                                className="bg-[#96F3C3] w-[100px] md:text-base text-sm  h-[30px] rounded-[6px] text-[#2B2E3A] font-normal focus:outline-none focus:shadow-outline flex items-center justify-center text-[12px]"
                            >
                                {isSubmitting ? (
                                    <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
