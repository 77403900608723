import React, { useState } from 'react';

export const StoreInformation = ({ data, onChange }) => (
  <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-4 gap-x-6 font-dmsans">
    <label className="text-[16px] font-medium text-[#2B2E3A]">Store Name</label>
    <textarea
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      placeholder="Store Name"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.storeName || ''}
      onChange={(e) => onChange('storeName', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Business Type</label>
    <textarea
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      placeholder="Business Type"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.businessType || ''}
      onChange={(e) => onChange('businessType', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Contact Email</label>
    <input
      type="email"
      placeholder="Contact Email"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md text-sm"
      value={data.contactEmail || ''}
      onChange={(e) => onChange('contactEmail', e.target.value)}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Support Phone Number</label>
    <input
      type="tel"
      placeholder="Support Phone Number"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md text-sm"
      value={data.supportPhoneNumber || ''}
      onChange={(e) => onChange('supportPhoneNumber', e.target.value)}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Business Address</label>
    <textarea
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      placeholder="Business Address"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.businessAddress || ''}
      onChange={(e) => onChange('businessAddress', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Customer Support Availability</label>
    <textarea
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      placeholder="Customer Support Availability"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.customerSupportAvailability || ''}
      onChange={(e) => onChange('customerSupportAvailability', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Owner/Representative Information</label>
    <textarea
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      placeholder="Owner/Representative Information"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.ownerRepresentativeInfo || ''}
      onChange={(e) => onChange('ownerRepresentativeInfo', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Jurisdiction</label>
    <textarea
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm"
      placeholder="Enter Jurisdiction"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.jurisdiction || ''}
      onChange={(e) => onChange('jurisdiction', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />

  </div>
);


export const PurchasePolicy = ({ data, onChange }) => {
  const [isPaymentDropdownOpen, setIsPaymentDropdownOpen] = useState(false);

  // List of available payment methods for multi-select dropdown
  const paymentMethodsOptions = ["Credit Card", "Debit Card", "PayPal", "Bank Transfer", "Cash on Delivery", "Gift Card"];

  // Handle selection of payment methods
  const handlePaymentMethodChange = (method) => {
    const updatedPaymentMethods = data.paymentMethodsAccepted || [];
    if (updatedPaymentMethods.includes(method)) {
      // Remove method if already selected
      onChange("paymentMethodsAccepted", updatedPaymentMethods.filter(item => item !== method));
    } else {
      // Add method if not selected
      onChange("paymentMethodsAccepted", [...updatedPaymentMethods, method]);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-4 gap-x-4 font-dmsans">
      {/* Order Confirmation Process */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Order Confirmation Process</label>
      <textarea
        placeholder="Describe how and when customers receive order confirmation"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.orderConfirmationProcess || ''}
        onChange={(e) => onChange('orderConfirmationProcess', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      {/* Product Availability Terms */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Product Availability Terms</label>
      <textarea
        placeholder="Specify if products are subject to availability"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.productAvailabilityTerms || ''}
        onChange={(e) => onChange('productAvailabilityTerms', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      {/* Payment Methods Accepted */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Payment Methods Accepted</label>
      <div className="col-span-2 relative text-sm">
        <div
          className="w-full border border-[#D9D9D9] p-2 rounded-md cursor-pointer"
          onClick={() => setIsPaymentDropdownOpen(!isPaymentDropdownOpen)}
        >
          {(data.paymentMethodsAccepted && data.paymentMethodsAccepted.length > 0) || (data.isCustomPaymentMethod && data.customPaymentMethod)
            ? `${data.paymentMethodsAccepted.join(", ")}${data.isCustomPaymentMethod && data.customPaymentMethod ? `, ${data.customPaymentMethod}` : ""}`
            : "Select payment methods"}
        </div>

        {isPaymentDropdownOpen && (
          <div className="absolute z-10 w-full bg-white border border-[#D9D9D9] rounded-md mt-2 max-h-48 overflow-y-auto custom-scrollbar">
            {paymentMethodsOptions.map((method) => (
              <label
                key={method}
                className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
              >
                <input
                  type="checkbox"
                  className="mr-2 cursor-pointer hidden peer"
                  checked={data.paymentMethodsAccepted?.includes(method)}
                  onChange={() => handlePaymentMethodChange(method)}
                />
                <div
                  className={`w-4 h-4 border-1 rounded-sm flex items-center justify-center ${data.paymentMethodsAccepted?.includes(method) ? 'bg-[#39F6A3] border-[#39F6A3]' : 'border-[#D9D9D9]'
                    }`}
                >
                  {data.paymentMethodsAccepted?.includes(method) && (
                    <svg
                      className="w-3 h-4 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 22 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M5 13l4 4L19 7" />
                    </svg>
                  )}
                </div>
                <span className="text-sm ml-2">{method}</span>
              </label>
            ))}
            {/* "Other" Option */}
            <div className="flex flex-col p-2">
              <label className="flex items-center cursor-pointer hover:bg-gray-100">
                <input
                  type="checkbox"
                  className="mr-2 cursor-pointer hidden peer"
                  checked={!!data.isCustomPaymentMethod}
                  onChange={() =>
                    onChange("isCustomPaymentMethod", !data.isCustomPaymentMethod)
                  }
                />
                <div
                  className={`w-4 h-4 border-1 rounded-sm flex items-center justify-center ${data.isCustomPaymentMethod
                    ? 'bg-[#39F6A3] border-[#39F6A3]'
                    : 'border-[#D9D9D9]'
                    }`}
                >
                  {data.isCustomPaymentMethod && (
                    <svg
                      className="w-3 h-4 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 22 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </div>
                <span className="text-sm ml-2">Other</span>
              </label>

              {/* Input field for "Other" option */}
              {data.isCustomPaymentMethod && (
                <input
                  type="text"
                  value={data.customPaymentMethod || ""}
                  onChange={(e) => onChange("customPaymentMethod", e.target.value)}
                  placeholder="Enter Other payment methods"
                  className="w-full border border-[#D9D9D9] p-2 rounded-md mt-2 text-sm"
                />
              )}
            </div>
          </div>
        )}
      </div>

      {/* Order Cancellation Window */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Order Cancellation Window</label>
      <textarea
        placeholder="Specify the time frame in which customers can cancel an order (e.g., 24 hours)"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.orderCancellationWindow || ''}
        onChange={(e) => onChange('orderCancellationWindow', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      {/* Cancellation Process */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Cancellation Process</label>
      <textarea
        placeholder="Steps customers must take to cancel an order"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.cancellationProcess || ''}
        onChange={(e) => onChange('cancellationProcess', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      {/* Non-Cancellable Items */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Non-Cancellable Items</label>
      <textarea
        placeholder="List items that cannot be canceled once ordered"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.nonCancellableItems || ''}
        onChange={(e) => onChange('nonCancellableItems', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      {/* Customer Notification Method */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Customer Notification Method</label>
      <textarea
        placeholder="Describe how customers are notified about cancellations or issues with their order"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.customerNotificationMethod || ''}
        onChange={(e) => onChange('customerNotificationMethod', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />


    </div>
  );
};



export const OrderAndShippingPolicy = ({ data, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const shippingOptions = [
    "Standard",
    "Expedited",
    "Overnight",
    "International"
  ];

  // Toggle shipping method selection
  const handleShippingMethodChange = (method) => {
    const updatedMethods = data.shippingMethods?.includes(method)
      ? data.shippingMethods.filter((item) => item !== method)
      : [...(data.shippingMethods || []), method];
    onChange("shippingMethods", updatedMethods);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-4 gap-x-4 font-dmsans">
      {/* Order Processing Time */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Order Processing Time</label>
      <input
        type="text"
        placeholder="State the time taken to process orders before shipment (e.g., 1-3 business days)"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md text-sm custom-scrollbar"
        value={data.orderProcessingTime || ''}
        onChange={(e) => onChange('orderProcessingTime', e.target.value)}
      />

      {/* Available Shipping Methods */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Available Shipping Methods</label>
      <div className="col-span-2 relative text-sm">
        {/* Dropdown Toggle */}
        <div
          className="w-full border border-[#D9D9D9] p-2 rounded-md cursor-pointer"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          {(data.shippingMethods && data.shippingMethods.length > 0) || (data.isCustomShippingMethod && data.customShippingMethod)
            ? `${data.shippingMethods ? data.shippingMethods.join(", ") : ""}${data.isCustomShippingMethod && data.customShippingMethod ? `${data.shippingMethods && data.shippingMethods.length > 0 ? ", " : ""}${data.customShippingMethod}` : ""}`
            : "Select shipping methods"}
        </div>

        {/* Dropdown Options */}
        {isDropdownOpen && (
          <div className="absolute z-10 w-full bg-white border border-[#D9D9D9] rounded-md mt-2 max-h-48 overflow-y-auto custom-scrollbar">
            {shippingOptions.map((method) => (
              <label
                key={method}
                className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleShippingMethodChange(method)}
              >
                {/* Custom Checkbox */}
                <div
                  className={`w-4 h-4 mr-2 border rounded-sm flex items-center justify-center ${data.shippingMethods?.includes(method) ? 'bg-[#39F6A3] border-[#39F6A3]' : 'border-[#D9D9D9]'
                    }`}
                >
                  {data.shippingMethods?.includes(method) && (
                    <svg
                      className="w-3 h-3 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7" />
                    </svg>
                  )}
                </div>

                {/* Label Text */}
                <span className="text-sm">{method}</span>
              </label>
            ))}
            {/* "Other" Shipping Method Option */}
            <div className="flex flex-col p-2">
              <label className="flex items-center cursor-pointer hover:bg-gray-100">
                <input
                  type="checkbox"
                  className="mr-2 cursor-pointer hidden peer"
                  checked={!!data.isCustomShippingMethod}
                  onChange={() =>
                    onChange("isCustomShippingMethod", !data.isCustomShippingMethod)
                  }
                />
                <div
                  className={`w-4 h-4 border-1 rounded-sm flex items-center justify-center ${data.isCustomShippingMethod ? 'bg-[#39F6A3] border-[#39F6A3]' : 'border-[#D9D9D9]'
                    }`}
                >
                  {data.isCustomShippingMethod && (
                    <svg
                      className="w-3 h-4 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 22 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </div>
                <span className="text-sm ml-2">Other Shipping Method</span>
              </label>

              {/* Input field for "Other" shipping method */}
              {data.isCustomShippingMethod && (
                <input
                  type="text"
                  value={data.customShippingMethod || ""}
                  onChange={(e) => onChange("customShippingMethod", e.target.value)}
                  placeholder="Enter Other shipping methods"
                  className="w-full border border-[#D9D9D9] p-2 rounded-md mt-2 text-sm"
                />
              )}
            </div>

          </div>
        )}
      </div>

      {/* Delivery Timeframes */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Delivery Timeframes</label>
      <textarea
        placeholder="Estimated delivery times for different regions or shipping methods"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md text-sm custom-scrollbar resize-none"
        value={data.deliveryTimeframes || ''}
        onChange={(e) => onChange('deliveryTimeframes', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      {/* Shipping Costs */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Shipping Costs</label>
      <textarea
        placeholder="Describe the shipping fees calculation (e.g., flat rate, location-based)"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.shippingCosts || ''}
        onChange={(e) => onChange('shippingCosts', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />


      {/* International Shipping Policy */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">International Shipping Policy</label>
      <textarea
        placeholder="Specify terms for international shipping, if applicable"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.internationalShippingPolicy || ''}
        onChange={(e) => onChange('internationalShippingPolicy', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      {/* Tracking Availability */}
      <label className="text-[16px] font-medium text-[#2B2E3A]">Tracking Availability</label>
      <textarea
        placeholder="Whether tracking information is provided to customers"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.trackingAvailability || ''}
        onChange={(e) => onChange('trackingAvailability', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      <label className="text-[16px] font-medium text-[#2B2E3A]">Delayed Orders Process</label>
      <textarea
        placeholder="Describe options for customers if their order is delayed (e.g., wait, store credit, cancel for refund)"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.delayedOrdersProcess || ''}
        onChange={(e) => onChange('delayedOrdersProcess', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

    </div>
  );
};



export const ReturnAndRefundPolicy = ({ data, onChange }) => {
  const [selectedRefundMethods, setSelectedRefundMethods] = useState(data.refundMethod || []);
  const [otherRefundMethod, setOtherRefundMethod] = useState(data.otherRefundMethod || '');

  const refundOptions = [
    { label: "Original Payment Method", value: "originalPaymentMethod" },
    { label: "Store Credit", value: "storeCredit" },
    { label: "Gift Card", value: "giftCard" },
    { label: "Other", value: "other" },
  ];

  const handleRefundMethodChange = (value) => {
    let updatedMethods;
    if (selectedRefundMethods.includes(value)) {
      updatedMethods = selectedRefundMethods.filter((method) => method !== value);
    } else {
      updatedMethods = [...selectedRefundMethods, value];
    }
    setSelectedRefundMethods(updatedMethods);
    onChange('refundMethod', updatedMethods);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-4 gap-x-4 font-dmsans">
      <label className="text-[16px] font-medium text-[#2B2E3A]">Return Period</label>
      <input
        type="text"
        placeholder="Enter the number of days allowed for returns (e.g., 30 days)"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md text-sm custom-scrollbar"
        value={data.returnPeriod || ''}
        onChange={(e) => onChange('returnPeriod', e.target.value)}
      />

      <label className="text-[16px] font-medium text-[#2B2E3A]">Item Condition for Return</label>
      <textarea
        placeholder="Specify the required condition for returned items (e.g., unused, original packaging)"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.itemCondition || ''}
        onChange={(e) => onChange('itemCondition', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      <label className="text-[16px] font-medium text-[#2B2E3A]">Refund Method</label>
      <div className="col-span-2 grid gap-y-2 text-sm">
        {refundOptions.map((option) => (
          <label
            key={option.value}
            className={`flex items-center p-2 rounded-md border border-[#D9D9D9] cursor-pointer`}
          >
            <input
              type="checkbox"
              className="mr-2 cursor-pointer hidden peer"
              checked={selectedRefundMethods.includes(option.value)}
              onChange={() => handleRefundMethodChange(option.value)}
            />
            <div
              className={`w-4 h-4 border-1 rounded-sm flex items-center justify-center ${selectedRefundMethods.includes(option.value) ? 'bg-[#39F6A3] border-[#39F6A3]' : 'border-[#D9D9D9]'
                }`}
            >
              {selectedRefundMethods.includes(option.value) && (
                <svg
                  className="w-3 h-4 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 22 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M5 13l4 4L19 7" />
                </svg>
              )}
            </div>
            <span className="text-sm ml-2">{option.label}</span>
          </label>
        ))}

        {/* Show additional input for "Other" option */}
        {selectedRefundMethods.includes("other") && (
          <input
            type="text"
            placeholder="Specify other refund method"
            className="w-full border border-[#D9D9D9] p-2 rounded-md mt-2 text-sm custom-scrollbar"
            value={otherRefundMethod}
            onChange={(e) => {
              setOtherRefundMethod(e.target.value);
              onChange("otherRefundMethod", e.target.value);
            }}
          />
        )}
      </div>


      <label className="text-[16px] font-medium text-[#2B2E3A]">Return Shipping Responsibility</label>
      <textarea
        placeholder="Specify who covers the return shipping costs (e.g., customer or store)"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.shippingResponsibility || ''}
        onChange={(e) => onChange('shippingResponsibility', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      <label className="text-[16px] font-medium text-[#2B2E3A]">Excluded Items</label>
      <textarea
        placeholder="List any items not eligible for return (e.g., gift cards, perishable items)"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.excludedItems || ''}
        onChange={(e) => onChange('excludedItems', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      <label className="text-[16px] font-medium text-[#2B2E3A]">Refund Processing Time</label>
      <textarea
        placeholder="Specify the timeframe for processing refunds (e.g., 7-10 business days)"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.refundProcessingTime || ''}
        onChange={(e) => onChange('refundProcessingTime', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      <label className="text-[16px] font-medium text-[#2B2E3A]">Damages and Issues</label>
      <textarea
        placeholder="Specify how customers should handle defective or damaged items"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.damagesAndIssues || ''}
        onChange={(e) => onChange('damagesAndIssues', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />

      <label className="text-[16px] font-medium text-[#2B2E3A]">Refund Notifications</label>
      <textarea
        placeholder="Specify the process for notifying customers once a refund is processed"
        className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none text-sm custom-scrollbar"
        style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
        value={data.refundNotifications || ''}
        onChange={(e) => onChange('refundNotifications', e.target.value)}
        onInput={(e) => {
          e.target.style.height = "auto";
          e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
        }}
      />
    </div>
  );
};



export const ExchangePolicy = ({ data, onChange }) => (
  <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-4 gap-x-4 font-dmsans">
    <label className="text-[16px] font-medium text-[#2B2E3A]">Eligibility for Exchanges</label>
    <textarea
      placeholder="Define if items are eligible for exchange (e.g., defective, damaged, or incorrect items)"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.eligibility || ''}
      onChange={(e) => onChange('eligibility', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Exchange Period</label>
    <input
      type="text"
      placeholder="Enter the timeframe for eligible exchanges (e.g., 30 days)"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md text-sm"
      value={data.exchangePeriod || ''}
      onChange={(e) => onChange('exchangePeriod', e.target.value)}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Exchange Process</label>
    <textarea
      placeholder="Steps for customers to initiate an exchange"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.exchangeProcess || ''}
      onChange={(e) => onChange('exchangeProcess', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Replacement Options if Out of Stock</label>
    <textarea
      placeholder="Specify options if the replacement item is unavailable (e.g., refund or store credit)"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.replacementOptions || ''}
      onChange={(e) => onChange('replacementOptions', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Shipping Costs for Exchanges</label>
    <textarea
      placeholder="Define if the store covers shipping costs for exchanges on defective or incorrect items"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.shippingCosts || ''}
      onChange={(e) => onChange('shippingCosts', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />
  </div>
);


export const DamagedOrDefectiveItemsPolicy = ({ data, onChange }) => (
  <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-4 gap-x-4 font-dmsans">
    <label className="text-[16px] font-medium text-[#2B2E3A]">Reporting Damaged Items Window</label>
    <textarea
      placeholder="e.g., within 7 days of receipt"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.reportingWindow || ''}
      onChange={(e) => onChange('reportingWindow', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />


    <label className="text-[16px] font-medium text-[#2B2E3A]">Resolution Options</label>
    <textarea
      placeholder="Specify resolution options (e.g., replacement, store credit, refund)"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.resolutionOptions || ''}
      onChange={(e) => onChange('resolutionOptions', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Required Documentation</label>
    <textarea
      placeholder="Specify required documentation (e.g., photos of damage)"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      style={{ minHeight: "80px", maxHeight: "220px", overflowY: "auto" }}
      value={data.requiredDocumentation || ''}
      onChange={(e) => onChange('requiredDocumentation', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 220)}px`;
      }}
    />
  </div>
);


export const ContactInformation = ({ data, onChange }) => (
  <div className="grid grid-cols-1 sm:grid-cols-3 gap-y-4 gap-x-4 font-dmsans">
    <label className="text-[16px] font-medium text-[#2B2E3A]">Customer Support Email</label>
    <input
      type="email"
      placeholder="Enter customer support email"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md text-sm"
      value={data.customerSupportEmail || ''}
      onChange={(e) => onChange('customerSupportEmail', e.target.value)}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Support Phone Number</label>
    <input
      type="tel"
      placeholder="Enter support phone number"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md text-sm"
      value={data.supportPhoneNumber || ''}
      onChange={(e) => onChange('supportPhoneNumber', e.target.value)}
    />

    <label className="text-[16px] font-medium text-[#2B2E3A]">Business Hours</label>
    <textarea
      placeholder="e.g., Monday to Friday, 9 am - 5 pm"
      className="col-span-2 w-full border border-[#D9D9D9] p-2 rounded-md resize-none custom-scrollbar text-sm"
      style={{ minHeight: "40px", maxHeight: "120px", overflowY: "auto" }}
      value={data.businessHours || ''}
      onChange={(e) => onChange('businessHours', e.target.value)}
      onInput={(e) => {
        e.target.style.height = "auto";
        e.target.style.height = `${Math.min(e.target.scrollHeight, 120)}px`;
      }}
    />
  </div>
);

