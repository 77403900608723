import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signin from "./pages/Signin";
import Home from "./pages/Home";
import DraftPage from "./pages/DraftPage";
import DocumentsPage from "./pages/DocumentsPage";
import IntegrationPage from "./pages/IntegrationPage";
import ExamplesPage from "./pages/ExamplesPage";
import ExampleDetailPage from "./pages/ExamplePages/ExampleDetailPage";
import CreateExamplesPage from "./pages/ExamplePages/CreateExamplesPage";
import Layout from "./layouts/ContentLayout";
import AccountPage from "./pages/AccountPage";
import DraftDetailPage from "./pages/DraftDetailPage";
import AdminHome from "./pages/AdminPages/AdminHome";
import ApplicationSetup from "./pages/ApplicationSetup";
import PrivateRoute from "./layouts/PrivateLayout";
import AdminLayout from "./layouts/AdminLayout";
import ErrorPage from "./pages/ErrorPage";
import ProtectedAdminRoutes from "./layouts/ProtectedAdminRoutes";
import AdminDashboard from "./pages/AdminPages/AdminDashboard";
// import AdminSettingsPage from "./pages/AdminPages/AdminSettingsPage";
import AdminUserPage from "./pages/AdminPages/AdminUserPage";
import AdminUsersDetails from "./pages/AdminPages/AdminUsersDetails";
// import AdminAddStorePage from "./pages/AdminPages/AdminAddStorePage";
import AdminStorePage from "./pages/AdminPages/AdminStorePage";
import AdminStorePageDetails from "./pages/AdminPages/AdminStorePageDetails";
import InvitePage from "./pages/InvitePage";
import PageNotFound from "./pages/PageNotFound";
import CreatePolicyPage from "./pages/CreatePolicyPage";
import Error502Page from "./pages/Error502Page";
import { withDevCycleProvider } from "@devcycle/react-client-sdk";

function App() {
  return (
    <div className="">
      <Router>
        {window.location.pathname === ""}
        <Routes>
          <Route path="/signin" element={<Signin />} />
          {/* <Route path="/invite" element={<InvitePage />} /> */}
          <Route path="/" element={<PrivateRoute isAdminRoute={false} />}>
            <Route element={<Layout />}>
              <Route index element={<Home />} />

              <Route path="/emails-page" element={<DraftPage />} />
              <Route
                path="/email-page/detail/:id"
                element={<DraftDetailPage />}
              />

              <Route path="/document-page" element={<DocumentsPage />} />
              <Route path="/document-page/create-policy/:id?" element={<CreatePolicyPage />} />
              <Route path="/integration-page" element={<IntegrationPage />} />
              <Route path="/examples-page" element={<ExamplesPage/>} />
              <Route path="/create-example/:id?" element={<CreateExamplesPage />} />
              <Route path="/example/:id?" element={<ExampleDetailPage />} />
              <Route path="/accounts-page" element={<AccountPage />} />
              <Route path="/welcome-page/:status?" element={<ApplicationSetup />} />
            </Route>
          </Route>
          <Route path="/admin" element={<ProtectedAdminRoutes />}>
            <Route element={<AdminLayout />}>
              <Route path="" element={<AdminHome />}></Route>
              <Route path="dashboard-page" element={<AdminDashboard />} />
              {/* <Route path="user-page" element={<AdminUserPage />} /> */}
              {/* <Route
                path="user-page/details/:id"
                element={<AdminUsersDetails />}
              /> */}
              <Route path="store-page" element={<AdminStorePage />} />
              {/* <Route
                path="store-page/add-store"
                element={<AdminAddStorePage />}
              /> */}
              <Route
                path="store-page/details/:id"
                element={<AdminStorePageDetails />}
              />
              {/* <Route path="settings-page" element={<AdminSettingsPage />} /> */}
            </Route>
          </Route>
          <Route path="/502-error" element={<Error502Page />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default withDevCycleProvider({
  sdkKey: process.env.REACT_APP_DEV_CYCLE_SDK_KEY,
  options: {
    enableEdgeDB: true, // Use EdgeDB for faster flag fetching
    logLevel: "debug",  // Log detailed SDK information
  },
})(App);