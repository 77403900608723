import axios from "axios";
import { setInBothStorages } from "./logics";
const API_URL = process.env.REACT_APP_API_URL;

export const googleLogin = async (code, hostname) => {

  let res = await axios.get(`${API_URL}/v1/auth/google/callback?code=${code}`, {
    withCredentials: true
  });

  setInBothStorages('email', res.data.email);
  setInBothStorages('token', res.data.token);
  setInBothStorages('expiry_in', res.data.expires_in);
  setInBothStorages('is_super_admin', res.data.is_super_admin);

};

export const isAuthenticated = async () => {
  // Check if the token is expired before making the request
  if (isTokenExpired()) {
    const refreshSuccess = await getRefreshToken();
    if (!refreshSuccess) {
      // If the refresh failed, return false to indicate the user is not authenticated
      return false;
    }
  }

  const token = sessionStorage.getItem("token") || localStorage.getItem("token");
  try {
    const res = await axios.get(`${API_URL}/v1/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });


    const data = res.data;
    setInBothStorages('profile', data.user.profile_image);
    setInBothStorages('firstname', data.user.first_name);
    setInBothStorages('lastname', data.user.last_name);
    setInBothStorages('role', data.role);
    setInBothStorages('storeid', data?.store?.id);
    setInBothStorages('documentUploaded', data.store?.is_document_uploaded);
    setInBothStorages('emailaccount', data.store?.is_email_account_added);
    setInBothStorages('shopifyaccount', data.store?.is_shopify_account_added);
    setInBothStorages('isonboarded', data.store?.is_onboarded);
    setInBothStorages('preferedlanguage', data.user?.prefered_language);
    setInBothStorages('storename', data?.store?.name);
    setInBothStorages('storedata', data?.store?.store_id);
    setInBothStorages('signed-on', data?.user?.created_at);
    setInBothStorages('id', data.user.id);
    setInBothStorages('isLoggedIn', 'true');

    return true;
  } catch (error) {
    getRefreshToken();
    return false;
  }
};

export const getRefreshToken = async () => {
  const isLoggedIn = sessionStorage.getItem('isLoggedIn') || localStorage.getItem('isLoggedIn');
  const isSuperAdmin = sessionStorage.getItem("is_super_admin") || localStorage.getItem("is_super_admin");
  const sessionStoreid = sessionStorage.getItem('storeid')
  const localStoreid = localStorage.getItem('storeid')
  if (isLoggedIn === 'false') {
    window.location.href = "/signin";
    return;
  }

  let storeid = null;
  if (isSuperAdmin?.toLowerCase() === "true" && (sessionStoreid === "undefined")) {
    storeid = null
  } else if (sessionStoreid && sessionStoreid !== "null" && sessionStoreid !== "undefined") {
    storeid = sessionStoreid;
  } else {
    storeid = localStoreid;
  }

  try {
    const res = await axios.post(`${API_URL}/v1/auth/token/refresh`, {}, {
      withCredentials: true,
      params: {
        store_id: storeid,
      }
    });
    // Assuming the response contains the new token and its expiration time
    if (isSuperAdmin === 'False' || (isSuperAdmin === "True" && sessionStoreid === "undefined")) {
      localStorage.setItem("email", res.data.email);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("expiry_in", res.data.expires_in);
      localStorage.setItem("is_super_admin", res.data.is_super_admin);
    }

    /*sessionstorage*/
    sessionStorage.setItem("email", res.data.email);
    sessionStorage.setItem("token", res.data.token);
    sessionStorage.setItem("expiry_in", res.data.expires_in);
    sessionStorage.setItem("is_super_admin", res.data.is_super_admin);
    return true;
  } catch (err) {
    console.error("Error during token refresh:", err);
    localStorage.clear();
    sessionStorage.clear();
    setInBothStorages('isLoggedIn', 'false');
    window.location.href = "/signin";
    return false;
  }
}

export const isTokenExpired = () => {
  const tokenExpiryTime = sessionStorage.getItem("expiry_in") || localStorage.getItem("expiry_in");

  if (!tokenExpiryTime) {
    return true;
  }

  const currentTime = new Date();
  const expiryTime = new Date(tokenExpiryTime);
  // console.log(currentTime > expiryTime);
  return currentTime > expiryTime;
};
