import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { googleLogin } from "../services/login";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import LoadingAnimation from "../assets/LoadingAnimation.svg";
import { toast } from "react-toastify";
function GoogleSignInButton(props) {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);

  // Function to handle Google Sign-In by fetching the authorization URL from the backend
  const handleGoogleSignIn = async () => {
    try {
      // Call the backend to get the Google OAuth authorization URL
      const response = await axios.get(`${API_URL}/v1/auth/google/login`);
      const authorizationUrl = response.data.authorization_url;

      // Redirect the user to the Google OAuth authorization URL
      window.location.href = authorizationUrl;
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
      setLoading(false);
    }
  };

  // Function to handle the OAuth callback (when the user is redirected back with the authorization code)
  const handleOAuthCallback = async (code) => {
    if (code) {
      setLoading(true);
      // codeProcessed.current = true;
      try {
        // Send the authorization code to your backend for token exchange
        await googleLogin(code);

        // Redirect to the homepage or another page after successful login
        navigate("/");
      } catch (error) {
        console.log("Error during OAuth callback:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    // If a code exists and hasn't been processed yet, handle the OAuth callback
    if (code) {
      handleOAuthCallback(code);
    }
  }, [navigate]);

  return (
    <div className="flex mt-3">
      <button
        id="google-login-btn"
        onClick={handleGoogleSignIn}
        className={`lg:py-[10px] bg-[#F8FAFC] border border-[#0000001A] gap-2 flex justify-center items-center h-[40px] sm:h-[50px] md:h-[58px] w-[330px] sm:w-[400px] lg:w-[420px] border-[#39F6A3] border-opacity-10 rounded-[5px] ${
          (props?.isDisabled) ? 'cursor-not-allowed opacity-50' : ''
        }`}
        disabled={props?.isDisabled || loading}
      >
       {loading ? (
          <span className="h-full w-12 lg:w-14 flex justify-center items-center rounded-md">
          <img src={LoadingAnimation} alt="loading..." />
            </span>
    
        ) : (
          <>
            <span className="h-full w-4 sm:w-8 flex justify-center items-center rounded-md">
              <FcGoogle className="sm:w-[25px] sm:h-[25px]" />
            </span>
            <div className="text-xs sm:text-[15px]  font-semibold font-dmsans leading-normal  text-[#1E293B]">
              Sign in with Google
            </div>
          </>
        )}
      </button>
    </div>
  );
}
export default GoogleSignInButton;