import React, { useState } from 'react';
import CalendarIcon from "../../assets/DraftsPageAssets/CalendarIcon.jsx";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import "../DraftDetailPageComponents/CustomCalendar.css"
import { AiOutlineClose } from "react-icons/ai";
const formatDate = (date) => {
    return date ? date.toISOString().split('T')[0] : null;
};

const DateRangeInput = ({ startDate, endDate, onChange }) => {
    const [showStartCalendar, setShowStartCalendar] = useState(false);
    const [showEndCalendar, setShowEndCalendar] = useState(false);

    const handleStartDateChange = (date) => {
        if (date) {
            const formattedDate = formatDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
            onChange([formattedDate, endDate]);
            setShowStartCalendar(false);
        }
    };

    const handleEndDateChange = (date) => {
        if (date) {
            date.setHours(23, 59, 59, 999); // Set the time to the end of the day
            const formattedDate = formatDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
            onChange([startDate, formattedDate]);
            setShowEndCalendar(false);
        }
    };

    const toggleStartCalendar = () => {
        setShowStartCalendar((prev) => !prev);
        if (showEndCalendar) {
            setShowEndCalendar(false);
        }
    };

    const toggleEndCalendar = () => {
        setShowEndCalendar((prev) => !prev);
        if (showStartCalendar) {
            setShowStartCalendar(false);
        }
    };

    const calendarStyle = {
        width: '250px',
        height: '250px',
        zIndex: 20,
    };

    return (
        <div className="absolute top-10 right-0 z-10 bg-white border border-gray-300 rounded shadow-lg">
            <div className="flex md:flex-row flex-col justify-between bg-white border-gray-300 rounded-lg w-[198px] md:w-[390px] md:h-[40px] px-1">
                <div className="relative md:w-1/2 p-1">
                    <input
                        type="text"
                        value={startDate ? new Date(startDate).toLocaleDateString() : ""}
                        placeholder="From"
                        className="border border-[#868ca2] p-2 w-[180px] h-[30px] rounded focus:outline-none focus:ring-2 focus:ring-[#39F6A3] bg-white"
                        onClick={toggleStartCalendar}
                        onChange={() => { }}
                        data-testid="start-calendar"
                    />
                    <CalendarIcon className="absolute right-4 top-1/2 transform -translate-y-1/2 w-4 h-4" fill="#374957" />
                    {showStartCalendar && (
                        <div className="absolute top-12 left-0 z-20" style={calendarStyle}>
                            <Calendar
                                onChange={handleStartDateChange}
                                value={startDate ? new Date(startDate) : new Date()}
                                selectRange={false}
                                maxDate={new Date()}
                                className="custom-calendar h-[252px]"
                            />
                        </div>
                    )}
                </div>
                <div className="relative md:w-1/2 p-1">
                    <input
                        type="text"
                        value={endDate ? new Date(endDate).toLocaleDateString() : ""}
                        placeholder="To"
                        className="border border-[#868CA2] p-2 w-[180px] h-[30px] rounded focus:outline-none focus:ring-2 focus:ring-[#39F6A3] bg-white"
                        onClick={toggleEndCalendar}
                        onChange={() => { }}
                        data-testid="end-calendar"
                    />
                    <CalendarIcon className="absolute right-4 top-1/2 transform -translate-y-1/2 w-4 h-4" fill="#374957" />
                    {showEndCalendar && (
                        <div className="absolute top-12 left-0 z-20" style={calendarStyle} >
                            <Calendar
                                onChange={handleEndDateChange}
                                value={endDate ? new Date(endDate) : new Date()}
                                selectRange={false}
                                maxDate={new Date()}
                                className="custom-calendar h-[252px]"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

// EmailFilterDropdown Component
export const EmailFilterDropdown = ({ connectedEmails, disconnectedEmails, selectedEmails, onEmailSelectionChange, setShowEmailList, onFilterChange, status }) => {
    const combinedEmails = [...connectedEmails, ...disconnectedEmails]; // Combine connected and disconnected emails
    const [activeTab, setActiveTab] = useState("Emails");

    const handleEmailSelect = (email) => {
        let updatedSelection;
        if (selectedEmails.includes(email)) {
            // Deselect the email
            updatedSelection = selectedEmails.filter((selectedEmail) => selectedEmail !== email);
        } else {
            // Select the email
            updatedSelection = [...selectedEmails, email];
        }
        onEmailSelectionChange(updatedSelection);
    };

    return (
        <div className="absolute top-10 right-0 z-10 bg-white border border-gray-300 rounded-md shadow-lg overflow-auto max-h-[400px] min-w-[300px] px-2 custom-scrollbar">
            {/* Header */}
            <div className="flex items-center justify-between py-2">
                <h3 className="text-sm font-semibold">Filter by</h3>
                <button
                    className="text-gray-500 hover:text-gray-700"
                    aria-label="Close dropdown"
                    onClick={() => setShowEmailList(false)}
                >
                    <AiOutlineClose size={16} />
                </button>
            </div>

            {/* Tabs */}
            <div className="flex border-b border-gray-300 bg-[#868CA2]/10 px-1 rounded-md h-[36px]">
                <button
                    onClick={() => setActiveTab("Emails")}
                    className={`flex-1  my-[2px] text-center text-sm font-medium ${activeTab === "Emails" ? "bg-[#D7FDED] rounded-md text-[#000000]" : "text-gray-500"
                        }`}
                >
                    Emails
                </button>
                <button
                    onClick={() => setActiveTab("Status")}
                    className={`flex-1 my-[2px] text-center text-sm font-medium ${activeTab === "Status" ? "bg-[#D7FDED] rounded-md text-[#000000]" : "text-gray-500"
                        }`}
                >
                    Status
                </button>
            </div>

            {/* Tab Content */}
            <div className='my-2'>
                {activeTab === "Emails" ? (
                    <ul>
                        {combinedEmails?.length > 0 ? (
                            combinedEmails?.map((email) => (
                                <li key={email?.id} className="flex items-center p-2 hover:bg-gray-100" >
                                    <input
                                        type="checkbox"
                                        id={email?.id}
                                        value={email?.email}
                                        checked={selectedEmails.includes(email?.email)}
                                        onChange={() => handleEmailSelect(email?.email)}
                                        className="mr-2 accent-green-200"
                                    />
                                    <label htmlFor={email?.id} className="text-sm font-medium">
                                        {email?.email}
                                    </label>
                                </li>
                            ))
                        ) : (
                            <li className="text-center py-2 text-sm text-gray-500 font-dmsans">
                                No emails connected
                            </li>
                        )}
                    </ul>
                ) : (
                    <ul>
                        {Object.keys(status)?.map((statusKey) => (
                            <li key={statusKey} className="flex items-center p-2 hover:bg-gray-100">
                                <input
                                    type="checkbox"
                                    id={statusKey}
                                    className="mr-2 accent-green-200"
                                    checked={status[statusKey]} // Use the parent filter state
                                    onChange={() => onFilterChange(statusKey)} // Call parent function directly
                                />
                                <label htmlFor={statusKey} className="text-sm font-medium">
                                    {statusKey
                                        .replace(/([A-Z])/g, " $1") // Add space before capital letters
                                        .toLowerCase() // Convert everything to lowercase
                                        .replace(/^\w/, (match) => match.toUpperCase())}
                                </label>

                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};
  
  export default DateRangeInput;
