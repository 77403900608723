import React, { useState, useRef, useEffect } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa6';
import { toast } from "react-toastify";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
import axiosInstance from "../../services/axiosInstance";
import DownArrowIcon from '../../assets/ExamplesPageAssets/DownArrowIcon.svg';
import { extractNameAndEmail, arrayBufferToBase64, getDisputeValue } from "../../services/emailUtils"
const ExchangeItem = ({ thread_id, exchangeDetails, handleClosExchangeItem, setExchangeSucessModal, orderData, draft_id, draftData,fetchAllData,store_email }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownIndex, setDropdownIndex] = useState(null); // Track which dropdown is open
    const [colorDropdownIndex, setColorDropdownIndex] = useState(null);
    const dropdownRef = useRef(null);
    // Refund Dispute state
    const [disputeIssuesOptions, setDisputeIssuesOptions] = useState([]);
    const [disputeSolutionOptions, setDisputeSolutionOptions] = useState([]);
    // State to store the selected dispute issue and solution
    const [selectedDisputeIssue, setSelectedDisputeIssue] = useState({ id: "", label: "" });
    const [selectedDisputeSolution, setSelectedDisputeSolution] = useState({ id: "", label: "" });
    const [isIssueDropdownOpen, setIsIssueDropdownOpen] = useState(false);
    const [isSolutionDropdownOpen, setIsSolutionDropdownOpen] = useState(false);
    const issueDropdownRef = useRef(null);
    const solutionDropdownRef = useRef(null);
    const email = store_email ? extractNameAndEmail(store_email)?.email || "" : "";
    // Dropdown toggle handlers
    const toggleIssueDropdown = () => setIsIssueDropdownOpen(!isIssueDropdownOpen);
    const toggleSolutionDropdown = () => setIsSolutionDropdownOpen(!isSolutionDropdownOpen);
    const getActionName = () => {
        // Safely access the actions data and return the desired action name
        const actionName = draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.actions?.[0]?.name || draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.actions?.[0] || "";
        return actionName.replace(/_/g, " ").includes("order change")
            ? "exchange"
            : actionName.replace(/_/g, " ").includes("order payment change")
                ? "payment"
                : "";
    };

    // Handlers for selecting dropdown options
    const handleDisputeIssueSelect = (issue) => {
        setSelectedDisputeIssue(issue);
        setIsIssueDropdownOpen(false); // Close dropdown after selection
    };

    const handleDisputeSolutionSelect = (solution) => {
        setSelectedDisputeSolution(solution);
        setIsSolutionDropdownOpen(false); // Close dropdown after selection
    };

    const availableColors = [
        { name: 'Beige', code: '#F5F5DC' },
        { name: 'Black', code: '#000000' },
        { name: 'Blue', code: '#0000FF' },
        { name: 'Bronze', code: '#CD7F32' },
        { name: 'Brown', code: '#A52A2A' },
        { name: 'Clear', code: '#FFFFFF' },
        { name: 'Gold', code: '#FFD700' },
        { name: 'Gray', code: '#808080' },
        { name: 'Green', code: '#008000' },
        { name: 'Navy', code: '#000080' },
        { name: 'Orange', code: '#FFA500' },
        { name: 'Pink', code: '#FFC0CB' },
        { name: 'Purple', code: '#800080' },
        { name: 'Red', code: '#FF0000' },
        { name: 'Rose Gold', code: '#B76E79' },
        { name: 'Silver', code: '#C0C0C0' },
        { name: 'White', code: '#FFFFFF' },
        { name: 'Yellow', code: '#FFFF00' }
    ];

    const lineItems = exchangeDetails?.line_items || [];
    const availableSizes = ['1', '2', '4', '6', '8', '10', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36', '38', '40', '42', '44', '46', '48', '50', '52', '54', '56', '58', '60', '3XS', '2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL', '6XL'];

    // Store initial values in useRef to keep them constant
    const initialQuantities = useRef(lineItems.map(item => item.quantity));
    const initialSizes = useRef(lineItems.map(item => item.size || availableSizes[0]));
    const initialColors = useRef(lineItems.map(item => item.color || availableColors[0]));

    // State for handling quantity, prices, selected sizes, and colors
    const [quantities, setQuantities] = useState(initialQuantities.current);
    const [prices, setPrices] = useState(lineItems.map(item => item.price));
    const [selectedSizes, setSelectedSizes] = useState(initialSizes.current);
    const [selectedColors, setSelectedColors] = useState(initialColors.current);

    const handleQuantityChange = (index, newQuantity) => {
        const newQuantities = [...quantities];
        const newPrices = [...prices];

        newQuantities[index] = newQuantity;
        newPrices[index] =  lineItems[index].price;

        setQuantities(newQuantities);
        setPrices(newPrices);
    };

    const handleSizeChange = (index, newSize) => {
        const newSizes = [...selectedSizes];
        newSizes[index] = newSize;
        setSelectedSizes(newSizes);
    
        // Close the size dropdown
        setDropdownIndex(null);
        setIsDropdownOpen(false);
    };
    
    const handleColorChange = (index, newColor) => {
        const newColors = [...selectedColors];
        newColors[index] = newColor;
        setSelectedColors(newColors);
    
        // Close the color dropdown
        setColorDropdownIndex(null);
        setIsDropdownOpen(false);
    };
    

    const toggleDropdown = (index) => {
        if (dropdownIndex === index) {
            setIsDropdownOpen(!isDropdownOpen);
        } else {
            setIsDropdownOpen(true);
            setDropdownIndex(index);
        }
    };

    const toggleColorDropdown = (index) => {
        if (colorDropdownIndex === index) {
            setIsDropdownOpen(!isDropdownOpen);
        } else {
            setIsDropdownOpen(true);
            setColorDropdownIndex(index);
        }
    };

    // Handle clicking outside the dropdown to close it
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
                setDropdownIndex(null);
                setColorDropdownIndex(null);
            }
              // Handle issue dropdown
            if (issueDropdownRef.current && !issueDropdownRef.current.contains(event.target)) {
                setIsIssueDropdownOpen(false);
            }

            // Handle solution dropdown
            if (solutionDropdownRef.current && !solutionDropdownRef.current.contains(event.target)) {
                setIsSolutionDropdownOpen(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, issueDropdownRef, solutionDropdownRef]);
    const fetchAttachmentData = async (messageId, attachmentId) => {
        try {
            const res = await axiosInstance.get(
                `${API_URL}/v1/store/gmail/${messageId}/attachment/${attachmentId}`,
                {
                    params: { email: email, },
                    responseType: 'arraybuffer', // Handle binary data
                }
            );
            // // Convert the binary data (ArrayBuffer) to a base64 encoded string
            const base64String = arrayBufferToBase64(res.data);
            return { data: base64String };
        } catch (error) {
            toast.error('Failed to fetch attachment');
            return null;
        }
    };
    useEffect(() => {
        fetchDropdownOptions()
    }, []);

    const fetchDropdownOptions = async () => {
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/servicepoints/get-dispute-options`);
            const { dispute_issues, dispute_solutions } = response.data;

            // Store both ID and label for each option
            const formattedIssues = Object.entries(dispute_issues).map(([id, label]) => ({ id, label }));
            const formattedSolutions = Object.entries(dispute_solutions).map(([id, label]) => ({ id, label }));
            setDisputeIssuesOptions(formattedIssues);
            setDisputeSolutionOptions(formattedSolutions);
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
    };
  useEffect(() => {
    const issueId = draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.dispute_data?.dispute_issue?.toString();
    const solutionId = draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.dispute_data?.dispute_solution?.toString();
    // Find the matching issue and solution based on their ids
    const matchedIssue = disputeIssuesOptions?.find(issue => issue.id === issueId);
    const matchedSolution = disputeSolutionOptions?.find(solution => solution.id === solutionId);

    if (matchedIssue) {
      setSelectedDisputeIssue(matchedIssue);
    }
    if (matchedSolution) {
        setSelectedDisputeSolution(matchedSolution);
    }

  }, [ disputeIssuesOptions,disputeSolutionOptions]);
    const handleExchangeOrder = async (e) => {
        e.preventDefault();
        const actionName = getActionName();
        setIsLoading(true);

        try {
            // Construct updated line items with the new quantities, prices, sizes, and colors
            const updatedLineItems = lineItems.map((item, index) => ({
                ...item,
                quantity: quantities[index],
                price: prices[index],
                ...(item?.size && { size: selectedSizes[index] }),
                ...(item?.color && { color: selectedColors[index].name }),
                title: item.title ? item.title : item.name
            }));
             // Find the last attachment and capture the corresponding mail content ID
             let lastAttachment = null;
             let mailContentId = null;
             draftData?.emails?.forEach((mail) => {
               if (mail?.attachments && mail?.attachments.length > 0) {
                 lastAttachment = mail?.attachments[mail.attachments.length - 1]; // Get the last attachment
                 mailContentId = mail?.id; // Capture the mail content (message) ID
               }
             });

            let attachmentData = null;
            if (lastAttachment) {
                // Fetch the attachment data for the last attachment
                attachmentData = await fetchAttachmentData(mailContentId, lastAttachment.attachmentId);
            }
                const dispute_data = {
                dispute_issue:selectedDisputeIssue.id,  // Send the ID
                dispute_solution:selectedDisputeSolution.id,  // Send the ID
                ...(attachmentData ? { attachment: { name: lastAttachment.filename, data: attachmentData.data } } : {})
                };

            const payload = {
                thread_id: thread_id,
                draft_id: draft_id,
                order_id: orderData[0].id.toString(),
                line_items: updatedLineItems,
                ...(actionName === "exchange" && { dispute_data: dispute_data })
            };
            if (actionName === "exchange") {
                const response = await axiosInstance.post(
                    `${API_URL}/v1/store/shopify/order/exchange`,
                    payload,
                );
                toast.success("Order updated successfully.");
                setExchangeSucessModal(true);
            } else if (actionName === "payment") {
                const response = await axiosInstance.post(
                    `${API_URL}/v1/store/shopify/checkout_url`,
                    payload,
                );
                handleClosExchangeItem();
                fetchAllData();
            }

        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
        setIsLoading(false);
    };

    const isQuantityChanged = (index) => quantities[index] !== initialQuantities.current[index];
    const isSizeChanged = (index) => selectedSizes[index] !== initialSizes.current[index];
    const isColorChanged = (index) => selectedColors[index] !== initialColors.current[index];

    return (
    <div className="flex items-center justify-center lg:items-start lg:justify-start">
        <div className="lg:w-full w-[96%]  flex flex-col font-dmsans pt-2 mt-10 border border-[#868CA24D] rounded-lg">
            <div className="flex gap-5 pb-2 text-xs font-medium border-b border-[#868CA24D] leading-none text-gray-800">
                <div className="flex items-center gap-2 ml-2 h-[30px]">
                    <div className="text-[#2B2E3A] text-[12px]">Exchange items</div>
                </div>
            </div>
            <div className="relative mt-3 mx-2 mb-3">
                {/* Heading for Raise Dispute */}
                <h2 className="text-sm font-medium text-[#2B2E3A]">Raise dispute</h2>

                {/* Flexbox container for both dropdowns */}
                <div className="flex gap-3 lg:gap-0 lg:flex-row flex-col lg:justify-between mt-2 w-full">
                    {/* Dispute Issue Dropdown */}
                    <div className="relative w-full sm:w-[60%] lg:w-[40%] xl:w-[36%]" ref={issueDropdownRef}>
                        <div className="relative">
                            <button
                                className="w-full flex justify-between items-center py-2 px-[8px] sm:px-3 border border-[#E7EAEE] rounded-md bg-white text-left text-[#000000] text-sm min-h-[40px]"
                                onClick={toggleIssueDropdown}
                            >
                                <span>{selectedDisputeIssue?.label || "Dispute Issue"}</span>
                                <img src={DownArrowIcon} alt="Down Arrow" className="ml-2" />
                            </button>
                            {isIssueDropdownOpen && (
                                <ul className="absolute w-full border border-gray-300 rounded-md bg-white z-10 max-h-40 overflow-y-auto custom-scrollbar">
                                    {disputeIssuesOptions?.map((issue, index) => (
                                        <li key={index} className="py-1 px-3 hover:bg-gray-200 cursor-pointer text-[#191D23] text-sm" onClick={() => handleDisputeIssueSelect(issue)}>{issue.label}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>

                    {/* Dispute Solution Dropdown */}
                    <div className="relative w-full sm:w-[60%] lg:w-[40%] xl:w-[36%]" ref={solutionDropdownRef}>
                        <div className="relative">
                            <button className="w-full flex justify-between items-center py-2 px-[8px] sm:px-3 border border-[#E7EAEE] rounded-md bg-white text-left text-[#000000] text-sm min-h-[40px]" onClick={toggleSolutionDropdown}>
                                <span>{selectedDisputeSolution?.label || "Dispute Solution"}</span>
                                <img src={DownArrowIcon} alt="Down Arrow" className="ml-2" />
                            </button>
                            {isSolutionDropdownOpen && (
                                <ul className="absolute w-full border border-gray-300 rounded-md bg-white z-10 max-h-40 overflow-y-auto custom-scrollbar">
                                    {disputeSolutionOptions?.map((solution, index) => (
                                        <li
                                            key={index}
                                            className="py-1 px-3 hover:bg-gray-200 cursor-pointer text-[#191D23] text-sm"
                                            onClick={() => handleDisputeSolutionSelect(solution)}
                                        >
                                            {solution.label}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-2 py-3">
                <div className="">
                    <div className="text-[#000000] underline text-xs font-medium pb-0">Updated cart</div>
                 <div className="space-y-4">
                    {lineItems.map((item, index) => (
                        <div key={index} className="flex lg:flex-row flex-col lg:items-center justify-between mt-3 gap-3 lg:gap-4">
                            <div className="lg:w-1/4">
                                <span className="text-[12px] capitalize text-[#000000] font-medium">{item.name}</span>
                            </div>

                            {/* Conditionally render Size dropdown if size is available */}
                            {item.size && (
                                <div className="flex w-full lg:w-1/4 gap-2 ">
                                    <label htmlFor={`sizeSelect-${index}`} className="mt-1 w-1/3 lg:w-auto text-[12px] font-medium text-[#000000]">Size</label>
                                    <div className="relative flex flex-col min-w-1/3 max-w-2/3 lg:w-auto">
                                        <button
                                            id={`sizeSelect-${index}`}
                                            className={`text-center relative w-full min-w-[40px] border border-[#1212121A] text-xs rounded-md h-[23px] p-2 text-[14px] focus:outline-none flex items-center justify-between ${isSizeChanged(index) ? 'bg-[#F1F9F7]' : ''}`}
                                            onClick={() => toggleDropdown(index)}
                                        >
                                            {selectedSizes[index] ? selectedSizes[index] : <span className="text-[#00000080]">Select size</span>}
                                            <img src={DownArrowIcon} alt="Down Arrow" className="ml-2" />
                                        </button>
                                    {isDropdownOpen && dropdownIndex === index && (
                                        <div className="absolute top-full mt-1 w-full bg-white border border-gray-800 rounded-md z-10 overflow-y-auto custom-scrollbar"
                                                style={{ maxHeight: '100px' }} // Adjust height as needed
                                                ref={dropdownRef}
                                            >
                                            <ul>
                                                {availableSizes?.map((size) => (
                                                    <li
                                                        key={size}
                                                        onClick={() => handleSizeChange(index, size)}
                                                        className="mb-2 rounded-md cursor-pointer pl-3 my-2 h-[20px] text-xs hover:bg-[#39F6A333]"
                                                    >
                                                        {size}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    </div>
                                </div>
                            )}


                            {/* Conditionally render Color dropdown if color is available */}
                            {item?.color && (
                                <div className="flex w-full lg:w-1/4 gap-2">
                                    <label htmlFor={`colorSelect-${index}`} className="mt-1 w-1/3 lg:w-auto text-[12px] font-medium text-[#000000]">Color</label>
                                    <div className="relative flex flex-col min-w-1/3 max-w-2/3 lg:w-auto">
                                        <button
                                            id={`colorSelect-${index}`}
                                            className={`text-center w-full min-w-[40px] border border-[#1212121A] text-xs rounded-md h-[23px] p-2 text-[14px] focus:outline-none flex items-center justify-between ${isColorChanged(index) ? 'bg-[#F1F9F7]' : ''}`}
                                            onClick={() => toggleColorDropdown(index)}
                                        >
                                            {selectedColors[index]?.name ? (
                                                <div className="flex items-center gap-2">
                                                    <span
                                                        className="inline-block w-3 h-3 rounded-full"
                                                        style={{ backgroundColor: selectedColors[index]?.code || availableColors[0].code }}
                                                    ></span>
                                                    <span>
                                                        {availableColors.find(
                                                            (color) => color.name?.toLowerCase() === selectedColors[index].name?.toLowerCase()
                                                        )?.name || availableColors[0].name}
                                                    </span>
                                                </div>
                                            ) : (
                                                // If no color is selected, show the default color from the API or fallback to the first available color
                                                <div className="flex items-center gap-2">
                                                    <span
                                                        className="inline-block w-3 h-3 rounded-full"
                                                        style={{
                                                            backgroundColor: availableColors.find(
                                                                (color) => color.name?.toLowerCase() === item.color?.toLowerCase()
                                                            )?.code || availableColors[0].code
                                                        }}
                                                    ></span>
                                                    <span>
                                                        {availableColors.find(
                                                            (color) => color.name?.toLowerCase() === item.color?.toLowerCase()
                                                        )?.name || availableColors[0].name}
                                                    </span>
                                                </div>
                                            )}
                                            <img src={DownArrowIcon} alt="Down Arrow" className="ml-2" />
                                        </button>
                                    {isDropdownOpen && colorDropdownIndex === index && (
                                            <div className="absolute top-full mt-1 w-full bg-white border border-gray-800 rounded-md z-10 overflow-y-auto custom-scrollbar"
                                                style={{ maxHeight: '100px' }} // Adjust height as needed
                                                ref={dropdownRef}
                                            >
                                            <ul>
                                                {availableColors?.map((color) => (
                                                    <li
                                                        key={color.name}
                                                        onClick={() => handleColorChange(index, color)}
                                                        className="mb-2 rounded-md cursor-pointer pl-3 my-2 h-[20px] text-xs flex items-center gap-2 hover:bg-[#39F6A333]"
                                                    >
                                                        <span
                                                            className="inline-block w-3 h-3 rounded-full"
                                                            style={{ backgroundColor: color.code }}
                                                        ></span>
                                                        <span>{color.name}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                </div>
                            )}




                            <div className={`w-full lg:w-${item.size || item.color ? '1/4' : '1/3'}`}>
                                <div className="flex items-center gap-2 w-full">
                                    <span className="text-[12px] capitalize text-[#000000] w-1/3 lg:w-auto">Quantity</span>
                                    <div className="relative flex items-center w-[50px] md:w-[60px]">
                                        <input
                                            type="number"
                                            value={quantities[index]}
                                            onChange={(e) => {
                                                const newValue = parseInt(e.target.value);
                                                if (newValue >= 1) {  // Minimum value 1
                                                    handleQuantityChange(index, newValue);
                                                }
                                            }}
                                            className={`no-arrows w-full text-center pr-1 border border-[#1212121A] text-xs rounded-md focus:outline-none h-[23px] ${isQuantityChanged(index) ? 'bg-[#F1F9F7]' : ''}`}
                                            min={1} // Minimum value 1
                                        />
                                        <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex flex-col justify-center h-[26px]">
                                            <button
                                                type="button"
                                                onClick={() => handleQuantityChange(index, quantities[index] + 1)}
                                                className="flex"
                                            >
                                                <FaAngleUp className={`text-[#000000] w-3 h-2`} />
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => handleQuantityChange(index, quantities[index] - 1)}
                                                className="flex"
                                                disabled={quantities[index] <= 1} // Disable when quantity is 1
                                            >
                                                <FaAngleDown className={`text-[#000000] w-3 h-2 ${quantities[index] <= 1 ? 'text-gray-400' : ''}`} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full lg:w-1/4">
                                <div className="flex items-center gap-2 w-full">
                                    <span className="text-[12px] capitalize text-[#000000] w-1/3 lg:w-auto">Price</span>
                                    <input 
                                        type="text" 
                                        className="w-auto text-center border rounded text-[12px] h-[26px]  focus:outline-none max-w-[90px]"
                                        value={prices[index]}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                </div>

                <div className="flex justify-end mt-6 space-x-4 pr-2">
                    <button
                        type="button"
                        className="px-2 w-[69px] h-[30px] text-[#2B2E3A] text-[12px] md:text-[13px] border rounded-lg border-[#D0D5DD]"
                        onClick={handleClosExchangeItem}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="w-auto px-2 min-w-[154px] h-[30px] text-[#2B2E3A] text-[12px] md:text-[13px] bg-[#96F3C3] rounded-lg flex items-center justify-center"
                        disabled={isLoading}
                        onClick={handleExchangeOrder}
                    >
                        {isLoading ? (
                            <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-14" />
                        ) : (
                            `Confirm exchange ${getActionName()==="exchange" ? '& Send email':''}`
                        )}
                    </button>
                </div>
            </div>
        </div>
    </div>
    );
}

export default ExchangeItem;