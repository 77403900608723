import React from "react";

const OrderLogsShimmer = () => {
  return (
    <div className="flex flex-col space-y-6">
      {/* Date Shimmer */}
      {[...Array(2)].map((_, dateIdx) => (
        <div key={dateIdx} className="lg:w-[400px] w-full">
          <div className="w-1/3 h-4 bg-gray-300 rounded mb-4 animate-pulse"></div>
          <div className="space-y-4 relative">
            {[...Array(2)].map((_, logIdx) => (
              <div key={logIdx} className="flex items-start relative">
                {/* Circle Shimmer */}
                <div className="w-4 h-4 bg-gray-300 rounded-full animate-pulse mr-2"></div>

                {/* Line Connector Shimmer */}
                {logIdx < 1 && (
                  <div className="absolute left-2 top-4 h-12 w-[1px] bg-gray-300 animate-pulse"></div>
                )}

                {/* Log Text Shimmer */}
                <div className="flex-1 ml-2">
                  <div className="h-4 bg-gray-300 rounded w-3/4 animate-pulse mb-2"></div>
                  <div className="h-4 bg-gray-300 rounded w-1/4 animate-pulse"></div>
                </div>

                {/* Time Shimmer */}
                <div className="w-16 h-4 bg-gray-300 rounded animate-pulse ml-4"></div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderLogsShimmer;
