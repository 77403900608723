import React from "react";
import ErrorIcon from "../assets/ErrorIcon";
import { useLocation } from "react-router-dom";

const Error502Page = () => {
  const location = useLocation();

  // Extract the lastPage parameter from the query string
  const queryParams = new URLSearchParams(location.search);
  const lastPage = queryParams.get("lastPage") || "/";

  const handleRetry = () => {
    window.location.href = lastPage;
  };

  return (
    <div className="flex flex-col fixed inset-0 mx-auto items-center text-center justify-center bg-[#FFFFFF] font-dmsans">
        {/* Illustrative icon */}
        <div className="mb-4 sm:w-[300px] sm:h-[300px] w-[200px] h-[200px]">
          <ErrorIcon />
        </div>

        {/* Main error message */}
        <h1 className="sm:text-4xl text-2xl font-bold text-[#2B2E3A] mb-2">502 - Bad Gateway</h1>

        {/* Description text */}
        <p className="sm:text-lg text-[14px] text-[#2B2E3A] mb-6">
          This is a temporary error. Please try again later.
        </p>

        {/* Retry button */}
        <button
          onClick={handleRetry}
          className="w-[100px] h-[32px] bg-[#96F3C3] text-[#2B2E3A] rounded-md font-medium hover:bg-[#88E7B4] transition-all"
        >
          Retry
        </button>
    </div>
  );
};

export default Error502Page;
