import React, { useEffect, useState, useCallback, useRef } from "react";
import { IoSearch } from "react-icons/io5";
import { useNavigate,useParams, useLocation } from "react-router-dom";
import { IoCalendar } from "react-icons/io5";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import DraftsPagination from "../components/DraftsPagination.jsx";
import { formatTime } from "../services/logics";
import { FiRotateCw } from "react-icons/fi";
import DateRangeInput  from "../components/DraftDetailPageComponents/DateRangeInput.jsx";
import { extractNameAndEmail } from "../services/emailUtils.js";
import { useSelector } from "react-redux";
import { CiFilter } from "react-icons/ci";
import DraftShimmer from "../components/DraftShimmer.jsx";
import axiosInstance from "../services/axiosInstance.js";
import {EmailFilterDropdown} from "../components/DraftDetailPageComponents/DateRangeInput.jsx";
import DOMPurify from 'dompurify';
export const groupDraftsByDate = (drafts = []) => {
  const grouped = {};

  drafts.forEach((draft) => {
    const draftDate = new Date(draft?.emails[draft?.emails?.length - 1]?.created_at);
    const today = new Date();

    // Get the UTC date strings for accurate comparison
    const draftDateString = draftDate.toUTCString().split(' ').slice(0, 4).join(' ');
    const todayDateString = today.toUTCString().split(' ').slice(0, 4).join(' ');

    let key;

    if (draftDateString === todayDateString) {
      key = `Today, ${draftDate.toLocaleDateString("en-GB", {
        month: "long",
        day: "numeric",
        year: "numeric",
        timeZone: "UTC"
      })}`;
    } else if (new Date(today.setUTCDate(today.getUTCDate() - 1)).toUTCString().split(' ').slice(0, 4).join(' ') === draftDateString) {
      key = `Yesterday, ${draftDate.toLocaleDateString("en-GB", {
        month: "long",
        day: "numeric",
        year: "numeric",
        timeZone: "UTC"
      })}`;
    } else {
      key = draftDate.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
        timeZone: "UTC"
      });
    }

    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(draft);
  });

  return grouped;
};
const DraftPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const [drafts, setDrafts] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalDrafts, setTotalDrafts] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showEmailList, setShowEmailList] = useState(false);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const calendarRef = useRef(null);
  const emailRef = useRef(null);
  const [stores, setStores] = useState([]);
  const [prevStoresConnected, setPrevStoresConnected] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const filterParam = searchParams.get("filter");
  const [status, setStatus] = useState({
    unread: false,
    replyRequired: false,
  });
  

  // Dynamically initialize the activeTab state based on the filter parameter
  const [activeTab, setActiveTab] = useState(() => {
    if (filterParam === "drafts") return "drafts";
    if (filterParam === "sent") return "sent";
    if (filterParam === "all") return "all";
  });
    // Update activeTab when the filter parameter changes
    useEffect(() => {
      if (filterParam === "drafts") {
        setActiveTab("drafts");
      } else if (filterParam === "sent") {
        setActiveTab("sent");
      } else if (filterParam === "all") {
        setActiveTab("all");
      } else {
        setActiveTab("inbox");
      }
    }, [filterParam]);

  const fetchDrafts = async (searchTerm, page = 1, startDate = null, endDate = null, tab = null,emails = [], status= {}) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/store/conversations`, {
        params: {
          page,
          page_size: 10,
          search: searchTerm,
          from_date: startDate,
          to_date: endDate,
          is_reply_required : tab === "drafts" ? true : status?.replyRequired === true ? true : null,
          draft : tab === "drafts" ? true : null,
          unread: status?.unread === true ? true :null  , 
          sent: tab === "sent" ? true : null,
        },
        paramsSerializer: (params) => {
          const searchParams = new URLSearchParams();
          // Add all the other parameters
          for (const key in params) {
            if (params[key] !== null && params[key] !== undefined) {
              searchParams.append(key, params[key]);
            }
          }
          // Add the store_emails array separately
          emails.forEach(email => {
            searchParams.append('store_emails', email);
          });
  
          return searchParams.toString();
        }
      });
      const data = response.data;
      // If the current page has no drafts and it's not the first page, navigate to the previous page
      if (data.conversations?.length === 0 && page > 1) {
        setPage(page - 1);
        fetchDrafts(searchTerm, page - 1, startDate, endDate, tab,emails ,status); 
        return;
      }
      setDrafts(data.conversations);
      setTotalDrafts(data.total);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsFetching(false);
  };

  // Load state from location.state if available
  useEffect(() => {
    fetchStores();
    fetchPrevConnectedStores();
    if (location.state) {
      const { page, searchTerm, selectedDateRange, activeTab, status } = location.state;
      setPage(page || 1);
      setSearchTerm(searchTerm || "");
      setSelectedDateRange(selectedDateRange || [null, null]);
      setActiveTab(activeTab);
      setStatus(status);

      // Fetch drafts based on the loaded state
      fetchDrafts(searchTerm || "", page || 1, selectedDateRange?.[0] || null, selectedDateRange?.[1] || null, activeTab ,selectedEmails,status);
    } else {
      // Fetch drafts with default values if no state is present in location
      setIsFetching(true);
      handleResetFilters();
    }
  }, [location.state,activeTab]);

  const debouncedSearchDrafts = useCallback(
    debounce((term, startDate, endDate, tab,emails) => {
      setPage(1);
      fetchDrafts(term, 1, startDate, endDate, tab,emails,status);
    }, 500),
    []
  );

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const [startDate, endDate] = selectedDateRange;
    debouncedSearchDrafts(value, startDate, endDate, filterParam, selectedEmails);
  };

  const handlePageChange = (newPage) => {
    if (newPage !== page) {
      setPage(newPage);
      setIsFetching(true);
      fetchDrafts(searchTerm, newPage, selectedDateRange[0], selectedDateRange[1], filterParam, selectedEmails,status); 
    }
  };

  const handleCalendarToggle = () => {
    setShowCalendar((prevShow) => !prevShow);
  };
  const handleEmailListToggle = () => {
    setShowEmailList((prevShow) => !prevShow);
  };

  const handleDateChange = (dateRange) => {
    setSelectedDateRange(dateRange);
    setShowCalendar(false);
    fetchDrafts(searchTerm, 1, dateRange[0], dateRange[1], activeTab, selectedEmails ,status); // Fetch drafts immediately when date range changes
  };
  const handleEmailSelectionChange = (emails) => {
    setSelectedEmails(emails);
    fetchDrafts(searchTerm, 1, selectedDateRange[0], selectedDateRange[1], activeTab, emails ,status);  // Fetch drafts with selected emails
  };

  const handleFilterChange = (filterKey) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [filterKey]: !prevStatus[filterKey], // Toggle filter state
    }));
  
    fetchDrafts(
      searchTerm,
      1,
      selectedDateRange[0],
      selectedDateRange[1],
      filterParam,
      selectedEmails,
      {
        ...status, // Use the current status state
        [filterKey]: !status[filterKey], // Update the changed filter state
      }
    );
  };

  const handleResetFilters = () => {
    setStatus({ unread: false, replyRequired: false }); // Reset all filters
    setSelectedDateRange([null, null]);
    setSearchTerm("");
    setPage(1)
    const [startDate, endDate] = [null, null];
    setSelectedEmails([])
    fetchDrafts("", 1, startDate, endDate, filterParam ,[]);
  };

  const handleNavigateToDetail = (draftId) => {
    // Store the current state before navigating
    navigate(`/email-page/detail/${draftId}?filter=${activeTab}`, {
      state: {
        page,
        searchTerm,
        selectedDateRange,
        activeTab,
        status,
      },
    });
  };

  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };



  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
      if (emailRef.current && !emailRef.current.contains(event.target)) {
        setShowEmailList(false); // Assuming you have a state to toggle the email dropdown
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef, emailRef]);

  const groupedDrafts = groupDraftsByDate(drafts);

  const fetchStores = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/v1/store/email/accounts?is_deleted=False`);
      const data = response.data;
      setStores(data);
    } catch (error) {
      toast.error(error.response?.data?.detail || `Error fetching email id`);
    }
  };

  const fetchPrevConnectedStores = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/v1/store/email/accounts?is_deleted=True`);
      const data = response.data;
      setPrevStoresConnected(data);
    } catch (error) {
      toast.error(error.response?.data?.detail || `Error fetching email id`);
    }
  };

  function hasUnreadEmails(mailObject) {
    // Check if the emails array exists and contains any email objects
    if (mailObject.emails && mailObject.emails.length > 0) {
      return mailObject.emails.some((email) => !email.is_read); // Check if any email is unread
    }
    return false; // Default to false if no emails are present
  }

  return (
    <div data-testid="draftPageContainer" className={`py-2 min-h-[500px] font-dmsans overflow-y-auto flex flex-col ${isSidebarOpen ? "lg:ml-[220px] lg:px-6  xl:ml-[230px] xl:px-8 px-[10px] md:px-4" : "lg:ml-[80px] lg:px-6 px-[10px] sm:px-5"} transition-all ease-in-out duration-500`}>
      {location.pathname.includes("emails-page") && <div className="font-dmsans font-medium text-[#000000] w-full pl-[5px] xl:text-[20px]">
        <span className="text-[#000000]">Emails</span>
        <span className="mx-1">{'>'}</span>
        <span className="text-[#868CA2]">
          {filterParam
            && filterParam.charAt(0).toUpperCase() + filterParam.slice(1)}
        </span>
      </div>
      }

         <div className="relative w-full h-[36px] border border-[#868CA2] bg-white text-[#000000] rounded-sm lg:hidden flex mt-2">
            <IoSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-[#64748B]" />
            <input
              type="text"
              placeholder="Search"
              className="focus:outline-none w-[84%] h-full ml-8 text-[#64748B] text-[15px]"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>

      <div className="flex  items-center justify-between lg:mr-4 mb-2 pl-[10px] lg:pl-0 ">
        <div className="flex items-center lg:space-x-10 lg:mt-0 mt-[10px]">
          {/* <div className="flex sm:space-x-10 xl:space-x-14 ml-2 lg:ml-8 space-x-6">
            <span
              className={`cursor-pointer lg:text-base text-sm lg:font-semibold relative ${activeTab === "all" ? "text-[#191D23]" : "text-[#868CA2]"}`}
              onClick={() => handleTabChange("all")}
            >
              All
              {activeTab === "all" && (
                <div className="absolute left-1/2 transform -translate-x-1/2 lg:h-1 h-[2px] mt-1 bg-[#39F6A3]" style={{ width: "50px" }} />
              )}
            </span>
            <span
              className={`cursor-pointer lg:text-base text-sm lg:font-semibold relative ${activeTab === "unread" ? "text-[#191D23]" : "text-[#868CA2]"}`}
              onClick={() => handleTabChange("unread")}
            >
              Unread
              {activeTab === "unread" && (
                <div className="absolute left-1/2 transform -translate-x-1/2 lg:h-1 h-[2px] bg-[#39F6A3] mt-1" style={{ width: "50px" }} />
              )}
            </span>
            <span
              className={`cursor-pointer lg:text-base text-sm lg:font-semibold relative ${activeTab === "sent" ? "text-[#191D23]" : "text-[#868CA2]"}`}
              onClick={() => handleTabChange("sent")}
            >
              Sent
              {activeTab === "sent" && (
                <div className="absolute left-1/2 transform -translate-x-1/2 lg:h-1 h-[2px] bg-[#39F6A3] mt-1" style={{ width: "50px" }} />
              )}
            </span>
          </div> */}
        </div>
        <div className="flex items-center space-y-2 mt-[4px] sm:space-y-2 lg:space-y-0 space-x-2 sm:space-x-4 relative">
          <div className="relative lg:w-[210px] xl:w-[240px] h-[36px] border border-[#868CA2] bg-white text-[#000000] rounded-sm lg:block hidden">
            <IoSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-[#64748B]" />
            <input
              type="text"
              placeholder="Search"
              className="focus:outline-none w-[84%] h-full ml-8 text-[#64748B] text-[15px]"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="relative" ref={calendarRef}>
          <div
            className={`flex items-center justify-center h-9 w-9 cursor-pointer rounded-md hover:bg-gray-100 ${
              showCalendar ? "bg-[#39F6A333] text-[#374957]" : ""
            }`}
            onClick={handleCalendarToggle}
          >
            <IoCalendar
              className={`w-5 h-5 lg:w-6 lg:h-6 ${showCalendar ? "text-[#374957]" : "text-[#64748B]"}`}
              data-testid="Calendar"
            />
          </div>
            {showCalendar && (
              <DateRangeInput startDate={selectedDateRange[0]} endDate={selectedDateRange[1]} onChange={handleDateChange} />
            )}
          </div>

          <div className="relative" ref={emailRef}>
            <div
              className={`flex items-center h-[36px] w-[60px] md:w-[90px] justify-center cursor-pointer py-2 border border-gray-300 rounded-md hover:bg-gray-100 ${showEmailList ? "bg-[#39F6A333] text-[#374957]" : ""}`}
              onClick={handleEmailListToggle}
            >
              <CiFilter className="lg:mr-2 mr-1 text-[#64748B] lg:w-[18px] lg:h-[18px] w-[14px] h-[14px] "   style={{ strokeWidth: '1.5' }} 
              />
              <span className="lg:text-[16px] font-medium text-[#64748B] text-[14px]" data-testid="EmailDropDown">Filter</span>
            </div>
            {showEmailList && (
              <EmailFilterDropdown
                connectedEmails={stores}
                disconnectedEmails={prevStoresConnected}
                selectedEmails={selectedEmails} // Pass selected emails to the dropdown
                onEmailSelectionChange={handleEmailSelectionChange}
                setShowEmailList={setShowEmailList} // Close the dropdown
                onFilterChange={handleFilterChange}
                status={
                  filterParam === "drafts"
                    ? (({ replyRequired, ...rest }) => rest)(status)
                    : { ...status }
                }
              />
            )}
          </div>
          <FiRotateCw className="lg:w-[22px] lg:h-[22px] w-[16px] h-[16px] text-[#868CA2] cursor-pointer hover:bg-gray-100" onClick={handleResetFilters} title="reset-filters" />
        </div>
      </div>

      {/* Drafts list section */}
      <div className="overflow-auto custom-scrollbar h-auto justify-between w-full lg:mb-0 mb-[20px]">
        {isFetching ? (
          <DraftShimmer />
        ) : (
          <>
            {Object.keys(groupedDrafts).length === 0 ? (
                <p className="text-center mt-28 text-red-400">  {activeTab === "sent"
                  ? "No sent emails found"
                  : activeTab === "drafts"
                    ? "No drafts found"
                    : activeTab === "all"
                      ? "No emails found"
                      : "No data available"}</p>
            ) : (
              Object.entries(groupedDrafts).map(([date, drafts]) => (
                <div key={date} className="lg:mt-1 mt-[4px]">
                  <div className="text-base lg:px-4 py-2 font-normal font-dmsans text-[#2B2E3A] border-b border-[#E7EAEE]">
                    {date}
                  </div>
                  {drafts.map((draft) => (
                    <div
                      key={draft?.id}
                      className="flex items-center gap-2 py-[12px] md:py-[16px] px-2  hover:bg-[#39F6A30D] border-b border-gray-200 w-full cursor-default"
                      onClick={() => handleNavigateToDetail(draft?.id)}
                      data-testid="draftMail"
                    >
                      <div className={`flex-shrink-0 lg:w-2 lg:h-2 w-[6px] h-[6px] ${hasUnreadEmails(draft) ? 'bg-[#39F6A3]' : ""} rounded-full`}></div>
                      <div className="flex-shrink-0 w-6 h-6 lg:w-8 lg:h-8 bg-[black] rounded-full flex items-center justify-center text-[#61F8B5] text-base lg:text-lg font-semibold lg:ml-1 ml-[2px]">
                        {getInitials(extractNameAndEmail(draft?.sender_email)?.name || extractNameAndEmail(draft?.sender_email)?.email )}
                      </div>
                      <div className="flex-1 flex flex-col md:flex-row items-start md:items-center overflow-hidden">
                        <span
                          className={`lg:block hidden font-dmsans text-sm truncate w-[150px] font-normal ${draft?.is_read ? "text-gray-400" : "text-gray-800"}`}
                        >
                          {extractNameAndEmail(draft.sender_email)?.name || extractNameAndEmail(draft.sender_email)?.email  || "Unknown sender"}
                        </span>
                        <div
                          className="text-sm text-gray-500 lg:ml-10 ml-[2px] truncate flex-1 min-w-0 lg:w-[100px]"
                        >
                          <span className="font-medium text-[#2B2E3A] text-[12px] lg:text-sm">{draft?.emails[0].subject}</span>
                          <span className="ml-2 text-[#868CA2] text-[12px] lg:text-sm font-medium">
                            {
                              DOMPurify.sanitize(draft?.emails[draft?.emails?.length - 1]?.body || '', {
                                ALLOWED_TAGS: [] // No HTML tags allowed
                              })
                                .replace(/\n+/g, ' ') // Replace line breaks with a space for inline text
                                .replace(/\s+/g, ' ') // Collapse multiple spaces into one
                            }
                          </span>
                        </div>
                        <span className="lg:hidden visible text-[10px] text-[#1B1A22] ml-[2px] md:mx-4">
                          {extractNameAndEmail(draft.store_email)?.email || ""}
                        </span>
                      </div>
                      {/* New Email ID Column */}
                      <div className="lg:block hidden text-sm text-[#1B1A22] ml-6 w-[200px] truncate">
                        {extractNameAndEmail(draft.store_email)?.email || ""}
                      </div>
                      <div className="text-xs  text-gray-500 text-right whitespace-nowrap flex flex-col md:flex-row md:space-x-1 min-w-[50px] lg:min-w-[40px] w-auto">
                        <span>{formatTime(draft?.emails[draft?.emails?.length - 1]?.created_at)}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            )}
          </>
        )}
      </div>
      {Object.keys(groupedDrafts).length > 0 && <DraftsPagination
        currentPage={page}
        onPageChange={handlePageChange}
        totalDrafts={totalDrafts}
      />}
    </div>
  );
};

export default DraftPage;
