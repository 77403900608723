import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import axios from "axios"; // Axios for API requests
import axiosInstance from "../services/axiosInstance.js";
import {
  StoreInformation,
  PurchasePolicy,
  OrderAndShippingPolicy,
  ReturnAndRefundPolicy,
  ExchangePolicy,
  DamagedOrDefectiveItemsPolicy,
  ContactInformation,
} from "../components/DocumentsPageComponents/GeneratePolicySteps";
import { useNavigate } from "react-router-dom";

const CreatePolicyPage = () => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    storeInformation: {},
    purchasePolicy: {},
    orderAndShippingPolicy: {},
    returnAndRefundPolicy: {},
    exchangePolicy: {},
    damagedOrDefectiveItemsPolicy: {},
    contactInformation: {},
  });

  const [activeStep, setActiveStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state for submission
  const API_URL = process.env.REACT_APP_API_URL;

  const steps = [
    { id: 1, title: "Store Information", key: "storeInformation" },
    { id: 2, title: "Purchase Policy", key: "purchasePolicy" },
    { id: 3, title: "Order and Shipping Policy", key: "orderAndShippingPolicy" },
    { id: 4, title: "Return and Refund Policy", key: "returnAndRefundPolicy" },
    { id: 5, title: "Exchange Policy", key: "exchangePolicy" },
    { id: 6, title: "Damaged or Defective Items Policy", key: "damagedOrDefectiveItemsPolicy" },
    { id: 7, title: "Contact Information", key: "contactInformation" },
  ];

  const handleInputChange = (stepKey, field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [stepKey]: {
        ...prevData[stepKey],
        [field]: value,
      },
    }));
  };

  const renderContent = () => {
    const stepKey = steps.find((step) => step.id === activeStep).key;
    switch (activeStep) {
      case 1:
        return <StoreInformation data={formData.storeInformation} onChange={(field, value) => handleInputChange(stepKey, field, value)} />;
      case 2:
        return <PurchasePolicy data={formData.purchasePolicy} onChange={(field, value) => handleInputChange(stepKey, field, value)} />;
      case 3:
        return <OrderAndShippingPolicy data={formData.orderAndShippingPolicy} onChange={(field, value) => handleInputChange(stepKey, field, value)} />;
      case 4:
        return <ReturnAndRefundPolicy data={formData.returnAndRefundPolicy} onChange={(field, value) => handleInputChange(stepKey, field, value)} />;
      case 5:
        return <ExchangePolicy data={formData.exchangePolicy} onChange={(field, value) => handleInputChange(stepKey, field, value)} />;
      case 6:
        return <DamagedOrDefectiveItemsPolicy data={formData.damagedOrDefectiveItemsPolicy} onChange={(field, value) => handleInputChange(stepKey, field, value)} />;
      case 7:
        return <ContactInformation data={formData.contactInformation} onChange={(field, value) => handleInputChange(stepKey, field, value)} />;
      default:
        return null;
    }
  };

  const handleNext = () => {
    if (activeStep < steps.length) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (activeStep > 1) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      const response = await  axiosInstance.post(
        `${API_URL}/v1/store/documents/generate-document`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",

          },
        }
      );

      console.log("Document generated successfully:", response.data);

      // Handle success (e.g., navigate or display success message)
      alert("Document generated successfully!");
      navigate("/document-page"); // Redirect to a success page or other appropriate route
    } catch (error) {
      console.error("Error generating document:", error.response?.data || error.message);
      alert("Failed to generate document. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className={`py-2 mb-10 font-dmsans flex flex-col ${
        isSidebarOpen ? "lg:ml-[216px] xl:ml-[230px] lg:px-[10px]" : "lg:ml-[70px] lg:px-[10px]"
      } transition-all ease-in-out duration-500`}
    >
      {/* Back Button and Header */}
      <div className="flex justify-between items-center w-full mb-2 lg:px-6">
        <span className="text-[#000000] font-dmsans font-medium lg:text-[18px]">Generate Policy</span>
        <button className="flex items-center gap-2" onClick={() => navigate(-1)}>
          <IoMdClose className="w-6 h-6" />
        </button>
      </div>

      <div className="flex flex-row h-full px-4">
        {/* Sidebar */}
        <div className="xl:w-[380px] bg-[#FFFFFF] py-3">
          <ul>
            {steps.map((step, index) => (
              <li
                key={step.id}
                className="cursor-pointer py-3 gap-2 rounded-md mb-2 flex items-center relative"
                onClick={() => setActiveStep(step.id)}
              >
                {index > 0 && (
                  <div
                    className={`absolute left-[14px] top-[-24px] h-[40px] w-[4px] rounded-[10px] ${
                      activeStep >= step.id ? "bg-[#D7FDED]" : "bg-[#F5F5F5]"
                    }`}
                  />
                )}
                <span
                  className={`w-8 h-8 shrink-0 rounded-full flex items-center justify-center text-sm font-medium mr-2 text-[#000000] ${
                    activeStep >= step.id ? "bg-[#39F6A3]" : "bg-[#F5F5F5]"
                  }`}
                >
                  {step.id}
                </span>
                <span
                  className={`text-[18px] font-medium ${
                    activeStep >= step.id ? "text-[#000000]" : "text-[#868CA2]"
                  }`}
                >
                  {step.title}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content */}
        <div className="flex-grow bg-[#FFFFFF] pt-9 pl-10 overflow-y-auto border-l border-[#868CA29C]">
          {renderContent()}
          <div className="flex justify-between mt-14">
            {activeStep > 1 && (
              <button
                className="w-[100px] h-[30px] border border-[#D0D5DD] text-[#2B2E3A] rounded-md font-medium text-sm hover:bg-gray-100"
                onClick={handlePrevious}
              >
                Previous
              </button>
            )}
            <div className="flex-grow" />
            {activeStep < steps.length ? (
              <button
                className="w-[100px] h-[30px] bg-[#96F3C3] text-[#2B2E3A] rounded-md font-medium text-sm hover:bg-green-300"
                onClick={handleNext}
              >
                Next
              </button>
            ) : (
              <button
                className="w-[100px] h-[30px] bg-[#96F3C3] text-[#2B2E3A] rounded-md font-medium text-sm hover:bg-green-300"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Loading..." : "Generate"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePolicyPage;
