import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../services/axiosInstance";
import { useSelector } from "react-redux";
import CombinedPieOrSpeedometerChart from "../../components/AdminPageComponents/AdminDashboardPieChart.jsx";
import { formatDate, getStoredValue } from "../../services/logics";
import { FaChevronRight } from "react-icons/fa6";
import DashboardOutline from "../../assets/AdminAssets/DashboardOutline.svg";
import PopupModal from "../../components/PopupModal.jsx";
import AdminUserPage from "./AdminUserPage.jsx";
import { AiOutlineClose } from "react-icons/ai";
import DateUpDown from "../../assets/documentPageAssets/DateUpdown.svg";
const AdminDashboard = () => {
  const ownerName = getStoredValue("firstname");
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState({});
  const [usersData, setUsersData] = useState([]);
  const [storesData, setStoresData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [isFetchingStores, setIsFetchingStores] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [isAscending, setIsAscending] = useState(true);

  const fetchData = async () => {
    setIsFetching(true);
    try {
      const res = await axiosInstance.get(`${API_URL}/v1/admin/dashboard`);
      const data = res.data;
      setUserData(data);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsFetching(false);
  };

  const fetchRecentStores = async () => {
    setIsFetchingStores(true);
    try {
      const res = await axiosInstance.get(
        `${API_URL}/v1/admin/dashboard/recent-stores`
      );
      const data = res.data;
      setStoresData(data); // You are setting the stores data here
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsFetchingStores(false);
  };

  const fetchRecentUsers = async () => {
    setIsFetchingUsers(true);
    try {
      const res = await axiosInstance.get(
        `${API_URL}/v1/admin/dashboard/recent-users`
      );
      const data = res.data;
      setUsersData(data);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsFetchingUsers(false);
  };

  const handleSort = () => {
    setIsAscending((prev) => !prev); // Toggle sorting order

    const sortedStores = [...storesData].sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      return isAscending ? dateB - dateA : dateA - dateB; // Sort based on ascending or descending order
    });

    setStoresData(sortedStores); // Update the state with sorted stores
  };

  useEffect(() => {
    fetchData();
    fetchRecentUsers();
    fetchRecentStores();
  }, []);
  const handleCloseModal = async () => {
    setShowUsers(false);
  };
  const total = userData?.draftcreated_count || 0;
  const sent = userData?.draftsent_count || 0;
  const deleted = userData?.draftdeleted_count || 0;
  const edited = userData?.draftedited_count || 0;

  const sentPercentage = total > 0 ? (sent / total) * 100 : 0;
  const deletedPercentage = total > 0 ? (deleted / total) * 100 : 0;
  const editedPercentage = total > 0 ? (edited / total) * 100 : 0;

  return (
    <div
      className={`py-3 mb-3 flex flex-col bg-[#F9F9FB] font-dmsans ${
        isSidebarOpen ? "ml-[230px] px-6" : "ml-[74px] px-8"
      } transition-all ease-in-out duration-500 overflow-hidden`}
    >
      <div className="text-xl font-medium capitalize text-[#000000] max-md:max-w-full ml-2">
        Welcome back! {ownerName} 👋
      </div>
      <div
        className="w-full flex flex-wrap justify-between gap-4 mt-1 py-4 px-16 h-[170px]"
        style={{
          backgroundImage: `url(${DashboardOutline})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Total Store */}
        <div className="bg-white rounded-lg h-[120px] flex-grow p-7 flex flex-col  max-w-[26%] min-w-[150px]">
          <span className="text-[#000000] text-sm font-medium mb-2">
            TOTAL STORES
          </span>
          {isFetching ? (
            <div className="bg-gray-300 h-4 mt-2 w-40 rounded-lg animate-pulse"></div>
          ) : (
            <span className="text-[#18181B] font-plus-jakarta text-[22px] font-bold">
              {userData?.total_store_count}
            </span>
          )}
        </div>

        {/* Total Users */}
        <div className="bg-white rounded-lg h-[120px] flex-grow p-7 flex flex-col  max-w-[26%] min-w-[150px]">
          <span className="text-[#000000] text-sm font-medium mb-2">
            TOTAL USERS
          </span>
          {isFetching ? (
            <div className="bg-gray-300 h-4 mt-2 w-40 rounded-lg animate-pulse"></div>
          ) : (
            <span className="text-[#18181B] font-plus-jakarta text-[22px] font-bold">
              {userData?.total_user_count}
            </span>
          )}
        </div>

        {/* Total Draft */}
        <div className="bg-white rounded-lg h-[120px] flex-grow p-7 flex flex-col  max-w-[26%] min-w-[150px]">
          <span className="text-[#000000] text-sm font-medium mb-2">
            TOTAL DRAFTS
          </span>
          {isFetching ? (
            <div className="bg-gray-300 h-4 mt-2 w-40 rounded-lg animate-pulse"></div>
          ) : (
            <span className="text-[#18181B] font-plus-jakarta text-[22px] font-bold">
              {userData?.draftcreated_count}
            </span>
          )}
        </div>
      </div>
      <div className="w-full grid grid-cols-6 gap-6 p-2 mt-2 bg-[#F9F9FB] rounded-lg">
        {/* Draft Created + Total Draft Pie Chart Section */}
        <div className="bg-white shadow-md rounded-lg p-3 col-span-4 grid grid-cols-2 gap-6">
          {/* Draft Created Section */}
          <div className="col-span-1 w-[354px]">
            <h2 className="font-bold text-[16px] text-[#191D23]">Drafts</h2>

            <div className="mt-4">
              {/* Total */}
              <div className="flex justify-between items-center mb-1">
                <span className="text-[#18181B] text-[13px] font-plus-jakarta">
                  Total
                </span>
                <span className="text-[#18181B] text-[13px] font-plus-jakarta">
                  {userData?.draftcreated_count}
                </span>
              </div>
              <div className="h-2 bg-[#F3F4F6] rounded-full mb-4">
                <div
                  className="h-full bg-[#39F6A3] rounded-full"
                  style={{ width: "100%" }}
                ></div>
              </div>

              {/* Sent */}
              <div className="flex justify-between items-center mb-1">
                <span className="text-[#18181B] text-[13px] font-plus-jakarta">
                  Sent
                </span>
                <span className="text-[#18181B] text-[13px] font-plus-jakarta">
                  {userData?.draftsent_count}
                </span>
              </div>
              <div className="h-2 bg-[#F3F4F6] rounded-full mb-4">
                <div
                  className="h-full bg-[#39F6A3] rounded-full"
                  style={{ width: `${sentPercentage}%` }}
                ></div>
              </div>

              {/*Edited*/}
              <div className="flex justify-between items-center mb-1">
                <span className="text-[#18181B] text-[13px] font-plus-jakarta">
                  Edited
                </span>
                <span className="text-[#18181B] text-[13px] font-plus-jakarta">
                  {userData?.draftedited_count}
                </span>
              </div>
              <div className="h-2 bg-[#F3F4F6] rounded-full mb-4">
                <div
                  className="h-full bg-[#39F6A3] rounded-full"
                  style={{ width: `${editedPercentage}%` }}
                ></div>
              </div>

              {/* Deleted */}
              <div className="flex justify-between items-center mb-1">
                <span className="text-[#18181B] text-[13px] font-plus-jakarta">
                  Deleted
                </span>
                <span className="text-[#18181B] text-[13px] font-plus-jakarta">
                  {userData?.draftdeleted_count}
                </span>
              </div>
              <div className="h-2 bg-[#F3F4F6] rounded-full mb-4">
                <div
                  className="h-full bg-[#39F6A3] rounded-full"
                  style={{ width: `${deletedPercentage}%` }}
                ></div>
              </div>
            </div>
          </div>

          {/* Total Draft Pie Chart Section */}
          <div className="col-span-1 flex flex-col items-end justify-center">
            <CombinedPieOrSpeedometerChart
              total={total}
              label="Total Drafts"
              isSpeedometer={false}
              sentPercentage={sentPercentage}
              deletedPercentage={deletedPercentage}
              editedPercentage={editedPercentage}
            />
          </div>
        </div>

        {/* Draft Actions Speedometer Section */}
        <div className="bg-white shadow-md rounded-lg p-3 col-span-2 flex flex-col">
          <div className="font-bold text-[16px] text-[#191D23]">
            Actions triggered
          </div>
          <CombinedPieOrSpeedometerChart
            total={userData?.action_stats}
            isSpeedometer={true}
            label="Total Actions"
          />
        </div>
      </div>

      {/* Recently Added Stores */}
      <div className="grid grid-cols-6 gap-6 mt-8">
        {/* Recently added stores (spanning 2 columns) */}
        <div className="bg-[#FFFFFF] p-3 rounded-md border border-[#E4E4E7] col-span-4 w-full">
          <div className="flex justify-between items-center mb-2">
            <div className="text-[15px] font-plus-jakarta font-bold text-[#18181B] mb-3">
              Recently added stores
            </div>
            <div
              className="text-[#151518] text-[11px] font-medium cursor-pointer mb-3 font-plus-jakarta flex items-center"
              onClick={() => navigate("/admin/store-page")}
            >
              <span>See all stores</span>
              <span className="ml-1">
                <FaChevronRight className="text-[#151518] w-2 h-2" />
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center mb-4 py-2 rounded-md bg-[#E1E1E64D] text-[#2B2E3A] font-medium text-[14px]">
            <div className="w-[20%] pr-1 pl-2">Status</div>
            <div className="w-[15%] pl-1">Store ID</div>
            <div className="w-[33%] pl-2">Store name</div>
            <div
              className="w-[16%] pr-3 flex items-center ml-4 cursor-pointer"
              onClick={handleSort}
            >
              <span>Created on</span>
              <img
                className="ml-2 w-4 h-4"
                src={DateUpDown}
                alt="Sort Icon"
                loading="lazy"
              />
            </div>
            <div className="w-[17%] text-right pr-2">More</div>
          </div>

          {isFetchingStores ? (
            <div className="space-y-4">
              {[...Array(3)].map((_, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-4 p-3 w-full animate-pulse"
                >
                  {/* Status shimmer */}
                  <div className="flex items-center justify-center w-[15%]">
                    <div className="w-6 h-6 rounded-full bg-gray-300"></div>
                    <div className="ml-2 w-16 h-4 rounded-full bg-gray-300"></div>
                  </div>

                  {/* Store ID shimmer */}
                  <div className="w-[15%] h-4 rounded-full bg-gray-300"></div>

                  {/* Store Name and Owner shimmer */}
                  <div className="flex-1 space-y-2 pl-14">
                    <div className="h-4 w-32 bg-gray-300 rounded"></div>
                    <div className="h-4 w-20 bg-gray-300 rounded"></div>
                  </div>

                  {/* Date shimmer */}
                  <div className="w-[15%] h-4 bg-gray-300 rounded"></div>

                  {/* View shimmer */}
                  <div className="w-[10%] h-4 bg-gray-300 rounded"></div>
                </div>
              ))}
            </div>
          ) : (
            storesData?.map((store, index) => (
              <div
                className="flex items-center mb-4 w-full"
                key={store.id || index}
              >
                {/* Status Indicator (20%) */}
                <div className="flex items-center justify-start  w-[20%]">
                  <div
                    className={`flex items-center text-xs px-2  rounded-full py-1 ${
                      store.is_active ? "bg-[#DCFCE7]" : "bg-gray-200"
                    }`}
                  >
                    <span
                      className={`w-2 h-2 rounded-full ${
                        store.is_active ? "bg-[#22C55E]" : "bg-gray-500"
                      }`}
                    ></span>
                    <span
                      className={`ml-1 text-xs font-medium ${
                        store.is_active ? "text-[#14532D]" : "text-gray-600"
                      }`}
                    >
                      {store.is_active ? "Active" : "In-active"}
                    </span>
                  </div>
                </div>

                {/* Store ID (15%) */}
                <div className="text-xs text-[#868CA2] font-medium w-[15%]">
                  {store?.store_id ? `#${store.store_id}` : ""}
                </div>

                {/* Store Name (30%) */}
                <div className="w-[33%] mr-1 flex flex-col">
                  <div className="text-xs font-bold text-[#18181B]">
                    {store?.name}
                  </div>
                  <div className="text-xs text-[#868CA2]">
                    {store?.owner_name}
                  </div>
                </div>

                {/* Creation Date (20%) */}
                <div className="text-xs font-medium text-[#18181B] w-[16%]">
                  {formatDate(store?.created_at)}
                </div>

                {/* View Link (15%) */}
                <div
                  className="text-[11px] text-[#868CA2] font-medium cursor-pointer flex items-center justify-end w-[15%]"
                  onClick={() =>
                    navigate(`/admin/store-page/details/${store.id}`)
                  }
                >
                  <span>View</span>
                  <span className="ml-1">
                    <FaChevronRight className="text-[#868CA2] w-2 h-2" />
                  </span>
                </div>
              </div>
            ))
          )}
        </div>

        {/* Recent added Users (spanning 1 column) */}
        <div className="bg-[#FFFFFF] p-3 rounded-md border border-[#E4E4E7] col-span-2">
          <div className="text-[15px] font-plus-jakarta font-bold text-[#18181B] mb-3">
            Recently added users
          </div>

          {isFetchingUsers
            ? [...Array(3)].map((_, idx) => (
                <div
                  key={idx}
                  className="flex items-center space-x-4 p-3 w-full"
                >
                  {/* Image shimmer */}
                  <div className="w-12 h-12 rounded-full bg-gray-300 animate-pulse"></div>

                  {/* Text shimmer */}
                  <div className="flex-1 space-y-4 py-1">
                    <div className="h-4 w-1/4 bg-gray-300 rounded animate-pulse"></div>
                    <div className="h-4 w-[60%] bg-gray-300 rounded animate-pulse"></div>
                  </div>

                  {/* Status shimmer */}
                  <div className="h-4 w-14 bg-gray-300 rounded animate-pulse"></div>
                </div>
              ))
            : usersData?.map((user, index) => (
                <div
                  className="flex justify-between items-center mb-4"
                  key={user.id || index}
                >
                  {/* 90% for user info */}
                  <div className="flex items-center w-[80%]">
                    <img
                      src={user?.profile_image}
                      alt={user.first_name}
                      className="w-6 h-6 rounded-full mr-2"
                    />
                    <div>
                      <div className="text-[11px] font-bold font-plus-jakarta text-[#18181B]">
                        {user.first_name || user.last_name
                          ? `${user.first_name || ""} ${user.last_name || ""}`
                          : ""}
                      </div>
                      <div className="text-[11px] text-[#71717A] font-semibold break-all whitespace-normal">
                        {user?.email || ""}
                      </div>
                    </div>
                  </div>

                  {/* 10% for status */}
                  <div className="w-[18%] text-right">
                    <div
                      className={`ml-1 text-xs font-medium ${
                        user.is_active ? "text-[#039855]" : "text-gray-600"
                      }`}
                    >
                      {user.is_active ? "Active" : "In-active"}
                    </div>
                  </div>
                </div>
              ))}

          <div
            className="text-[#151518] text-[11px] font-medium cursor-pointer mt-3 font-plus-jakarta flex items-center"
            onClick={() => setShowUsers(true)}
          >
            <span>See all users</span>
            <span className="ml-1">
              <FaChevronRight className="text-[#151518] w-2 h-2" />
            </span>
          </div>
        </div>
      </div>

      {showUsers && (
        <PopupModal
          data-testid="users-modal"
          closeModal={handleCloseModal}
          outerClassName="justify-end mr-6 "
          className="bg-[#FFFFFF] max-h-[600px] w-[630px] overflow-y-auto custom-scrollbar rounded-lg shadow-md px-4"
        >
          <div className="flex justify-between items-center  ">
            <h2 className="text-lg font-semibold text-gray-800">Users</h2>
            <button
              data-testid="modal-close-button"
              onClick={handleCloseModal}
              className="text-[#000000] font-bold hover:text-gray-700"
            >
              <AiOutlineClose size={18} />
            </button>
          </div>
          <AdminUserPage />
        </PopupModal>
      )}
    </div>
  );
};

export default AdminDashboard;
