import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import PopupModal from "../components/PopupModal";
import RestoreIcon from "../assets/IntegrationPageAssets/RestoreIcon.svg";
import { MdKeyboardArrowRight, MdQuickreply, MdOutlineQuickreply } from "react-icons/md";
import { LuTrash2 } from "react-icons/lu";
import { toast } from "react-toastify";
import EmailSkeleton from "../components/EmailSkeleton";
import { formatCustomDateTime, formatTime } from "../services/logics";
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import DraftDetailsSidebar from "../components/DraftDetailsSidebar.jsx";
import { extractNameAndEmail, useClickOutsideDropdown } from "../services/emailUtils.js";
import RefundConfirmation from "../components/RefundConfirmation.jsx";
import AttachmentItem from "../components/DraftDetailPageComponents/AttachmentItem.jsx";
import NewAddressForm from "../components/DraftDetailPageComponents/NewAddressform.jsx";
import SucessModal from "../components/SucessModal.jsx";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import ExchangeItem from "../components/DraftDetailPageComponents/ExchangeItem.jsx";
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import axiosInstance from "../services/axiosInstance.js";
import ExampleDropdown from "../components/DraftDetailPageComponents/ExampleDropdown.jsx";
import { HiEllipsisVertical } from "react-icons/hi2";
import { LuLanguages } from "react-icons/lu";
import DOMPurify from 'dompurify';
const DraftDetailPage = () => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  const [selectedReason, setSelectedReason] = useState("Draft should not have been created");
  const [selectedDraftReason, setSelectedDraftReason] = useState("Draft quality is not good");
  const [draftData, setDraftData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [draftEmail, setDraftEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showSentModal, setShowSentModal] = useState(false);
  const [showSentMailModal, setShowSentMailModal] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false)
  const API_URL = process.env.REACT_APP_API_URL;
  const [sentMessage, setSentMessage] = useState("");
  const [sendingDraft, setSendingDraft] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [isFetchingOrderData, setIsFetchingOrderData] = useState(true);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [refundSucess, setRefundSucess] = useState(false)
  const [showNewAddress, setShowNewAddress] = useState(false)
  const [addressUpdate, setAddressUpdated] = useState(false)
  const [addressSucessModal, setAddressSucessModal] = useState(false)
  const [expandedEmailIndex, setExpandedEmailIndex] = useState(null);
  const [isExchnageItem, setIsExchangeItem] = useState(null);
  const [deleteContext, setDeleteContext] = useState('');
  const [updatingDraft, setUpdatingDraft] = useState(false);
  const [orderExchangeUpdate, setorderExchangeUpdated] = useState(false)
  const [exchnageSucessModal, setExchangeSucessModal] = useState(false)
  const [exampleDraft, setExampleDraft] = useState(false)
  const [showDiscountModal, setShowDiscountModal] = useState(false)
  const [showDetailsSidebar, setShowDetailsSidebar] = useState(false)
  const dropdownRef = useRef(null);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [showCreateExampleModal, setshowCreateExampleModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [translatedContent, setTranslatedContent] = useState({});
  const [isTranslating, setIsTranslating] = useState(false);
  const [detectedLanguage, setDetectedLanguage] = useState('')
  const [isTranslatingDraft, setIsTranslatingDraft] = useState(false);
  const [showTooltip, setShowTooltip] = useState('');
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const filterParam = new URLSearchParams(location.search).get("filter")
  const [lastEmail, setLastEmail] = useState({});
  const [isFetchingAllOrders, setIsFetchingAllOrders] = useState(false);
  const [allOrders, setAllOrders] = useState([]);
  useClickOutsideDropdown(dropdownRef, setShowDropDown);

  const getDetectedLanguage = () => {
    const preferredLanguage = sessionStorage.getItem("preferedlanguage");
    return (preferredLanguage && preferredLanguage !== 'null') ? preferredLanguage : "English";
  };


  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    // Expand the last email by default
    if (draftData?.emails?.length > 0) {
      const lastEmailIndex = draftData?.emails?.length - 1;
      setExpandedEmailIndex(lastEmailIndex);
      const lastEmailDraft = draftData?.emails[lastEmailIndex]?.drafts?.[0]?.final_draft || "";
      setDraftEmail(lastEmailDraft)
      setLastEmail(draftData?.emails[lastEmailIndex]);
      updateIsReadStatus(draftData?.emails);
    }
  }, [draftData]);

  const toggleEmailExpansion = (index) => {
    setExpandedEmailIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCloseSentModal = () => {
    setShowSentModal(false);
    setShowDiscountModal(false)
    setshowCreateExampleModal(false)
  };
  const fetchAllData = () => {
    fetchData();
    setShouldFetchData(true);
  };
  const handleCloseSentMailModal = () => {
    setShowSentMailModal(false);
  };
  const handleDraftChange = (eOrText, translated = false) => {
    const updatedDraft = typeof eOrText === 'string' ? eOrText : eOrText?.target?.value;
    if (!isTranslating || (isTranslating && translated)) {
      setDraftEmail(updatedDraft);
      debouncedUpdateDraftHandler(updatedDraft, false);
      { translated && handleTranslate() }
    } else {
      setTranslatedContent((prevContent) =>
        updateFinalDraft(prevContent, updatedDraft)
      );
    }
  };

  const handleOpenDeleteMailModal = () => {
    setDeleteContext('mail');
    setShowModal(true);
  };

  const handleOpenDeleteDraftModal = () => {
    setDeleteContext('draft');
    setShowModal(true);
  };


  const fetchEmailsData = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/store/conversations/${param.id}`
      );
      const data = response.data;
      setDraftData(data);
      handleDraftTranslate(getDetectedLanguage(), false, data?.summary, false);
      fetchOrderData(data);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };

  const fetchData = async () => {
    setIsFetching(true);
    try {
      await fetchEmailsData();
    } catch (error) {
      console.error("Error during data fetch", error);
    } finally {
      setIsFetching(false);
    }
  };


  const handleSyncConversations = async () => {
    setIsSyncing(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/store/conversations/${param?.id}/sync`
      );
      const data = response.data;
      setShowTooltip('');
      fetchData();
    } catch (error) {
      const errorMessage = error.response?.data?.detail || "An error occurred while syncing conversations.";
      toast.error(errorMessage);
    } finally {
      setIsSyncing(false);
    }
  };


  const updateIsReadStatus = async (emails) => {
    if (!emails || emails?.length === 0) return;

    // Check if all emails are already read
    const hasUnread = emails?.some((mail) => !mail?.is_read);
    if (!hasUnread) return; // Exit if all emails are read

    for (const mail of emails) {
      if (!mail?.is_read) {
        try {
          await axiosInstance.patch(
            `${API_URL}/v1/store/conversations/${param?.id}/emails/${mail?.id}`,
            { is_read: true }
          );
        } catch (error) {
          toast.error(`${error.response?.data?.detail}`);
        }
      }
    }
  };


  const updateIsReplyRequired = async () => {
    try {
      const newReplyRequiredStatus = !draftData.is_reply_required;
      await axiosInstance.patch(
        `${API_URL}/v1/store/conversations/${draftData?.conversation_id}`,
        {
          is_reply_required: newReplyRequiredStatus,
        },
      );

      setDraftData((prevDraftData) => ({
        ...prevDraftData,
        is_reply_required: newReplyRequiredStatus,
      }));

    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };

  useEffect(() => {
    setOrderData(null)
    setAllOrders([]);
    fetchData();
    setShowRefundModal(false);
    setRefundSucess(false);
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") {
        handleCloseModal();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [param?.id]);

  const handleCloseModal = () => {
    setShowModal(false);
    setDeleteContext('')
  };


  const handleSendDraftModal = () => {
    setShowSentModal(true);
  };


  const handleSendDraft = async () => {
    try {
      setSendingDraft(true);
      const res = await axiosInstance.post(
        `${API_URL}/v1/store/conversations/${param?.id}/emails/${lastEmail?.id}/drafts/${lastEmail?.drafts?.[0]?.id}/send`,
      );

      const data = res.data;
      fetchAllData();
      setShowTooltip("sent")
      setSentMessage(data?.message || "Email sent successfully");
      setShowSentMailModal(true);
    } catch (error) {
      const errorMessage = error.response?.data?.detail || "An error occurred while sending the draft.";
      toast.error(errorMessage);
    } finally {
      setSendingDraft(false);
      setShowSentModal(false);
    }
  };

  const handleCreateDraft = async (updatedDraft) => {
    try {
      setUpdatingDraft(true);
      const payload = {
        email_id: lastEmail?.id,
        final_draft: updatedDraft,
      };

      const res = await axiosInstance.post(
        `${API_URL}/v1/store/conversations/${param?.id}/emails/${lastEmail?.id}/drafts`,
        payload
      );

      const data = res.data;
      fetchEmailsData();
    } catch (error) {
      const errorMessage = error.response?.data?.detail || "An error occurred while sending the draft.";
      toast.error(errorMessage);
    } finally {
      setUpdatingDraft(false);
    }
  };


  const updateDraft = async (updatedDraft, updateActions, delete_reason = "") => {
    if (lastEmail?.drafts?.length === 0) {
      handleCreateDraft(updatedDraft);
      return;
    }
    setUpdatingDraft(true);
    try {
      let payload = {
        final_draft: updatedDraft,
        delete_reason: delete_reason
      };

      if (updateActions) {
        const updatedActions = draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data.actions.filter(action => action !== 'order_refund');

        payload.actions_data = {
          ...draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data,
          actions: updatedActions
        };
      }

      const res = await axiosInstance.patch(
        `${API_URL}/v1/store/conversations/${param?.id}/emails/${lastEmail?.id}/drafts/${lastEmail?.drafts?.[0]?.id}`,
        payload,);

      const data = res.data;
      if (delete_reason) {
        setDraftEmail(""); // Clear the textarea content
      }
      handleCloseModal();
      if (res.status === 200 && updateActions) {
        fetchAllData();
      }
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setUpdatingDraft(false)
  };

  const debouncedUpdateDraftHandler = useCallback(debounce((updatedDraft, updateActions) => {
    updateDraft(updatedDraft, updateActions);
  }, 500), [lastEmail]);

  const fetchOrderData = async (emailsList) => {
    setIsFetchingOrderData(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/store/draft/orders?draft_id=${emailsList?.emails[emailsList?.emails?.length - 1]?.drafts?.[0]?.id}`,

      );
      const data = response.data;
      setOrderData(data);
      fetchAllOrdersData(data);
    } catch (error) {
      setOrderData(null);
    }
    setIsFetchingOrderData(false);
  };

  const fetchAllOrdersData = async (order) => {
    setIsFetchingAllOrders(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/store/customers/${order?.[0]?.customer?.id}/orders`

      );
      const data = response.data;
      setAllOrders(data);
    } catch (error) {
      setAllOrders([])
    }
    setIsFetchingAllOrders(false);
  };

  const handleOpenRefundModal = () => {
    setShowRefundModal(true);
  };

  const getActionsIconPath = (dataType) => {
    const type = typeof dataType === 'string' ? dataType?.toLowerCase() : '';
    if (type.includes("address")) {
      return { name: 'Update Address', action: handleShowNewAddress };
    } else if (type.includes("refund")) {
      return { name: 'Refund', action: handleOpenRefundModal };
    } else if (type.includes("change")) {
      return { name: 'Exchange Item', action: handleExchangeItem };
    } else if (
      type.replace(/_/g, " ").includes("invalid discount code") ||
      type.replace(/_/g, " ").includes("invalid giftcard code")
    ) {
      return { name: 'Update Code', action: handleDiscountCode };
    }
  };

  const handleShowNewAddress = () => {
    setShowNewAddress(true)
  }
  const handleClosShowNewAddress = () => {
    setShowNewAddress(false)
  }
  const handleCloseAddressModal = () => {
    setAddressSucessModal(false)
    handleClosShowNewAddress();
    setAddressUpdated(true);
    setShouldFetchData(true);
    fetchOrderData(draftData);
  }

  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : "";
  };

  const handleExchangeItem = () => {
    setIsExchangeItem(true)
  }
  const handleClosExchangeItem = () => {
    setIsExchangeItem(false)
  }
  const handleCloseExchangeModal = () => {
    setExchangeSucessModal(false)
    handleClosExchangeItem();
    setorderExchangeUpdated(true);
    setShouldFetchData(true);
    fetchOrderData(draftData);
  }

  const handleExampleDraft = async (example_id) => {
    try {
      setExampleDraft(true);

      const res = await axiosInstance.patch(
        `${API_URL}/v1/store/drafts/update/${lastEmail?.drafts?.[0]?.id}`, {
        example_id: example_id
      },

      );
      const data = res.data;
      if (isTranslating) {
        setTranslatedContent((prevContent) =>
          updateFinalDraft(prevContent, data?.final_draft)
        );
      } else {
        setDraftEmail(data?.final_draft)
      }

    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setExampleDraft(false);
  };

  const handleDiscountCode = () => {
    setShowDiscountModal(true)
  }
  const handleUpdateDiscount = async () => {
    setSendingDraft(true);
    let text
    let actionName = draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.actions?.[0] || draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.actions?.[0]?.name;
    if (actionName?.replace(/_/g, " ").includes("invalid discount code")) {
      text = 'discount'
    } else if (actionName?.replace(/_/g, " ").includes("invalid giftcard code")) {
      text = 'giftcard'
    }
    let actiondata = draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.action_details
    let res;

    try {
      if (text === 'discount') {
        res = await axiosInstance.post(
          `${API_URL}/v1/store/shopify/order/update_discount_code`, {
          draft_id: param.id,
          discount_code: actiondata?.discount_code || actiondata?.code,
        },);
      } else if (text === 'giftcard') {
        res = await axiosInstance.post(
          `${API_URL}/v1/store/shopify/order/update_giftcard`, {
          draft_id: param.id,
          thread_id: draftData?.conversation_id,
          order_id: draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.gift_card_responses?.[0]?.order_id?.toString(),
          gift_card_id: draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.action_details?.gift_card_responses?.[0]?.id?.toString(),
        },
        );
      }
      const data = res.data;
      if (res.status === 200) {
        fetchData();
        setShouldFetchData(true);
        setShowDiscountModal(false);
      }
    } catch (error) {
      if (error?.response?.status === 422) {
        toast.error('Missing required fields');
      } else {
        toast.error(error.response?.data?.detail);
      }

    }
    finally {
      setSendingDraft(false);
    }
  };

  const handleCreateExampleModal = () => {
    setshowCreateExampleModal(true);
  }

  const handleCreateExampleDraft = async () => {
    setIsFetchingData(true);
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/store/drafts/examples/${lastEmail?.drafts?.[0]?.id}`,
      );
      const data = response.data;
      navigate("/create-example", {
        state: { data, id: param?.id, filter: filterParam } // Directly passing param?.id
      });
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsFetchingData(false);
  };


  const handleSwitchLanguage = () => {
    let language;

    if (detectedLanguage === '') {
      language = getDetectedLanguage();
    } else if (getDetectedLanguage() === translatedContent?.detected_language) {
      language = detectedLanguage;
    } else {
      language = getDetectedLanguage();
    }

    return language;
  };

  const handleTranslate = async () => {
    try {
      // Check if a language is provided before proceeding
      let language = handleSwitchLanguage();
      if (!language) {
        toast.error("Please select a default language under your profile.");
        return;
      }
      setIsTranslating(!isTranslating);
      setIsTranslatingDraft(true);

      // Make the API call to translate the text
      const response = await axiosInstance.post(`${API_URL}/v1/translate/conversation`, {
        conversation_id: param?.id,
        target_language: language,
      });
      const data = response?.data
      setDetectedLanguage(data?.detected_language)
      setTranslatedContent({
        ...data,
        detected_language: language, // Set target_language explicitly
      });
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsTranslatingDraft(false);
  };

  const handleDraftTranslate = async (language, save = false, draftemail, isNotSummary = true) => {
    try {
      { isNotSummary && setIsTranslatingDraft(true); }
      { !isNotSummary && setIsSummaryLoading(true); }
      // Make the API call to translate the text
      const response = await axiosInstance.post(`${API_URL}/v1/translate/text`, {
        text: draftemail,
        target_language: language,
      });
      const translatedText = response?.data?.translated_text || '';
      if (isNotSummary) {
        setTranslatedContent((prevContent) =>
          updateFinalDraft(prevContent, translatedText)
        );
        if (save) {
          handleDraftChange(translatedText, true);
        }
      } else {
        setDraftData((prevData) => ({
          ...prevData,
          summary: translatedText,
        }));
      }
    } catch (error) {
      { isNotSummary && toast.error(`${error.response?.data?.detail}`); }
    } finally {
      { isNotSummary && setIsTranslatingDraft(false); }
      { !isNotSummary && setIsSummaryLoading(false); }
    }
  };

  const updateFinalDraft = (prevContent, updatedDraft) => {
    // Make a deep copy of the existing structure
    const updatedEmails = [...(prevContent?.emails || [])];

    if (updatedEmails.length > 0) {
      const lastEmail = { ...updatedEmails[updatedEmails.length - 1] };

      if (lastEmail.drafts && lastEmail.drafts.length > 0) {
        const lastDraft = { ...lastEmail.drafts[0] };
        lastDraft.final_draft = updatedDraft; // Update the specific property
        lastEmail.drafts[0] = lastDraft;
      } else {
        lastEmail.drafts = [
          {
            final_draft: updatedDraft,
          },
        ];
      }

      updatedEmails[updatedEmails.length - 1] = lastEmail;
    }

    return {
      ...prevContent,
      emails: updatedEmails,
    };
  }

  return (
    <div className={`${isSidebarOpen ? 'xl:ml-[242px] lg:ml-[220px]' : 'lg:ml-[80px]'} transition-all duration-300 bg-[#FFFFFF] h-screen font-dmsans`}>
      <div className="flex flex-col h-auto bg-[#FFFFFF]">
        <div className="lg:flex lg:flex-1">
          <div className={`${showDetailsSidebar ? 'hidden' : 'flex flex-col'} lg:w-[calc(100%-340px)] xl:w-full lg:flex lg:flex-col sm:mx-2 lg:mx-0 flex-grow ${showRefundModal ? 'py-1' : 'py-[6px] lg:py-3 lg:ml-5'} `}>
            {!showRefundModal ? (
              <div className="flex flex-col flex-grow ">
                <div className="flex items-start justify-between font-medium">
                  <div className="flex items-center pt-1">
                    <button data-testid="back-button" className="flex items-center lg:mr-4" onClick={() => navigate(`/emails-page?filter=${filterParam}`, { state: location.state })}>
                      <IoIosArrowRoundBack className="w-7 h-7 text-[#2B2E3A]" />
                    </button>
                  </div>

                  <div className="flex items-center space-x-2">
                    {refundSucess ? (
                      <div className="inline-flex items-center px-3 h-[30px] lg:h-[38px] bg-[#39F6A31A] rounded-md">
                        <div className="h-2.5 w-2.5 bg-[#28FB03] rounded-full mr-2 animate-pulse"></div>
                        <span className="text-[#000000A1] font-medium text-[10px]">Refunded</span>
                      </div>) : ""}

                    <button
                      className={`relative flex h-[32px] items-center justify-center gap-1.5 p-1.5  text-[12px] leading-tight rounded-md cursor-pointer  ${draftData?.is_reply_required === false ? "text-[#D92D20]" : "text-[#2B2E3A]"}`}
                      onClick={updateIsReplyRequired}
                      onMouseEnter={() => setShowTooltip("flag")}
                      onMouseLeave={() => setShowTooltip("")}
                    >
                      {draftData?.is_reply_required === false ? (
                        <MdQuickreply size={24} />) : (<MdOutlineQuickreply size={24} />)}

                        <span className="text-left">Mark as reply<br />not required</span>

                      {showTooltip === "flag" && (
                        <div className="absolute top-9 w-[200px] text-black font-normal bg-[#EFF8F4] text-xs rounded-md p-1 shadow-sm z-10 text-center right-0">
                          Mark this email for feedback. AI will not suggest drafts for future emails with similar content.
                        </div>
                      )}
                    </button>

                    <div className="relative w-[40px] h-[40px] flex items-center justify-center" onClick={handleTranslate}
                      onMouseEnter={() => setShowTooltip('language')}
                      onMouseLeave={() => setShowTooltip('')}>
                      <LuLanguages size={24} className="text-[#2B2E3A]" />
                      {(showTooltip === 'language') && (
                        <div className="absolute top-8 mb-2 w-[150px] text-black font-normal bg-[#EFF8F4] text-xs rounded-md p-1 right-0 shadow-sm z-10 text-center">
                          {!handleSwitchLanguage() ? 'Select a default language' : `Translate to ${handleSwitchLanguage()}`}
                        </div>
                      )}
                    </div>

                    {/* <button className={`flex h-[32px] items-center justify-center gap-1.5  pl-1.5 pr-2.5 text-[12px] leading-snug rounded-md bg-white text-[#868CA2] cursor-pointer`}
                      onClick={handleOpenDeleteMailModal}                      >
                      <LuTrash2 className="w-[18px] h-[18px] " />
                      <span>Delete</span>
                    </button> */}
                  </div>
                </div>
                <button className="lg:hidden visible text-[#2B2E3A] w-full text-[13px] mt-1 sm:pt-2 text-right  underline flex justify-end items-center">
                  <span className="bg-[#D7FDED]  font-medium p-1 flex items-center" onClick={() => setShowDetailsSidebar(true)}>
                    Show details
                    <MdKeyboardArrowRight size={16} />
                  </span>
                </button>
                <div className="flex flex-col  mt-3 bg-white ">
                  {isFetching || isTranslatingDraft ? (
                    <EmailSkeleton />
                  ) : (
                    <div className="flex flex-col overflow-y-auto custom-scrollbar overflow-x-hidden min-h-[400px] mx-1">
                      {/* Mail Content Section */}
                      <div className="flex-grow">
                        <div data-testid='draft-subject' className="text-base font-medium text-[#2B2E3A] mb-3 lg:mx-1 mx-[8px]">
                          {isTranslating ? translatedContent?.emails?.[0]?.subject : draftData?.emails?.[0]?.subject}
                        </div>
                        {(isTranslating ? translatedContent?.emails : draftData?.emails)?.map((data, index) => (
                          <React.Fragment key={index}>
                            <div
                              className={`flex justify-between gap-5 font-medium mr-2 cursor-pointer ml-[8px] mb-3 ${expandedEmailIndex !== index ? 'border-b border-[#868CA280]' : ''}`}
                              onClick={() => toggleEmailExpansion(index)}
                            >
                              <div className="flex flex-col w-full">
                                <div className="flex items-center gap-2.5 justify-between">
                                  <div className="flex items-center gap-2.5">
                                    <div className="flex-shrink-0 w-8 h-8 bg-[black] rounded-full flex items-center justify-center text-[#61F8B5] text-base lg:text-lg font-semibold">
                                      {draftData?.emails[index]?.sender_email ? getInitials(extractNameAndEmail(draftData?.emails[index]?.sender_email)?.name) : getInitials('Unknown sender')}
                                    </div>
                                    <div className="flex flex-col">
                                      <div className="text-xs font-medium text-[#1B1A22]">
                                        {draftData?.emails[index]?.sender_email ? extractNameAndEmail(draftData?.emails[index]?.sender_email)?.name : 'Unknown sender'}
                                      </div>
                                      <div className="text-[11px] font-medium text-[#868CA2]">
                                        {draftData?.emails[index]?.sender_email ? extractNameAndEmail(draftData?.emails[index]?.sender_email)?.email : 'Unknown sender'}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="ml-auto flex flex-col font-medium text-[11px] text-[#868CA2]">
                                    <div className="flex space-x-1 text-[#1B1A22]">
                                      <span>{formatCustomDateTime(data?.created_at, true)}</span>
                                    </div>
                                    <span>{formatTime(data?.created_at)}</span>
                                  </div>
                                </div>
                                {/* Conditionally display partial or full content */}
                                <div className="text-[12px] lg:text-[14px] font-medium text-[#868CA2] mt-2 overflow-x-hidden">
                                  {expandedEmailIndex === index ? (
                                    <div data-testid='emailContent' className="whitespace-pre-wrap break-words break-all font-dmsans  font-medium leading-14 text-[#868CA2]  mb-3 pb-2"
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize((data?.body || "").replace(/(https?:\/\/[^\s]+)/g,
                                          '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
                                        ),
                                          {
                                            ALLOWED_TAGS: ["a", "b", "i", "em", "strong", "span", "div", "p", "img"],
                                            ALLOWED_ATTR: ["href", "target", "rel", "src", "alt", "title", "width", "height"],
                                          }
                                        ),
                                      }}
                                    />
                                  ) : (
                                    <div className="whitespace-pre-wrap break-words break-all font-dmsans font-medium leading-14 text-[#868CA2] mb-2 pb-2"
                                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.body.slice(0, 100) + "...") }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            {expandedEmailIndex === index && data?.attachments && data?.attachments.length > 0 && (
                              <div className="mb-4 ml-2 text-xs text-[#2B2E3A] pt-2  border-t border-[#868CA233]">
                                <div className="text-[#000000] text-sm font-medium">{data?.attachments?.length} Attachments</div>
                                <div className="flex flex-wrap gap-4 mt-1">
                                  {data?.attachments.map((attachment, attachmentIndex) => (
                                    <AttachmentItem key={attachmentIndex} attachment={attachment} message_id={data?.message_id} account_type={draftData?.account_type} email={draftData?.store_email ? extractNameAndEmail(draftData?.store_email)?.email : ""} />
                                  ))}
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                      {/* Summary Section - This is always positioned at the bottom */}
                      {(draftData?.emails?.length > 1) && (
                        <div className=" text-[11px] lg:text-[14px] bg-[#D7FDED] font-medium px-[10px] text-[#868CA2] mt-2 py-2 rounded-md lg:mx-0 mx-1  min-h-[50px]">
                          {/* Heading */}
                          <div className="font-medium  text-[#2B2E3A]">
                            Conversation Summary:
                          </div>

                          {isSummaryLoading ? <div className="w-[120px] h-4 my-3 bg-gray-300 rounded animate-pulse"></div> :
                            (draftData?.summary) ? (
                              <div className="mt-2 whitespace-pre-wrap break-words text-[#868CA2]">
                                {/* Show only the first 300 characters if not expanded */}
                                {isExpanded
                                  ? draftData?.summary
                                  : draftData?.summary.slice(0, 300)}

                                {/* Show "Show More"/"Show Less" button if content is more than 300 characters */}
                                {(draftData?.summary.length) > 300 && (
                                  <button
                                    onClick={toggleExpand}
                                    className="ml-2 focus:outline-none text-[#10b981]"
                                  >
                                    {isExpanded ? "Show Less" : "... Show More"}
                                  </button>
                                )}
                              </div>
                            ) : (
                              <div className="text-center my-3"> No summary available.</div>
                            )}
                        </div>)}
                    </div>
                  )}

                  {!isFetching && showTooltip === 'sent' && draftData && lastEmail?.drafts?.length > 0 && lastEmail?.drafts?.[0]?.is_email_sent && (
                    <div className="flex justify-center items-center w-full mt-3">
                      <button
                        className={`bg-[#96F3C3] text-[#2B2E3A] rounded-md ${isSyncing ? "cursor-not-allowed" : "hover:bg-green-200"
                          } transition w-[170px] h-[34px] font-medium text-sm underline flex justify-center items-center`}
                        onClick={!isSyncing ? handleSyncConversations : null}
                        disabled={isSyncing}
                      >
                        {isSyncing ? (<img src={WhiteLoadingAnimation} alt="Loading..." className="h-12 w-14" />) : ("Sync conversation")}
                      </button>
                    </div>
                  )}

                  {showNewAddress ? (<NewAddressForm thread_id={draftData?.conversation_id} address={lastEmail?.drafts?.[0]?.actions_data?.address_details} orderData={orderData} handleClosShowNewAddress={handleClosShowNewAddress} setAddressSucessModal={setAddressSucessModal} draft_id={param?.id} />) : isExchnageItem ? (
                    <ExchangeItem
                      thread_id={draftData?.conversation_id}
                      exchangeDetails={lastEmail?.drafts?.[0]?.actions_data?.action_details?.order_details || lastEmail?.drafts?.[0]?.actions_data?.order_details || lastEmail?.drafts?.[0]?.actions_data?.details}
                      orderData={orderData}
                      handleClosExchangeItem={handleClosExchangeItem}
                      setExchangeSucessModal={setExchangeSucessModal}
                      draft_id={param?.id}
                      draftData={draftData}
                      fetchAllData={fetchAllData}
                      store_email={draftData?.store_email}
                    />
                  ) : (
                    <div className="flex flex-col pt-2  mt-3 border border-[#868CA24D] rounded-lg lg:mx-0 mx-2">
                      {!isFetching &&
                        <div className="flex justify-between items-center w-full px-[8px] md:px-[10px] pb-2 text-xs font-medium border-b border-[#868CA24D] leading-none text-gray-800">
                          <div className="flex items-center gap-2 ">
                            <div className="text-[#868CA2] text-[11px] lg:text-[12px]">To:</div>
                            <div className="text-[#2B2E3A] p-2 rounded-md bg-[#F3F3F3] text-[11px] lg:text-[12px]">{draftData?.sender_email ? extractNameAndEmail(draftData?.sender_email)?.email : 'Unknown sender'}</div>
                          </div>
                          <div className="flex lg:items-center lg:space-x-6">
                            {!(refundSucess || addressUpdate || orderExchangeUpdate) && <ExampleDropdown handleExampleDraft={handleExampleDraft} />}</div>
                        </div>}
                      {(isFetching || exampleDraft || isTranslatingDraft) ? (
                        <EmailSkeleton show={true} />
                      ) : (
                        <>
                          <div className="flex flex-col pt-2 mt-1.5 bg-white h-[340px] rounded-lg">
                            <textarea
                              onChange={handleDraftChange}
                              value={isTranslating ? translatedContent?.emails[translatedContent?.emails?.length - 1]?.drafts?.[0]?.final_draft
                                || '' : draftEmail || ''}
                              className={`w-full font-dmsan focus:outline-none bg-white text-[11px] lg:text-xs font-medium leading-4 text-[#868CA2]  ${(refundSucess || addressUpdate || orderExchangeUpdate) ? 'h-[300px] min-h-[180px] cursor-default' : 'h-[260px] min-h-[180px]'} overflow-y-auto custom-scrollbar text-[#868CA2] resize-none pl-3 pr-[6%] pb-2`}
                              data-testid="draft-textarea"
                              disabled={(refundSucess || addressUpdate || orderExchangeUpdate)}
                            />
                            {!(refundSucess || addressUpdate || orderExchangeUpdate || isTranslating) && (
                              <div className="flex justify-between items-center mt-6 px-2">
                                {/* Left side - Delete Button */}
                                <div className="sm:flex hidden lg:gap-[8px]">
                                  <button
                                    className={`flex h-[32px] items-center justify-center gap-1.5 pl-1.5 pr-2.5 text-[11px] lg:text-[12px] bg-[#FFFFFF] leading-snug rounded-md text-[#868CA2] cursor-pointer`}
                                    onClick={handleOpenDeleteDraftModal}                                      >
                                    <LuTrash2 className="w-[18px] h-[18px]" />
                                  </button>

                                  <button
                                    className={`flex items-center justify-center mt-[2px] text-[10px] lg:text-xs w-[90px] lg:w-[100px]  h-[28px] font-medium rounded-md  bg-[#F3F3F3] text-[#2B2E3A] cursor-pointer`}
                                    onClick={handleCreateExampleModal}
                                  >

                                    <span className="self-stretch my-auto text-[10px] lg:text-xs">
                                      Save as example
                                    </span>
                                  </button>
                                </div>
                                <div className="sm:hidden relative" ref={dropdownRef}>
                                  <button
                                    className="text-[#727272] hover:text-gray-700 bg-[#FFFFFF]  flex items-center justify-center"
                                    onClick={() => setShowDropDown(!showDropDown)}
                                  >
                                    <HiEllipsisVertical className="w-[22px] h-[22px]" />
                                  </button>

                                  {/* Dropdown for Mobile */}
                                  {showDropDown && (
                                    <div className="absolute z-4 bottom-6  ml-2 w-[120px] text-xs bg-white shadow-lg rounded-md border border-gray-200">
                                      <button
                                        className="block w-full text-left px-2 py-2 text-gray-700 hover:bg-gray-100"
                                        onClick={handleCreateExampleModal}
                                      >
                                        Save as example
                                      </button>
                                      <button
                                        className="block w-full text-left px-2 py-2 text-gray-700 hover:bg-gray-100"
                                        onClick={handleOpenDeleteDraftModal}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  )}
                                </div>

                                {/* Right side - Action Buttons */}
                                <div className="flex sm:gap-[16px] gap-[6px] text-[#2B2E3A] justify-end">
                                  {lastEmail?.drafts?.[0]?.actions_data?.actions && lastEmail?.drafts?.[0]?.actions_data?.actions?.length > 0 && (
                                    lastEmail?.drafts?.[0]?.actions_data.actions?.map((action) => {
                                      const actionDetails = getActionsIconPath(action?.name || action);
                                      return actionDetails && (
                                        <button
                                          key={action?.name || action}
                                          className={`flex items-center gap-2 justify-center text-[10px] lg:text-[11px] min-w-[80px] w-auto px-2 h-[28px] font-medium border border-[#D0D5DD] rounded-md bg-white text-[#2B2E3A]  cursor-pointer`}
                                          onClick={actionDetails?.action}
                                        >
                                          <div className="text-center">{actionDetails?.name}</div>
                                        </button>
                                      );
                                    })
                                  )}
                                  <button
                                    className={`flex items-center gap-2 justify-center text-[10px] lg:text-xs min-w-[80px] w-auto h-[28px] font-medium bg-[#96F3C3] text-[#2B2E3A] rounded-md ${isTranslating
                                      ? !translatedContent?.emails?.[translatedContent?.emails?.length - 1]?.drafts?.[0]?.final_draft
                                        ? ' cursor-not-allowed'
                                        : 'cursor-pointer'
                                      : !draftEmail
                                        ? ' cursor-not-allowed'
                                        : ' cursor-pointer'
                                      }`}
                                    onClick={handleSendDraftModal}
                                    disabled={
                                      isTranslating
                                        ? !translatedContent?.emails?.[translatedContent?.emails?.length - 1]?.drafts?.[0]?.final_draft
                                        : !draftEmail
                                    }
                                  >
                                    <div className="text-center">Send mail</div>
                                  </button>
                                </div>
                              </div>
                            )}
                            {isTranslating &&
                              <div className="flex mt-8 px-2 text-[#2B2E3A] items-end justify-end">
                                <button
                                  className={`flex items-center gap-2 justify-center text-[10px] lg:text-xs min-w-[140px] max-w-[180px] w-auto h-[28px] font-medium rounded-md bg-[#96F3C3] text-[#2B2E3A] ${isTranslating
                                    ? !translatedContent?.emails?.[translatedContent?.emails?.length - 1]?.drafts?.[0]?.final_draft
                                      ? ' cursor-not-allowed'
                                      : 'cursor-pointer'
                                    : !draftEmail
                                      ? ' cursor-not-allowed'
                                      : ' cursor-pointer'
                                    }`}
                                  onClick={() => handleDraftTranslate(handleSwitchLanguage(), true, translatedContent?.emails[translatedContent?.emails?.length - 1]?.drafts?.[0]?.final_draft)}
                                  disabled={
                                    isTranslating
                                      ? !translatedContent?.emails?.[translatedContent?.emails?.length - 1]?.drafts?.[0]?.final_draft
                                      : !draftEmail
                                  }
                                >
                                  <div className="text-center">Save draft in {handleSwitchLanguage()}</div>
                                </button>
                              </div>}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="bg-white w-full lg:min-w-[440px] flex flex-grow mb-2">
                <RefundConfirmation setShowRefundModal={setShowRefundModal} showRefundModal={showRefundModal} orderData={orderData} refundDetails={draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.refund_details} draftData={draftData} updateDraft={updateDraft} draftEmail={draftEmail} setRefundSucess={setRefundSucess} />
              </div>
            )}
          </div>
          <div className={`${showDetailsSidebar ? 'visible' : 'hidden'} lg:flex flex-col ml-1 `}>
            <div className="flex flex-col font-medium  bg-opacity-20 min-h-[700px] lg:min-h-[850px] h-screen px-2">
              <div className="bg-[#FFFFFF] my-2 ">
                <div className="flex flex-col min-h-[600px] lg:min-h-[792px] w-full lg:w-[300px] mb-6 sm:w-[90%] mx-auto lg:mb-1 bg-[#FFFFFF] overflow-y-auto scrollbar mr-[2px] pr-[3px] items-center">
                  {/* Add content here */}
                  {!isFetching && (draftData?.emails?.length > 0 || translatedContent?.emails?.length > 0) &&
                    (<DraftDetailsSidebar thread_id={draftData?.conversation_id} draftData={draftData} isFetchingOrderData={isFetchingOrderData} orderData={orderData} shouldFetchData={shouldFetchData} setShouldFetchData={setShouldFetchData} sender_email={draftData?.sender_email} allOrders={allOrders} isFetchingAllOrders={isFetchingAllOrders} setShowDetailsSidebar={setShowDetailsSidebar} />)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <PopupModal closeModal={handleCloseModal} className="bg-[#F9F9F9]">
            <div className="flex flex-col justify-center items-center w-[300px] sm:h-[200px] sm:w-[360px]">
              <img src={FeaturedIcon} alt="" className="mb-2" />
              <div className="text-[#101828] font-medium text-base sm:text-lg mb-2">
                {deleteContext === 'mail' ? 'Delete mail' : 'Delete draft'}
              </div>
              {deleteContext === 'mail' ? (
                <p className="text-[#667085] font-dmsans text-sm sm:text-[15px] font-normal mb-4 text-center">
                  Are you sure you want to delete this mail? This action cannot be undone.
                </p>
              ) : (
                <p className="text-[#667085] font-dmsans text-sm sm:text-[15px] font-normal mb-4 text-center">
                  Confirm deletion of this draft? This <br /> action is irreversible.
                </p>
              )}
              <div className="flex justify-center space-x-6 w-full">
                <button
                  className="text-sm sm:text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[39px] flex items-center justify-center"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  data-testid='deleteButton'
                  type="button"
                  onClick={deleteContext === 'mail' ? '' : () => updateDraft("", false, selectedDraftReason)}
                  disabled={(updatingDraft)}
                  className="w-[150px] h-[39px] rounded-md bg-[#D92D20] text-sm sm:text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
                >
                  {(updatingDraft) ? (
                    <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
            </div>
          </PopupModal>
        )}

        {/* send modal confirmation*/}
        {(showSentModal || showDiscountModal || showCreateExampleModal) && (
          <PopupModal closeModal={handleCloseSentModal} className="bg-[#F9F9F9]">
            <div className="flex flex-col justify-center items-center w-[300px] sm:h-[200px] sm:w-[360px]">
              <img src={RestoreIcon} alt="" className="mb-3" />
              <div className="text-[#101828] font-medium text-base sm:text-lg mb-2">{showSentModal ? "Send Draft" : showCreateExampleModal ? "Create example" : "Update code"}</div>
              <p className="text-[#667085] font-dmsans text-sm sm:text-[15px] font-normal mb-6 text-center">
                {showSentModal && "Are you sure, you want to send this draft?"}
                {showDiscountModal && "Are you sure you want to update the code?"}
                {showCreateExampleModal && "Do you want to create an example from this draft?"}
              </p>
              <div className="flex justify-center space-x-6 w-full">
                <button
                  className="text-sm sm:text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[40px] sm:h-[44px] flex items-center justify-center"
                  onClick={handleCloseSentModal}
                >
                  Cancel
                </button>
                <button
                  data-testid='sendDraft'
                  type="button"
                  onClick={() =>
                    (showSentModal && handleSendDraft()) ||
                    (showCreateExampleModal && handleCreateExampleDraft()) ||
                    handleUpdateDiscount()
                  } disabled={sendingDraft || isFetchingData}
                  className="w-[150px] h-[40px] sm:h-[44px] rounded-md bg-[#039855] text-sm sm:text-base text-[white] font-medium text-center flex items-center justify-center"
                >

                  {sendingDraft || isFetchingData ? (
                    <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                  ) : (
                    showSentModal ? "Send mail" : showCreateExampleModal ? "Proceed" : "Update code"
                  )}
                </button>
              </div>
            </div>
          </PopupModal>

        )}

        {/* successfully send modal*/}

        {showSentMailModal && (
          <SucessModal text={sentMessage} handleModal={handleCloseSentMailModal} />

        )}

        {addressSucessModal && (
          <SucessModal text={'Address updated successfully'} handleModal={handleCloseAddressModal} />
        )}

        {exchnageSucessModal && (
          <SucessModal text={'Order updated successfully'} handleModal={handleCloseExchangeModal} />
        )}


      </div>
    </div>
  );
};

export default DraftDetailPage;
