import React, { useEffect, useState,useRef } from "react";
import { useNavigate, Link ,useParams} from "react-router-dom";
import GoogleSignInButton from "../components/GoogleSigninButton";
import { toast } from "react-toastify";
import axios from "axios";
import ZenvaLogo from "../assets/SidebarAssets/ZenvaLogo.svg";
import DraftBoardImage from "../assets/DraftBoardImage.svg";
import { getStoredValue } from "../services/logics";
const Signin = () => {

  const navigate = useNavigate();
  const [inviteId, setInviteId] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const isLoggedIn = getStoredValue("isLoggedIn");
  const [inviteApiFailed, setInviteApiFailed] = useState(false); // State to track API failure

  useEffect(() => {
    const checkAuthAndInvite = async () => {
      // Extract token and invite_id from URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = urlParams.get('token');
      const inviteIdParam = urlParams.get('invite_id');
  
      if (tokenFromUrl) {
        // Clear previous session data and store the new token
        sessionStorage.clear();
        sessionStorage.setItem("token", tokenFromUrl);  
        sessionStorage.setItem("email", urlParams.get('email')); 
        sessionStorage.setItem("expiry_in", urlParams.get('expiry_in'));  
        sessionStorage.setItem("is_super_admin", urlParams.get('is_super_admin')); 
        // Always navigate to the document page when token is present in the URL
        navigate("/document-page", { replace: true });
      } else {
      // Check if the user has a valid token
      const token = getStoredValue("token");
      sessionStorage.setItem("token",token);
      
      // If token exists and user is already logged in
      if (token && isLoggedIn === "true") {
        try {
          const isSuperAdmin =  getStoredValue("is_super_admin");
          const store_id = getStoredValue("storeid");

          // Redirect based on the user's role
          if (isSuperAdmin && (isSuperAdmin?.toLowerCase() === "true" && (store_id==="undefined"))) {
            navigate("/admin/dashboard-page", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        } catch (error) {
          console.error("Error during authentication check:", error);
        }
      }

      // If no token from URL, check if invite_id is present and process invitation
    if (inviteIdParam) {
      setInviteId(inviteIdParam);
      const postInviteId = async () => {
        try {
          const details = {
            invite_id: String(inviteIdParam)
          };          
          const response = await axios.post(`${API_URL}/v1/invites/accept`, details);
          toast.success("Invitation accepted!");
          setInviteApiFailed(false);
          navigate('/signin', { replace: true });
        } catch (error) {
          setInviteApiFailed(true); // Set API failure state
          toast.error(`${error.response?.data?.detail || error.response?.data?.error || 'An unexpected error occurred'}`);
        }
      };
      // hasInvitedPosted.current = true;
      postInviteId();
        }
    }
  };
    checkAuthAndInvite();
  }, [navigate, API_URL]);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "isLoggedIn" && event.newValue === "true") {  
        // Check if sessionStorage is already populated
        const token = sessionStorage.getItem("token");
        const localtoken =localStorage.getItem("token");
        const isSuperAdmin = getStoredValue("is_super_admin");
        const store_id = getStoredValue("storeid");
        sessionStorage.setItem("token", localtoken);

        // Redirect based on the user's role
        if (isSuperAdmin && (isSuperAdmin?.toLowerCase() === "true" && (store_id==="undefined"))) {
          navigate("/admin/dashboard-page", { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      }
    };
  
    window.addEventListener("storage", handleStorageChange);
  
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);  // Include navigate in the dependency array
  

  const boxShadow = `
  0 21px 294px 83px rgba(0, 0, 0, 0.00),
  13px 188px 76px 0px rgba(0, 0, 0, 0.01),
  7px 106px 64px 0px rgba(0, 0, 0, 0.05),
  3px 47px 47px 0px rgba(0, 0, 0, 0.09),
  1px 12px 26px 0px rgba(0, 0, 0, 0.10)
`;
  const boxShadowStyle = {
    boxShadow: boxShadow,
  };


  return (
    <section className="h-screen grid grid-cols-1 lg:grid-cols-2 font-dmsans overflow-hidden">
      {/* Left Section */}
      <div className="flex justify-center items-center h-screen bg-[#D7FDED80] lg:bg-white mb-10 lg:mb-0 lg:mx-0 lg:p-8">
        <div className="text-center max-w-lg lg:mx-0">
          {/* Top Cloud Decoration */}
          <img src={ZenvaLogo} alt="Mail Box" className="mb-3 w-[56px] mx-auto" />

          {/* Welcome and Login Form */}
          <h1 className="lg:text-[26px] font-medium text-[#000000] mb-4 sm:text-[16px] text-sm">Welcome to Zenva<span className="text-[14px]"><sup>AI</sup></span></h1>
          <p className="text-[#090914] font-medium lg:text-[18px] sm:text-[16px] text-sm sm:mb-3 lg:mb-7">
            Sign In to Your Account          </p>
            <GoogleSignInButton inviteId={inviteId} isDisabled={!!inviteId && inviteApiFailed} />
            {/* Privacy Policy Statement */}
          <p className="text-sm text-gray-700 mt-6">
            By signing in, you agree to our <a href="https://www.servicepoints.eu/en/legal/privacy-policy" className="text-blue-500 underline">Privacy Policy</a>.
          </p>
        </div>
      </div>

      {/* Right Section */}
      <div className="hidden lg:flex flex-col justify-between bg-[#D7FDED80] lg:pl-6 xl:pl-14  pt-10 pr-0">
        {/* Top Descriptive Text */}
        <div className="lg:text-[11px] xl:text-[13px] font-dmsans lg:leading-[20.64px] xl:tracking-[0.32px] text-[#2B2E3A] mb-1 ml-3 lg:w-[600px] xl:w-[678px]">
          <p className="font-normal">Welcome to Zenva<span className="text-[13px]"><sup>AI</sup></span> - Your One-Stop Shop for Efficient Order Processing</p>
          <p>With Zenva<span className="text-[13px]"><sup>AI</sup></span>, streamline your entire order management process through a single, user-<br />friendly platform. Easily manage <span className="font-bold text-black">purchase orders, shipment tracking, delivery</span></p>
          <p><span className="font-bold text-black">updates,refunds, cancellations, product availability,</span> and <span className="font-bold text-black">order tracking</span> all in one place.</p>
          <p>Spend less time navigating the complexities of order administration and more time </p>
          <p>focusing on what truly matters—<span className="font-bold text-black">growing your business.</span> Simplify your workflow </p>
          <p>with Zenva<span className="text-[13px]"><sup>AI</sup></span> and unlock the full potential of your success.</p>

        </div>

        {/* Bottom Image */}
        <div className="flex justify-center items-end mt-10">
          <img src={DraftBoardImage} alt="Draft Board" className="w-full xl:mt-8" />
        </div>
      </div>
    </section>

  );
};

export default Signin;
