import React from 'react';

const ExampleDetailSkeleton = ({show}) => {
  return (
    <div data-testid="example-detail-skeleton" className="rounded-lg lg:p-6 w-full h-full mx-auto space-y-12 mb-10 lg:mb-0 ">
      {/* Title Skeleton */}
      <div className="space-y-2 flex lg:flex-row flex-col gap-4 ">
        <div className="h-4 bg-gray-200 rounded w-[146px] mt-3"></div> {/* Label Skeleton */}
        <div className="h-10 bg-gray-200 rounded w-[344px]"></div> {/* Input Skeleton */}
      </div>

      {/* Action Name Skeleton */}
      <div className="space-y-2 flex lg:flex-row flex-col gap-4 ">
        <div className=" h-4 bg-gray-200 rounded w-[146px] mt-3"></div> {/* Label Skeleton */}
        <div className=" h-10 bg-gray-200 rounded w-[344px]"></div> {/* Input Skeleton */}
      </div>

      {/* Message Skeleton */}
      <div className="space-y-2 flex lg:flex-row flex-col gap-4 lg:items-center justify-center ">
        <div className="h-4 bg-gray-200 rounded w-[146px] mt-3 mr-4"></div> {/* Label Skeleton */}
        <div className="w-full h-48 bg-gray-200 rounded"></div> {/* Textarea Skeleton */}
      </div>

      {/* Button Skeleton */}
      <div className="flex justify-end space-x-4 lg:pt-14">
        <div className="h-10 bg-gray-200 rounded w-[146px]"></div> {/* Button Skeleton */}
       {show && <div className="w-[146px] h-10 bg-gray-200 rounded"></div> }
      </div>
    </div>
  );
};

export default ExampleDetailSkeleton;
