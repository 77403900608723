import React from 'react';

const DraftShimmer = ({show=true}) => {
  return (
    <div className="py-2 space-y-4">
      {/* Date Group Shimmer */}
    {show &&  <div className="w-32 h-4 bg-gray-300 rounded mb-3 lg:ml-4 mt-6 animate-pulse"></div>}

      {[...Array(4)].map((_, index) => (
        <div key={index} className="flex items-center gap-2 p-3 border-b border-gray-200 animate-pulse">
          {/* Shimmer Circle for Avatar */}
          <div className="w-8 h-8 bg-gray-300 rounded-full shrink-0"></div>

          {/* Content */}
          <div className="flex-1 flex flex-col md:flex-row items-start md:items-center">
            {/* Sender Name Placeholder */}
            <div className="w-[120px] h-4 bg-gray-300 rounded mb-2 md:mb-0"></div>

            {/* Subject and Body Placeholder */}
            <div className="flex-1 flex flex-col md:flex-row items-start md:items-center md:ml-20 space-y-2 md:space-y-0">
              <div className="lg:w-[500px] w-[230px]  h-4 bg-gray-300 rounded"></div>
            </div>
          </div>

          {/* Date Placeholder */}
          <div className={`sm:flex hidden sm:w-[100px] lg:w-[120px] h-4 bg-gray-300 rounded ${show?"mr-4":"mr-24"}`}></div>
        </div>
      ))}

      {/* Another Date Group Shimmer */}
      {show && <div className="w-32 h-4 bg-gray-300 rounded mb-3 lg:ml-4 mt-6 animate-pulse"></div>}

      {[...Array(3)].map((_, index) => (
        <div key={index} className="flex items-center gap-2 p-3 border-b border-gray-200 animate-pulse">
          {/* Shimmer Circle for Avatar */}
          <div className="w-8 h-8 bg-gray-300 rounded-full shrink-0"></div>

          {/* Content */}
          <div className="flex-1 flex flex-col md:flex-row items-start md:items-center">
            {/* Sender Name Placeholder */}
            <div className="w-[120px] h-4 bg-gray-300 rounded mb-2 md:mb-0"></div>

            {/* Subject and Body Placeholder */}
            <div className="flex-1 flex flex-col md:flex-row items-start md:items-center md:ml-20 space-y-2 md:space-y-0">
              <div className="lg:w-[500px] w-[230px] h-4 bg-gray-300 rounded"></div>
            </div>
          </div>

          {/* Date Placeholder */}
          <div className={`sm:flex hidden sm:w-[100px] lg:w-[120px] h-4 bg-gray-300 rounded ${show?"mr-4":"mr-24"}`}></div>
        </div>
      ))}
    </div>
  );
};

export default DraftShimmer;
