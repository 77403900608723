import React, { useEffect, useState, useCallback, useRef } from "react";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { HiEllipsisVertical } from "react-icons/hi2";
import { BREAKPOINTS } from "../services/emailUtils"
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import DraftsPagination from "../components/DraftsPagination.jsx";
import LoadingAnimation from "../assets/LoadingAnimation.svg";
import { useSelector } from 'react-redux';
import '../components/ExamplePageComponents/CustomTheme.css';
import { marked } from 'marked';
import { formatCustomDateTime,getStoredValue } from "../services/logics";
import DocumentListShimmer from "../components/DocumentSkeleton.jsx";
import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import PopupModal from "../components/PopupModal";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import axiosInstance from "../services/axiosInstance.js";

const ExamplesPage = () => {
    const navigate = useNavigate();
    const [examplesData, setExamplesData] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const API_URL = process.env.REACT_APP_API_URL;
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(1);
    const [totalExamples, setTotalExamples] = useState(0);
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const role = getStoredValue("role");
    const dropdownRef = useRef(null);

    // Fetch examples data with the current search term and page number
    const fetchExamplesData = async (searchTerm, page = 1) => {
        try {
            const response = await axiosInstance.get(
                `${API_URL}/v1/store/draft/examples`, {
                params: {
                    page,
                    page_size: 10,
                    search: searchTerm,
                },

            });
            const data = response?.data;
            setExamplesData(data?.items);
            setTotalExamples(data?.total);
        } catch (error) {
            toast.error(`${error.response?.data.detail}`);
            // if (error.response && error.response.status === 401) {
            //     toast.error("Your session has expired");
            //     navigate("/signin");
            // }
        }
        setIsFetching(false);
    };

    // Debounced function to handle search term changes
    const debouncedFetchData = useCallback(
        debounce((term) => {
            setPage(1);
            fetchExamplesData(term, 1); // Reset to first page on new search
        }, 500),
        []
    );

    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedFetchData(value);
    };

    useEffect(() => {
        fetchExamplesData(searchTerm, page);
    }, [page]);

    const handlePageChange = (newPage) => {
        if (newPage !== page) {
            setIsFetching(true);
        }
        setPage(newPage);
    };

    const stripMarkdown = (markdown) => {
        const html = marked(markdown);
        const tmp = document.createElement("div");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    };
    const getBgColor = (dataType) => {
        if (dataType.includes("Enquiry")) {
            return 'bg-[#1A13E326] text-[#1A13E3]';
        } else if (dataType.includes("Refund")) {
            return 'bg-[#23BE5426] text-[#23BE54]';
        } else if (dataType.includes("Order")) {
            return 'bg-[#FA343426] text-[#FA3434]';
        } else if (dataType.includes("Product")) {
            return 'bg-yellow-100 text-yellow-500';
        } else {
            return 'bg-gray-100 text-gray-500';
        }
    };
    const handleDelete = () => {
        setShowDeleteModal(false);
    };

    const handleDeleteExample = async () => {
        try {
            setIsDeleting(true)
            await axiosInstance.delete(`${API_URL}/v1/store/draft/examples/${selectedId}`);
            // Fetch data for the current page
            const response = await axiosInstance.get(
                `${API_URL}/v1/store/draft/examples`, {
                params: {
                    page,
                    page_size: 10,
                    search: searchTerm,
                },
            });
            const data = response.data;

            // If the current page has no items and it's not the first page, go to the previous page
            if (data.items.length === 0 && page > 1) {
                setPage(page - 1);
                fetchExamplesData(searchTerm, page - 1);
            } else {
                setExamplesData(data.items);
                setTotalExamples(data.total);
            }
            toast.success("Example Deleted successfully");
            setShowDeleteModal(false);

        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
        setIsDeleting(false)
    };
  // Close the dropdown when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (id) => {
    setActiveDropdown((prev) => (prev === id ? null : id));
  };

    return (
        <div className={`py-2  overflow-y-auto  flex flex-col  font-dmsans ${isSidebarOpen ? 'lg:ml-[220px] xl:ml-[244px] lg:px-[26px] px-[8px] sm:px-4 md:px-6' : 'lg:ml-[70px] lg:px-[40px] sm:px-4 xl:px-[36px] px-[8px] md:px-6'} transition-all ease-in-out duration-500`}>
                {location.pathname.includes("example") && (
                    <div className="hidden lg:block font-dmsans font-medium text-[#000000] xl:text-[20px]">
                        Examples
                    </div>
                )}
            <div className="flex items-center justify-end  mb-4 ">
                <div className="flex flex-col-reverse lg:flex-row lg:justify-between items-start lg:items-center lg:ml-1  lg:w-auto lg:space-x-4 w-full">

                    <div className="relative w-full lg:w-[270px] h-[36px] lg:h-[40px] border border-[#868CA2] bg-white text-[#000000] rounded-md lg:mt-0 mt-[8px]">
                        <IoSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 lg:h-4 lg:w-4 text-[#64748B] h-3 w-3" />
                        <input
                            type="text"
                            placeholder="Search"
                            className="focus:outline-none w-[84%] h-full ml-6 lg:ml-8 text-[#64748B] text-[13px] lg:text-[15px]"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className="flex lg:h-0 h-[36px]  lg:w-auto w-full justify-between items-center lg:mb-0">
                        {location.pathname.includes("example") && (
                            <div className="lg:hidden visible font-dmsans text-base font-medium text-[#000000] pl-[5px]">
                                Examples
                            </div>
                        )}
                            <div className="flex items-center h-[34px] justify-center rounded-md font-medium leading-none text-[#2B2E3A] w-[126px] lg:w-[147px] lg:h-[40px] bg-[#96F3C3] cursor-pointer" onClick={() => navigate(`/create-example`)}>
                                <span className="self-stretch my-auto text-xs lg:text-[16px]">Create example</span>
                            </div>
                    </div>
                </div>
            </div>
            <div className="overflow-y-auto  custom-scrollbar bg-white h-auto justify-between border-gray-200 lg:mb-0 mb-12 lg:w-auto lg:mt-4">
                {isFetching ? (
                    <DocumentListShimmer items={9} show={false} />
                ) : (
                    <>
                        {examplesData.length === 0 ? (
                            <p className="text-center mt-20 text-red-400">No examples found</p>
                        ) : (
                            <>
                                <table className="min-w-full  bg-white">
                                    <thead className="bg-[#E1E1E64D] hidden md:table-header-group h-[40px] text-[#000000] md:text-[13px] lg:text-sm font-normal font-dmsans">
                                        <tr>
                                            <th className="py-2 xl:w-[574px] lg:w-[500px] text-left md:pl-2 xl:pl-3 ">Title</th>
                                            <th className="py-2 xl:w-[242px] lg:w-[200px] text-center ">Status</th>
                                            <th className="py-2 xl:w-[212px] lg:w-[180px] text-center">Date | Time</th>
                                            <th className="text-center w-[60px]"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {examplesData.map((data) => (
                                            <tr
                                                key={data.id}
                                                className="h-[10px]  hover:border-[#39F6A3] hover:bg-[#39F6A30D] cursor-pointer "
                                                onClick={() => navigate(`/example/${data.id}`)}
                                            >
                                                <td className="px-[8px] xl:px-4 py-3 min-w-[100px] max-w-[130px] md:min-w-[180px] md:max-w-[400px] xl:max-w-[560px] truncate ">
                                                    <span className="md:text-xs  text-[10px] font-medium text-[#000000] pr-1">
                                                        {data?.name} -
                                                    </span>
                                                    <span className="md:text-xs  text-[10px] md:min-w-[180px] font-normal truncate transition duration-300 text-[#2B2E3AA1]">
                                                        {stripMarkdown(data?.template)}
                                                    </span>
                                                </td>
                                                <td className="xl:w-[242px] sm:w-[140px] sm:min-w-[120px] md:w-[180px] md:min-w-[140px] xl:min-w-[180px] w-[100px] min-w-[100px] text-center ">
                                                    <span className={`px-2 py-1 text-[9px] md:text-xs rounded-full ${getBgColor(data?.type)}`}>
                                                        {data?.type}
                                                    </span>
                                                </td>
                                                <td className=" xl:w-[212px] md:w-[180px] sm:w-[140px] sm:min-w-[120px] w-[78px] min-w-[68px] md:min-w-[160px]  xl:min-w-[180px] text-center text-[#2B2E3A] font-normal text-[9px] md:text-xs">
                                                    {formatCustomDateTime(data.updated_at, window.matchMedia(BREAKPOINTS.mobile).matches ? true : false)}
                                                </td>
                                                <td>
                                                   <button data-testid="delete" className="mx-auto hidden sm:flex items-center justify-start sm:w-[18px] sm:h-[18px] lg:w-[28px] lg:h-[28px]" onClick={(event) => {
                                                        event.stopPropagation(); // Prevent row click
                                                        setShowDeleteModal(true);
                                                        setSelectedId(data?.id)
                                                    }}>
                                                        <DeleteIcon className="  lg:w-[20px] lg:h-[20px] sm:w-[18px] sm:h-[18px]  " />
                                                    </button>
                                                        <>
                                                            <button
                                                                className="text-[#727272] sm:hidden relative hover:text-gray-700 bg-[#FFFFFF] flex items-center justify-end ml-auto"
                                                                onClick={(event) => {event.stopPropagation();handleDropdownToggle(data?.id)}} // Toggle dropdown for this item
                                                            >
                                                                <HiEllipsisVertical className="w-[18px] h-[22px] md:w-[18px] md:h-[24px]" />
                                                            </button>

                                                            {/* Dropdown for Mobile */}
                                                            {activeDropdown === data?.id && (
                                                                <div
                                                                    className="absolute z-10 right-6 mt-2 w-[80px] text-xs bg-white shadow-lg rounded-md border border-gray-200"
                                                                    ref={dropdownRef}
                                                                >
                                                                    <button
                                                                        className="block w-full text-left px-2 py-2 text-gray-700 hover:bg-gray-100"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation(); // Prevent row click
                                                                            setShowDeleteModal(true);
                                                                            setSelectedId(data?.id)
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </>
                )}
            </div>
            {examplesData.length > 0 && <DraftsPagination
                currentPage={page}
                onPageChange={handlePageChange}
                totalDrafts={totalExamples}
            />}

            {/* Show delete Modal */}
            {showDeleteModal && (
                <PopupModal closeModal={handleDelete} className="bg-[#F9F9F9]">
                    <div className="flex flex-col justify-center items-center  w-[300px] sm:h-[200px] sm:w-[360px]">
                        <img src={FeaturedIcon} alt="" className="mb-3" />
                        <div className="text-[#101828] font-medium text-lg mb-2">Delete example</div>
                        <p className="text-[#667085] font-dmsans text-sm sm:text-[15px] font-normal mb-6 text-center">
                            Are you sure, you want to delete it?
                        </p>
                        <div className="flex justify-center space-x-6 w-full">
                            <button
                                className="text-sm sm:text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[36px] sm:h-[39px] flex items-center justify-center"
                                onClick={handleDelete}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                onClick={handleDeleteExample}
                                disabled={isDeleting}
                                className="w-[150px] h-[36px] sm:h-[39px] rounded-md bg-[#D92D20] text-sm sm:text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
                            >
                                {isDeleting ? (
                                    <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                                ) : (
                                    "Delete"
                                )}
                            </button>
                        </div>
                    </div>
                </PopupModal>
            )}
        </div>
    );
};

export default ExamplesPage;
