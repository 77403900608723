import React, { useState, useEffect, useRef } from "react";
import { FiPlus } from "react-icons/fi";
import PopupModal from "../components/PopupModal";
import { ShopifyAccountDetails } from "../components/IntegrationPageComponents/ShopifyAccountDetails.jsx";
import ShopIcon from "../assets/IntegrationPageAssets/ShopIcon.svg";
import DeleteIcon from "../assets/documentPageAssets/DeleteIcon.jsx";
import RestoreIcon from "../assets/IntegrationPageAssets/RestoreIcon.svg";
import IMAPIcon from "../assets/IntegrationPageAssets/IMAPIcon.svg";
import { toast } from "react-toastify";
import { FaShopify } from "react-icons/fa";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import { useSelector } from 'react-redux';
import { formatDate, formatCustomISO, getStoredValue } from "../services/logics.js"
import { isAuthenticated } from "../services/login.js";
import FeaturedIcon from "../assets/FeaturedIcon.svg";
import axiosInstance from "../services/axiosInstance.js";
import { useDispatch } from 'react-redux';
import { setIntegrationTrue, setIntegrationFalse } from '../redux/notificationSlice.js';
import { AiOutlineClose } from "react-icons/ai";
import { TbAlertTriangle } from "react-icons/tb";
import OAuthFlowButton from "../components/IntegrationPageComponents/OAuthFlowButton.jsx";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "../components/DraftDetailPageComponents/CustomCalendar.css"
import Gmail from "../assets/IntegrationPageAssets/Gmail.svg"
import Outlook from "../assets/IntegrationPageAssets/Outlook.svg"
import { BREAKPOINTS } from "../services/emailUtils"
import { CustomIMAPDetails } from "../components/IntegrationPageComponents/CustomIMAPDetails.jsx";
import { useVariableValue } from "@devcycle/react-client-sdk";


const IntegrationPage = ({ setupMode, refreshCompletionStatus }) => {
  const IMAPSMTPmaintenanceMode = useVariableValue("imap-smtp-maintenance-mode", false);
  const urlParams = new URLSearchParams(window.location.search);
  const isWelcomePage = location.pathname.includes('/welcome-page');
  const isShopifyAccountPage = location.pathname.includes('welcome-page/shopifyaccount');
  const isEmailAccountPage = location.pathname.includes('/welcome-page/emailaccount');
  const code = urlParams.get("code");
  const initialTab = code ? "email" : setupMode === 'shopifyaccount' ? 'shopify' : setupMode === 'emailaccount' ? 'email' : "shopify";
  const [activeTab, setActiveTab] = useState(initialTab);
  const [showShopifyModal, setShowShopifyModal] = useState(false);
  const [showDeleteShopifyModal, setShowDeleteShopifyModal] = useState(false);
  const [showRestoreShopifyModal, setShowRestoreShopifyModal] = useState(false);
  const [stores, setStores] = useState([]);
  const [prevStoresConnected, setPrevStoresConnected] = useState([]);
  const [storeIdToDelete, setStoreIdToDelete] = useState(null);
  const [isLoadingStores, setIsLoadingStores] = useState(true);
  const [isLoadingPrevStores, setIsLoadingPrevStores] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [showdeleteModal, setShowdeleteModal] = useState(false)
  const [isNotification, setNotification] = useState(false);
  const [missingScopes, setMissingScopes] = useState([]);
  const [showIntegrationModal, setShowIntegrationModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const role = getStoredValue("role");
  const [showCalendar, setShowCalendar] = useState(false);
  const [tooltipState, setTooltipState] = useState({ activeId: null, showTooltip: false });
  const isMobileScreen = window.matchMedia(BREAKPOINTS.mobile).matches
  const [isOAuthFlow, setIsOAuthFlow] = useState(false);
  const emailAccountStatus = getStoredValue("emailaccount") === "true";
  const shopifyAccountStatus = getStoredValue("shopifyaccount") === "true";
  const [showIMAPForm, setShowIMAPForm] = useState(false);
  const [configSMTPData, setConfigSMTPData] = useState({
    email: "",
    password: "",
    imap_host: "",
    imap_port: "",
    smtp_host: "",
    smtp_port: "",
    use_ssl: false,
    use_tsl:false,
  });

  const handleTooltipToggle = (id) => {
    setTooltipState((prevState) => ({
      ...prevState,
      activeId: id,
      showTooltip: !prevState.showTooltip,
    }));

    setTimeout(() => {
      setTooltipState((prevState) => ({
        activeId: null,
        showTooltip: false,
      }));
    }, 3000);
  };

  const dispatch = useDispatch();
  const handleShopifyModal = () => {
    setShowShopifyModal(true);
  };

  const handleCloseShopifyModal = () => {
    setShowShopifyModal(false);
    setShowIMAPForm(false)
  };

  const handleShopifyDeleteModal = async (storeId, account_type = null) => {
    if (account_type === "imap_smtp") {
      await fetchSMTPAccount(storeId)
     }
    setStoreIdToDelete(storeId);
    setShowDeleteShopifyModal(true);
  };

  const handleCloseShopifyDeleteModal = () => {
    setShowDeleteShopifyModal(false);
    setStoreIdToDelete(null);
  };

  const handleShopifyRestoreModal = async (storeId, account_type = null) => {
    if (account_type === "imap_smtp") {
      await fetchSMTPAccount(storeId)
    }
    setStoreIdToDelete(storeId);
    setSelectedAccountType(account_type);
    setShowRestoreShopifyModal(true);
  };

  const handleCloseShopifyRestoreModal = () => {
    setShowRestoreShopifyModal(false);
    setStoreIdToDelete(null);
  };


  // Handle OAuth Callback when redirected from Google with a code
  const handleOAuthCallback = async (code) => {
    if (code) {
      setAuthLoading(true);
      // codeProcessed.current = true;
      const redirectPath = isEmailAccountPage ? "/welcome-page/emailaccount" : "/integration-page";
      try {
        const savedAccountType = getStoredValue("accountType");
        let callbackUrl = `${API_URL}/v1/store/email/callback?code=${code}&account_type=${savedAccountType}`;
        if (isWelcomePage) {
          const redirectUri = `${window.location.origin}/welcome-page/emailaccount`;
          callbackUrl += `&redirect_uri=${redirectUri}`;
        }
        // Send authorization code to the backend for token exchange
        const response = await axiosInstance.get(callbackUrl);
        const data = response.data;
        setStoreIdToDelete(data?.id)
        setSelectedAccountType(savedAccountType)
        // Remove the "code" from the URL after processing
        window.history.replaceState({}, document.title, redirectPath);
        setAuthLoading(false);
        handleOAuthSuccess();
        setIsOAuthFlow(false); // End OAuth flow
      } catch (error) {
        toast.error(error.response?.data?.detail || "Error during OAuth integration.");
        // Remove the "code" from the URL after processing
        window.history.replaceState({}, document.title, redirectPath);
        setAuthLoading(false);
      }
    }
  };

  // Detect and handle OAuth code in URL
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (!code && !setupMode && !isWelcomePage) {
      // Check the stored values for `emailaccount` and `shopifyaccount`
      if (emailAccountStatus && !shopifyAccountStatus) {
        setActiveTab("shopify");
      } else if (shopifyAccountStatus && !emailAccountStatus) {
        setActiveTab("email");
      } else if (!emailAccountStatus && !shopifyAccountStatus) {
        setActiveTab("shopify");
      }
    }
    if (code) {
      setIsOAuthFlow(true);
      handleOAuthCallback(code);
    } else {
      setIsOAuthFlow(false);
    }
  }, []);

  const getFetchAPIURL = (isDisconnected = false) => {
    if (activeTab === "shopify") {
      return isDisconnected
        ? `${API_URL}/v1/store/shopify/accounts?disconnected=True`
        : `${API_URL}/v1/store/shopify/accounts`;
    } else if (activeTab === "email") {
      // Gmail URLs for connected and disconnected accounts using the `is_deleted` parameter
      return isDisconnected
        ? `${API_URL}/v1/store/email/accounts?is_deleted=True`
        : `${API_URL}/v1/store/email/accounts?is_deleted=False`;
    }
  };

  const fetchStores = async () => {
    setIsLoadingStores(true);
    try {
      const response = await axiosInstance.get(getFetchAPIURL());
      const data = response.data;
      setStores(data);
      await isAuthenticated();
      if (isShopifyAccountPage || isEmailAccountPage) {
        refreshCompletionStatus();
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || `Error fetching ${activeTab === 'shopify' ? 'stores' : 'accounts'}`);
    } finally {
      setIsLoadingStores(false);
    }
  };

  const fetchPrevConnectedStores = async () => {
    setIsLoadingPrevStores(true);
    try {
      const response = await axiosInstance.get(getFetchAPIURL(true),);
      const data = response.data;
      setPrevStoresConnected(data);
    } catch (error) {
      toast.error(error.response?.data?.detail || `Error fetching ${activeTab === 'shopify' ? 'stores' : 'accounts'}`);
    } finally {
      setIsLoadingPrevStores(false);
    }
  };

  const handleUpdateAccount = async (storeId, isDeleted, startDate = null) => {
    setIsLoading(true)
    try {
      if (activeTab === 'shopify') {
        const response = await axiosInstance.patch(
          `${API_URL}/v1/store/shopify/accounts/${storeId}?is_deleted=${isDeleted}`

        );
      } else if (activeTab === 'email') {
        if (selectedAccountType === 'imap_smtp') {
          const response = await axiosInstance.patch(
            `${API_URL}/v1/store/imap-smtp/accounts/${storeId}`,
            {
              ...configSMTPData,
              ...(isDeleted !== null && { is_deleted: isDeleted }),
              ...(startDate && { start_date: startDate })
            }
          );
        } else {
          const response = await axiosInstance.patch(
            `${API_URL}/v1/store/email/accounts/${storeId}?&account_type=${selectedAccountType}`,
            {
              ...(isDeleted !== null && { is_deleted: isDeleted }),
              ...(startDate && { start_date: startDate })
            }
          );
        }
      }

      fetchStores();
      fetchPrevConnectedStores();
      setShowDeleteShopifyModal(false);
      setSelectedAccountType(null)
      setShowRestoreShopifyModal(false);
      setConfigSMTPData({
        email: "",
        password: "",
        imap_host: "",
        imap_port: "",
        smtp_host: "",
        smtp_port: "",
        use_ssl: false,
        use_tsl:false
      });
    } catch (error) {
      toast.error(error.response?.data?.detail || `Error updating ${activeTab === 'shopify' ? 'store' : 'account'}`);
    } finally {
      setShowCalendar(false)
    }
    setIsLoading(false)
  };


  // Clear data and fetch new data on tab switch
  useEffect(() => {
    if (activeTab === "shopify" || activeTab === "email") {
      // Clear the existing data when the tab is switched
      setStores([]);
      setPrevStoresConnected([]);
      setIsLoadingStores(true);
      setIsLoadingPrevStores(true);
      // Fetch new data based on the active tab
      fetchStores();
      fetchPrevConnectedStores();
    }
  }, [activeTab]);

  const handleDeleteAccount = async (storeId) => {
    setIsDeleting(true)
    try {
      if (activeTab === 'shopify') {
        const response = await axiosInstance.delete(
          `${API_URL}/v1/store/shopify/accounts/${storeId}`

        );
      } else if (activeTab === 'email') {
        if (selectedAccountType === 'imap_smtp') {
          const response = await axiosInstance.delete(
            `${API_URL}/v1/store/imap-smtp/accounts/${storeId}`,
            {
              ...configSMTPData,
            }
          );
        } else {
          const response = await axiosInstance.delete(
            `${API_URL}/v1/store/email/accounts/${storeId}?account_type=${selectedAccountType}`
          );
        }
      }
      toast.success("Deleted Successfully");
      fetchStores();
      fetchPrevConnectedStores();
      setShowDeleteShopifyModal(false);
      setShowRestoreShopifyModal(false);
      setShowdeleteModal(false);
      setConfigSMTPData({
        email: "",
        password: "",
        imap_host: "",
        imap_port: "",
        smtp_host: "",
        smtp_port: "",
        use_ssl: false,
        use_tsl:false
      });
    } catch (error) {
      toast.error(error.response?.data?.detail || `Error deleting ${activeTab === 'shopify' ? 'store' : 'account'}`);
    }
    setIsDeleting(false)
  };

  const fetchSMTPAccount = async (id) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/v1/store/imap-smtp/accounts/${id}`);
      const data = response.data
      const filteredData = {
        email: data?.email,
        password: data?.password,
        imap_host: data?.imap_host,
        imap_port: data?.imap_port,
        smtp_host: data?.smtp_host,
        smtp_port: data?.smtp_port,
        use_ssl: data?.use_ssl,
        use_tsl:data?.use_tsl
      };
      setConfigSMTPData(filteredData);
    } catch (error) {
      console.error("Error fetching configuration:", error);
    }
  };

  const handleDeleteAccountModal = async (storeId, account_type = null) => {
    if (account_type === "imap_smtp") {
     await fetchSMTPAccount(storeId)
    }
    setStoreIdToDelete(storeId);
    setShowdeleteModal(true);
  };

  const handleCloseDeleteAccountModal = () => {
    setShowdeleteModal(false);
    setStoreIdToDelete(null);
  };
  useEffect(() => {
    handleToggleNotification();
    if (activeTab === "shopify" && stores?.length > 0 && !isLoadingStores) {
      fetchAccessScopes();
    }
  }, [stores, prevStoresConnected]);

  const handleToggleNotification = () => {
    // Check if both `stores` and `prevStoresConnected` are empty
    if (stores?.length === 0 && prevStoresConnected?.length === 0) {
      dispatch(setIntegrationTrue());  // Set integration to true
    } else {
      dispatch(setIntegrationFalse());  // Set integration to false
    }
  };

  const fetchAccessScopes = async () => {
    setIsLoading(true)
    try {
      if (activeTab === 'shopify' && stores?.length > 0) {
        const response = await axiosInstance.get(
          `${API_URL}/v1/store/shopify/access_scopes`

        );
        if (response?.data?.status === "failure") {
          setNotification(true);
          setMissingScopes(response.data.missing_scopes);
        }
      }

    } catch (error) {
      console.log(error.response?.data?.detail);
    }
    setIsLoading(false)
  };
  const handleClick = () => {
    setNotification(false);
  };

  const handleIntegrationModal = () => {
    setShowIntegrationModal(true);
  };

  const handleCloseIntegrationModal = () => {
    setShowIntegrationModal(false);
  };
  const handleOAuthSuccess = () => {
    setShowCalendar(true)
    setShowIntegrationModal(false);
    setShowIMAPForm(false);
    setSelectedDate(new Date());
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleApply = () => {
    if (selectedDate) {
      handleUpdateAccount(storeIdToDelete, false, formatCustomISO(selectedDate))
    }
  }
  return (
    <div className="bg-[#FFFFFF]">
      <div className={`${(!isWelcomePage) ? `flex flex-col justify-center ${isSidebarOpen ? 'xl:ml-[238px] lg:ml-[220px]' : 'lg:ml-[80px]'} transition-all duration-300 font-dmsans py-2` : ''}`}>
        {location.pathname.includes("integration") && <div className=" font-dmsans font-medium text-[#000000] xl:text-[20px] pl-4 sm:pl-7">Integration</div>}
        {(!isWelcomePage) && (<div className="flex space-x-14 lg:text-[18px] sm:text-[16px] pl-[30px] lg:pl-9 sm:mt-2">
          <button
            className={`py-2 cursor-pointer font-medium relative ${activeTab === 'shopify' ? 'text-[#191D23]' : 'text-[#868CA2]'}`}
            onClick={() => setActiveTab('shopify')}
          >
            Shopify
            {activeTab === "shopify" && (
              <div className="absolute left-1/2 transform -translate-x-1/2 h-1 mt-1 bg-[#39F6A3]" style={{ width: "80px" }} />
            )}
          </button>
          <button
            className={`py-2 cursor-pointer font-medium relative ${activeTab === 'email' ? 'text-[#191D23]' : 'text-[#868CA2]'}`}
            onClick={() => setActiveTab('email')}
          >
            Email
            {activeTab === "email" && (
              <div className="absolute left-1/2 transform -translate-x-1/2 h-1 mt-1 bg-[#39F6A3]" style={{ width: "80px" }} />
            )}
          </button>
        </div>)}
        {(stores?.length > 0 && isNotification && activeTab === "shopify" && (!isWelcomePage)) && (
          <div className={`flex bg-[#fcd34d] text-[#1f2937] text-[12px] w-full  min-h-8  px-4 lg:px-8 mb-2 font-dmsans items-center`}>
            <div className="flex items-center justify-center flex-grow">
              {/* Triangle Icon */}
              <TbAlertTriangle className="shrink-0 text-opacity-50 w-[12px] h-[12px] md:mr-1" />

              {/* Notification Message */}
              <span className="text-center">
                Please grant the app the missing admin scopes in your shopify store:
                {/* Map through missing scopes */}
                {missingScopes?.length > 0 && (
                  <span className="ml-1 font-semibold">
                    {missingScopes.join(", ")}
                  </span>
                )}
                .
                {/* Embedded link to documentation */}
                <a
                  href={process.env.SHOPIFY_ACCESS_SCOPE_DOCUMENT}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-1 text-blue-500 underline"
                >
                  Know more
                </a>
              </span>
            </div>

            {/* Close Button */}
            <button className="text-[#2B2E3A] justify-self-end" onClick={handleClick}>
              <AiOutlineClose size={12} />
            </button>
          </div>
        )}

        <div className="w-full">
          {((isLoadingStores || isLoadingPrevStores) && (!isWelcomePage)) ? (
            <div className="flex justify-center mt-10 lg:min-h-screen">
              <div className="space-y-24 sm:w-[80%] lg:w-[68%]  w-[90%]">
                {/* Connected Stores Shimmer */}
                <div className="space-y-4">
                  <div className="h-4 bg-gray-300 rounded lg:w-[20%] w-[40%] animate-pulse"></div>
                  <div className="h-[100px] bg-gray-200 rounded-md flex items-center animate-pulse">
                    <div className="w-[50px] h-[50px] bg-gray-300 rounded-full mx-4"></div>
                    <div className="flex-1 space-y-2 py-1">
                      <div className="h-4 bg-gray-300 rounded w-[80%]"></div>
                      <div className="h-4 bg-gray-300 rounded w-[80%]"></div>
                    </div>
                    <div className="w-[100px] h-[40px] bg-gray-300 rounded mr-4"></div>
                  </div>
                </div>

                {/* Disconnected Stores Shimmer */}
                <div className="space-y-4 ">
                  <div className="h-4 bg-gray-300 rounded lg:w-[20%] w-[40%]  animate-pulse"></div>
                  <div className="h-[100px] bg-gray-200 rounded-md flex items-center animate-pulse">
                    <div className="w-[50px] h-[50px] bg-gray-300 rounded-full mx-4"></div>
                    <div className="flex-1 space-y-2 py-1">
                      <div className="h-4 bg-gray-300 rounded w-[80%]"></div>
                      <div className="h-4 bg-gray-300 rounded w-[80%]"></div>
                    </div>
                    <div className="flex space-x-2 mr-4">
                      <div className="w-[70px] h-[40px] bg-gray-300 rounded"></div>
                      <div className="w-[40px] h-[40px] bg-gray-300 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          ) : (
            <>
              {(stores?.length === 0 && prevStoresConnected?.length === 0) && (
                <div className="flex items-center justify-center max-h-screen">
                  <div className={`flex flex-col items-center justify-center  transition-shadow duration-200 ease-in-out ${(!isWelcomePage) ? 'h-[214px] transform sm:translate-y-[120%] lg:translate-y-2/3 translate-y-1/2' : ''}`}>
                    {role?.toLowerCase() === 'admin' ? (
                      <>
                        {(activeTab === 'shopify' || isShopifyAccountPage) && (
                          <button
                            className="flex lg:flex-row flex-col items-center justify-center space-x-2 border lg:border-[#0000001A] bg-[#F8FAFC] rounded-lg lg:w-[300px] lg:h-[44px] w-[186px] h-[84px] border-black"
                            onClick={handleShopifyModal}
                          >
                            <FaShopify className="text-[#5EBD7E] text-lg" />
                            <span className="text-[#1E293B] sm:text-[16px] md:text-lg lg:font-medium font-semibold lg:mt-0 mt-[6px]">Add shopify store</span>
                          </button>
                        )}
                        {(activeTab === 'email' || isEmailAccountPage) && (
                          <button
                            className="lg:w-[260px] flex items-center justify-center rounded-lg space-x-2 border lg:h-[44px] bg-[#F8FAFC] border-black w-[220px] h-[84px] lg:flex-row flex-col"
                            onClick={handleIntegrationModal}
                          >
                            <FiPlus className="lg:text-base text-[#1B1A22] text-lg" />
                            <span className=" text-[#1E293B] sm:text-[16px] md:text-lg lg:mt-0 mt-[6px] lg:font-medium font-semibold">Connect email account</span>
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {activeTab === 'shopify' && (
                          <p className="text-red-400 text-sm">No shopify accounts connected.</p>
                        )}
                        {activeTab === 'email' && (
                          <p className="text-red-400 text-sm">No email accounts connected.</p>
                        )}
                      </>
                    )}

                  </div>
                </div>
              )}
              {/* Shopify Modal */}
              {showShopifyModal && activeTab === 'shopify' && (
                <PopupModal closeModal={handleCloseShopifyModal} className="bg-[#FFFFFF]">
                  <ShopifyAccountDetails closeModal={handleCloseShopifyModal} fetchStores={fetchStores} />
                </PopupModal>
              )}

              {(stores?.length !== 0 || prevStoresConnected?.length !== 0) && (
                <div className={`bg-white lg:overflow-y-auto ${!isWelcomePage && 'sm:mx-6 lg:mx-10 xl:mx-32'}`}>
                  {/* Add Store Button at the Top */}
                  {(role?.toLowerCase() === 'admin' && !isWelcomePage) && <div className="flex mb-3 lg:px-11 px-[12px]">
                    {stores?.length === 0 && (
                      <>
                        {activeTab === 'shopify' && (
                          <div className="flex items-center justify-center space-x-2 cursor-pointer bg-[#F8FAFC] w-[140px] sm:w-[224px] h-[44px] border border-black rounded-lg  hover:shadow-lg transition-shadow duration-200 ease-in-out sm:mt-6 mt-[8px]" onClick={handleShopifyModal}>
                            <FaShopify className="text-[#5EBD7E] text-lg" />
                            <span className="text-[#1E293B] text-[10px] sm:text-sm font-semibold">Add shopify store</span>
                          </div>
                        )}
                      </>
                    )}
                    {activeTab === 'email' && (
                      <button className="sm:w-[250px] w-[140px] flex items-center justify-center rounded-lg space-x-2 border h-[44px] bg-[#F8FAFC] border-black sm:mt-6 mt-[8px] hover:shadow-lg transition-shadow duration-200 ease-in-out" onClick={handleIntegrationModal}>
                        <FiPlus className="sm:text-base text-[14px] text-[#1B1A22]" />
                        <span className="sm:text-base text-[10px] text-[#1E293B]">Connect email account</span>
                      </button>

                    )}
                  </div>}

                  {/* Connected Stores Section */}

                  <div className={`max-w-full  ${!isWelcomePage && 'sm:py-6 lg:py-8 lg:px-6'} transition-all duration-300 lg:pt-4 items-center justify-center`}>
                    {!isWelcomePage && <div className="flex justify-between items-center lg:mb-4 lg:px-6 px-[12px] w-full">
                      <h1 className="text-[#000000] text-sm lg:text-[16px] font-medium ">Connected {activeTab === 'shopify' ? 'stores' : 'accounts'}</h1>
                    </div>}
                    {stores?.length > 0 ? (
                      <div className={`${!isWelcomePage && 'lg:px-6 px-[12px] w-full'}`}>
                        {stores.map((store, index) => (
                          <div key={index} className="bg-white rounded-lg shadow-[0px_4px_6px_0px_rgba(0,0,0,0.2)] sm:shadow-md pl-[10px] px-[6px] sm:px-4 lg:py-2 flex justify-between items-center mb-2 min-h-[100px] w-full">
                            <div className={`flex items-center sm:w-auto ${isWelcomePage ? 'w-[80%]' : 'w-[55%]'}`}>
                              {(activeTab === 'shopify' || isShopifyAccountPage) && (
                                <img src={ShopIcon} alt="Shop Icon" className="md:h-14 md:w-14 w-10 h-10" />
                              )}
                              {(activeTab === 'email' || isEmailAccountPage) && (
                                store?.account_type === 'gmail' ? (
                                  <img src={Gmail} alt="" className="md:h-14 md:w-14 w-8 h-8" />
                                ) : store?.account_type === 'outlook' ? (
                                  <img src={Outlook} alt="" className="md:h-14 md:w-14 w-8 h-8" />
                                ) : store?.account_type === 'imap_smtp' ? (
                                  <img src={IMAPIcon} alt="" className="md:h-14 md:w-14 w-8 h-8" />
                                ) : null
                              )}
                              <div className="lg:ml-4 ml-[8px] sm:w-auto w-full relative">
                                <div className="lg:text-[16px] text-[13px] text-left font-medium flex-col overflow-hidden break-words truncate text-ellipsis whitespace-nowrap" onClick={() => isMobileScreen && handleTooltipToggle(store?.id)}>
                                  {activeTab === 'shopify' ? store.shop_domain : store.email}
                                  {tooltipState.showTooltip && tooltipState.activeId === store.id && (
                                    <div className="absolute mt-[2px] p-2 bg-gray-700 text-white text-xs rounded-md shadow-lg z-10 text-[13px] ">
                                      <span className="absolute top-[-10px] left-[10px] lg:left-[20px] border-[6px] border-transparent border-b-gray-700"></span>
                                      {activeTab === 'shopify' ? store.shop_domain : store.email}</div>)}
                                  {activeTab === 'email' && (
                                    <div className={`${isEmailAccountPage && 'text-left'}`}>
                                      <div className="lg:block hidden text-xs mt-1 text-[#000000] font-dmsans">
                                        {store?.first_name || ""} {store?.last_name || ""}
                                      </div>
                                      {store?.start_date && <div className="text-[9px] lg:text-xs mt-[2px] sm:mt-1 text-[#888888] font-dmsans ml-[1px]">
                                        Email synced from {store?.start_date?.split('T')[0].split('-').reverse().join('/')}
                                      </div>}
                                    </div>
                                  )}
                                  <div className="mt-1 sm:pr-[2px] text-[8px] lg:text-[10px] text-[#000000] font-medium">Added on  {formatDate(store?.updated_at)}</div>
                                </div>
                              </div>
                            </div>
                            {!isWelcomePage && <div className="flex flex-col items-end w-[30%] sm:w-auto">
                              {role?.toLowerCase() === 'admin' && <button
                                className="bg-[#F3F3F3] text-[#1B1A22] px-[8px] sm:px-[16px] py-1 rounded-md text-[12px] lg:text-sm border border-[#868CA2]"
                                onClick={() => { handleShopifyDeleteModal(store?.id, activeTab === 'email' ? store?.account_type : null); { activeTab === 'email' && setSelectedAccountType(store?.account_type); } }}
                              >
                                Disconnect
                              </button>}
                            </div>}
                          </div>
                        ))}
                      </div>
                    ) : (<p className="text-center my-10 text-red-400">No connected {activeTab === 'shopify' ? 'stores' : 'accounts'} available.</p>)}
                  </div>


                  {/* Disconnected Stores Section */}
                  {(prevStoresConnected?.length > 0 && !isWelcomePage) && (
                    <div className="max-w-full lg:px-6 sm:py-6 lg:py-8 transition-all duration-300 pt-4 items-center justify-center">
                      <div className="flex justify-between items-center lg:mb-4 lg:px-6 px-[12px] w-full">
                        <h1 className="text-[#000000] text-sm lg:text-[16px] font-medium pt-2">Disconnected {activeTab === 'shopify' ? 'stores' : 'accounts'}</h1>
                      </div>

                      <div className="lg:px-6 px-[12px] w-full">
                        {prevStoresConnected.map((store) => (
                          <div key={store.id} className="bg-white rounded-lg shadow-[0px_4px_6px_0px_rgba(0,0,0,0.2)] sm:shadow-md px-[6px] pl-[10px] sm:px-4 lg:py-2 flex justify-between items-center mb-6 min-h-[100px]">
                            <div className="flex items-center sm:w-auto w-[54%]">
                              {activeTab === 'shopify' && (
                                <img src={ShopIcon} alt="Shop Icon" className="md:h-14 md:w-14 w-10 h-10" />
                              )}
                              {activeTab === 'email' && (
                                store?.account_type === 'gmail' ? (
                                  <img src={Gmail} alt="" className="md:h-14 md:w-14 w-8 h-8" />
                                ) : store?.account_type === 'outlook' ? (
                                  <img src={Outlook} alt="" className="md:h-14 md:w-14 w-8 h-8" />
                                ) : store?.account_type === 'imap_smtp' ? (
                                  <img src={IMAPIcon} alt="" className="md:h-14 md:w-14 w-8 h-8" />
                                ) : null
                              )}
                              <div className="lg:ml-4 ml-[8px] sm:w-auto w-full">
                                <div className="lg:text-[16px] text-[13px] text-[#000000] font-medium flex-col overflow-hidden break-words truncate text-ellipsis whitespace-nowrap" onClick={() => isMobileScreen && handleTooltipToggle(store?.id)}>{activeTab === 'shopify' ? store.shop_domain : store.email}
                                  {tooltipState.showTooltip && tooltipState.activeId === store.id && (
                                    <div className="absolute mt-[2px] p-2 bg-gray-700 text-white text-xs rounded-md shadow-lg z-10 text-[13px] ">
                                      <span className="absolute top-[-10px] left-[10px] lg:left-[20px] border-[6px] border-transparent border-b-gray-700"></span>
                                      {activeTab === 'shopify' ? store.shop_domain : store.email}</div>)}
                                  {activeTab === 'email' && (
                                    <div>
                                      <div className="lg:block hidden text-xs mt-1 text-[#000000] font-dmsans">
                                        {store?.first_name || ""} {store?.last_name || ""}
                                      </div>
                                      {store?.start_date && <div className="text-[9px] lg:text-xs mt-[2px] sm:mt-1 text-[#888888] font-dmsans">
                                        Email synced from {store?.start_date?.split('T')[0].split('-').reverse().join('/')}
                                      </div>}
                                    </div>
                                  )}
                                  <div className="mt-1 text-[8px] lg:text-[10px] text-[#000000] font-medium sm:pr-[2px]">Last used on {formatDate(store?.updated_at)}</div>
                                </div>
                              </div>

                            </div>
                            <div className="flex flex-col items-end w-[31%] sm:w-auto mt-[16px]">
                              {role?.toLowerCase() === 'admin' && <div className="flex space-x-2 sm:space-x-3">
                                <button
                                  className={`px-[8px] sm:px-[16px] h-[24px] sm:h-[30px] my-auto text-[12px] lg:text-sm rounded-lg ${activeTab === 'shopify' && stores.length > 0
                                    ? 'bg-[#A0A0A0] text-[#FFFFFF] cursor-not-allowed' // Disabled style
                                    : 'bg-[#039855] text-[#FFFFFF]'
                                    }`}
                                  onClick={() => (activeTab === 'shopify' && stores.length > 0
                                    ? toast.info("You can only connect one store at a time. Please disconnect the current store before restoring.")
                                    : handleShopifyRestoreModal(activeTab === 'shopify' ? store?.id : store?.account_type === 'imap_smtp' ? store?.id : store.email, store?.account_type))}

                                // disabled={activeTab === 'shopify' && stores.length > 0} // Disable if there are stores
                                >
                                  {activeTab === 'shopify' ? "Restore" : "Reconnect"}
                                </button>
                                <button data-testid="delete-button" className=" text-red-600 lg:py-2 rounded-md" onClick={() => {
                                  handleDeleteAccountModal(store.id, activeTab === 'email' ? store?.account_type : null);
                                  activeTab === 'email' && setSelectedAccountType(store?.account_type);
                                }}><DeleteIcon size={window.matchMedia(BREAKPOINTS.mobile).matches ? 18 : 22} /></button>
                              </div>}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}


              {(showDeleteShopifyModal || showdeleteModal) && (
                <PopupModal closeModal={handleCloseShopifyDeleteModal} className="bg-[#F9F9F9]">
                  <div className="flex flex-col justify-center items-center w-[300px] sm:h-[200px] sm:w-[360px]">
                    <img src={FeaturedIcon} alt="" className="mb-3" />
                    <div className="text-[#101828] font-medium text-base sm:text-lg mb-2">
                      {showdeleteModal ? `Delete ${activeTab === 'shopify' ? 'store' : 'account'}` : `Disconnect ${activeTab === 'shopify' ? 'store' : 'account'}`}
                    </div>
                    <p className="text-[#667085] font-dmsans text-[14px] font-normal mb-3 text-center">
                      {showdeleteModal ? (
                        <span>Are you sure you want to delete this {activeTab === 'shopify' ? 'store' : 'account'}<br /> permanently?</span>
                      ) : (
                        `Are you sure you want to disconnect this ${activeTab === 'shopify' ? 'store' : 'account'}?`
                      )}
                    </p>
                    <div className="flex justify-center space-x-6 w-full">
                      <button
                        className="text-sm sm:text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[36px] sm:h-[44px] flex items-center justify-center"
                        onClick={showdeleteModal ? handleCloseDeleteAccountModal : handleCloseShopifyDeleteModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={() => showdeleteModal ? handleDeleteAccount(storeIdToDelete) : handleUpdateAccount(storeIdToDelete, true)}
                        disabled={(isLoading || isDeleting)}
                        className="w-[150px] h-[36px] sm:h-[44px] rounded-md bg-[#D92D20] text-sm sm:text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
                      >
                        {(isLoading || isDeleting) ? (
                          <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                        ) : (
                          showdeleteModal ? "Delete" : "Disconnect"
                        )}
                      </button>
                    </div>
                  </div>
                </PopupModal>

              )}

              {showRestoreShopifyModal && (
                <PopupModal closeModal={handleCloseShopifyRestoreModal} className="bg-[#F9F9F9]">
                  <div className="flex flex-col justify-center items-center w-[300px] sm:h-[200px] sm:w-[360px]">
                    <img src={RestoreIcon} alt="" className="mb-3" />
                    <div className="text-[#101828] font-medium text-base sm:text-lg mb-2"> {activeTab === 'shopify' ? 'Restore store' : 'Reconnect account'}</div>
                    <p className="text-[#667085] font-dmsans text-[14px] font-normal mb-6 text-center">
                      Are you sure you want to {activeTab === 'shopify' ? "restore" : "reconnect"} this {activeTab === 'shopify' ? 'store' : 'account'}?
                    </p>
                    <div className="flex justify-center space-x-6 w-full">
                      <button
                        className="text-sm sm:text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[36px] sm:h-[44px] flex items-center justify-center"
                        onClick={handleCloseShopifyRestoreModal}
                      >
                        Cancel
                      </button>
                      {activeTab === 'shopify' && (
                        <button
                          type="button"
                          onClick={() => handleUpdateAccount(storeIdToDelete, false)}
                          disabled={isLoading}
                          className="w-[150px]  h-[36px] sm:h-[44px] rounded-md bg-[#039855] text-sm sm:text-base text-[white] font-medium text-center flex items-center justify-center"
                          data-testid="restore"
                        >
                          {isLoading ? (
                            <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                          ) : (
                            "Restore"
                          )}
                        </button>)}
                      {activeTab === 'email' && (
                        <>
                          {selectedAccountType === 'imap_smtp' ? (
                            <button
                              type="button"
                              onClick={() => setShowIMAPForm(true)}
                              disabled={isLoading}
                              className="w-[150px]  h-[36px] sm:h-[44px] rounded-md bg-[#039855] text-sm sm:text-base text-[white] font-medium text-center flex items-center justify-center"
                            >
                              {isLoading ? (
                                <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                              ) : (
                                "Restore"
                              )}
                            </button>
                          ) : (
                            <OAuthFlowButton
                              text="Reconnect"
                              email={storeIdToDelete}
                              accountType={selectedAccountType}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </PopupModal>
              )}


              {showCalendar && (
                <PopupModal closeModal={() => setShowCalendar(true)} className="bg-[#FFFFFF]" >
                  <div className="flex flex-col font-dmsans sm:w-[400px] min-w-[300px] justify-center items-center">
                    <div className="text-[#051B44] text-[14px] lg:text-base font-semibold border-b border-[#868CA280] pb-2 mb-3 sm:w-[90%] w-full text-left">
                      Sync email from
                    </div>

                    <div className="flex justify-center items-center w-full">
                      <Calendar
                        onChange={handleDateChange}
                        value={selectedDate}
                        maxDate={new Date()} // Today
                        minDate={new Date(Date.now() - 14 * 24 * 60 * 60 * 1000)} // 14 days ago
                        className="custom-calendar h-[252px] sm:h-[300px]"
                      />
                    </div>

                    <div className="flex justify-end w-full lg:w-[90%] mt-4">
                      <button
                        onClick={handleApply}
                        className="text-sm font-medium text-[#1B1A22] w-[74px] h-[32px] bg-[#96F3C3] border border-transparent rounded-md hover:bg-green-500"
                      >
                        Apply
                      </button>
                    </div>
                  </div>


                </PopupModal>
              )}

              {showIMAPForm && (
                <PopupModal closeModal={handleCloseShopifyModal} className="bg-[#FFFFFF]">
                  <CustomIMAPDetails closeModal={handleCloseShopifyModal} handleOAuthSuccess={handleOAuthSuccess} setStoreIdToDelete={setStoreIdToDelete}
                    setSelectedAccountType={setSelectedAccountType} setConfigSMTPData={setConfigSMTPData} configSMTPData={configSMTPData} showRestoreShopifyModal={showRestoreShopifyModal}
                  />
                </PopupModal>
              )}


              {showIntegrationModal && (
                <PopupModal closeModal={handleCloseIntegrationModal} outerClassName="justify-center z-30" className="bg-[#FFFFFF]">
                  <div className="sm:w-[520px] md:w-[580px] max-h-[400px] custom-scrollbar min-w-[300px]">
                    <div className="flex justify-between w-full mb-4">
                      <div className="md:text-lg text-base font-medium ">Select your email provider</div>
                      <button className="text-[#2B2E3A]" onClick={handleCloseIntegrationModal}>
                        <AiOutlineClose size={18} />
                      </button>
                    </div>
                    <div className="flex flex-col space-y-6 ">
                      {activeTab === "email" && (
                        <>
                          <div className="flex justify-between sm:p-3 p-[6px] rounded-md h-[54px] sm:h-[78px] border border-[#868CA280] bg-[#F8FAFC54] items-center">
                            <div className="flex items-center">
                              <img
                                src={Gmail}
                                alt=""
                                className="md:w-[43px] md:h-[32px] w-[30px] h-[22px]"
                              />
                              <span className="ml-2 md:ml-3 sm:text-base text-[12px] text-[#1E293B]">Gmail</span>
                            </div>
                            <OAuthFlowButton accountType="gmail" text="Connect" />
                          </div>
                          <div className="flex justify-between sm:p-3 p-[6px] rounded-md h-[54px] sm:h-[78px] border border-[#868CA280] bg-[#F8FAFC54] items-center">
                            <div className="flex items-center">
                              <img
                                src={Outlook}
                                alt=""
                                className="md:w-[43px] md:h-[32px] w-[30px] h-[22px]"
                              />
                              <span className="ml-2 md:ml-3 text-[#1E293B] sm:text-base text-[12px]">Outlook</span>
                            </div>
                            <OAuthFlowButton accountType="outlook" text="Connect" />
                          </div>
                          {/* IMAP email integration */}
                         {IMAPSMTPmaintenanceMode &&
                          <div className="flex justify-between sm:p-3 p-[6px] rounded-md h-[54px] sm:h-[78px] border border-[#868CA280] bg-[#F8FAFC54] items-center">
                            <div className="flex items-center">
                              <img
                                src={IMAPIcon}
                                alt=""
                                className="md:w-[43px] md:h-[42px] w-[30px] h-[22px]"
                              />
                              <span className="ml-2 md:ml-3 sm:text-base text-[12px] text-[#1E293B]">Custom IMAP</span>
                            </div>
                            <button
                              className="py-[10px] w-[74px] md:w-[86px] bg-[#FFFFFF] border border-[#E7EAEE] gap-2 flex justify-center items-center md:h-[40px] h-[36px]  border-opacity-10 rounded-[6px]"
                              onClick={() => setShowIMAPForm(true)}
                            >
                              <div className="text-[12px] md:text-[14px] font-dmsans leading-normal text-[#1E293B]">
                                Connect
                              </div>
                            </button>
                          </div>}
                        </>
                      )}
                    </div>
                  </div>
                </PopupModal>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntegrationPage;
