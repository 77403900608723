import React from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../services/login';
import { getStoredValue } from '../services/logics';
const ProtectedAdminRoutes = () => {
  const token =  getStoredValue("token");
  const navigate = useNavigate();
  const isSuperAdmin = getStoredValue("is_super_admin");
  const store_id = getStoredValue("storeid");

  isAuthenticated();
  
  if (!token) {
    return <Navigate to="/signin" replace />;
  }

  if(isSuperAdmin === "True" && (store_id==="undefined")){
   
    return <Outlet />
  }
  return <Navigate  to = '/error' replace/>
}

export default ProtectedAdminRoutes