const ErrorIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 50 50" viewBox="0 0 50 50" id="ComputerSetting">
	  <path
		fill="#0b0c16"
		d="M48.99756,30.72534V7.99854c0-2.75684-2.24316-5-5-5h-38c-2.75684,0-5,2.24316-5,5v26.62012
		  c0,2.75684,2.24316,5,5,5h13.03589l-1.49463,5.38281h-4.99438c-0.55273,0-1,0.44727-1,1s0.44727,1,1,1h5.75488h13.40625h5.75488
		  c0.55273,0,1-0.44727,1-1s-0.44727-1-1-1h-4.99438l-1.49463-5.38281h13.02612c2.75684,0,5-2.24316,5-5v-3.84497
		  c0.00024-0.00854,0.00488-0.01563,0.00488-0.02417S48.9978,30.73389,48.99756,30.72534z M5.99756,4.99854h38c1.6543,0,3,1.3457,3,3
		  v21.75098H26.41553V29.1167c0-0.46289-0.31836-0.86621-0.76855-0.97266l-1.55811-0.37
		  c-0.13354-0.38831-0.29126-0.76355-0.47363-1.12683l0.82666-1.34399c0.24316-0.39355,0.18262-0.90332-0.14453-1.23047
		  l-2.25586-2.25684c-0.32715-0.3291-0.83984-0.38574-1.23242-0.14355l-1.36011,0.84045
		  c-0.36694-0.18152-0.74634-0.33728-1.13916-0.4679l-0.36304-1.52686c-0.10742-0.4502-0.50977-0.76855-0.97266-0.76855h-3.18164
		  c-0.46289,0-0.86621,0.31836-0.97266,0.76953l-0.36816,1.55127c-0.39111,0.13354-0.77148,0.29309-1.1416,0.47937L9.9751,21.7251
		  c-0.3916-0.24316-0.90234-0.18457-1.23145,0.14258l-2.25098,2.24707c-0.3291,0.32813-0.38867,0.83887-0.14453,1.2334
		  l0.83984,1.36011c-0.18408,0.37085-0.34131,0.75256-0.4729,1.14648l-1.52515,0.36157
		  c-0.45117,0.10645-0.76953,0.50879-0.76953,0.97266v0.56055H2.99756V7.99854C2.99756,6.34424,4.34326,4.99854,5.99756,4.99854z
		  M20.37036,29.74951c-0.46875-2.33032-2.51855-4.09668-4.98804-4.09668c-2.47168,0-4.5354,1.76367-5.0022,4.09668H7.38965
		  c0.1-0.07055,0.86648-0.04022,1.06396-0.79883c0.16211-0.63184,0.41113-1.23438,0.7373-1.79199
		  c0.16702-0.28428,0.16301-0.74645-0.01172-1.03125l-0.71387-1.15625l1.13184-1.12891
		  c0.58442,0.21354,1.33133,1.20824,2.1709,0.71094c0.56055-0.33203,1.16113-0.58496,1.78613-0.75
		  c0.01172-0.00317,0.01978-0.01196,0.03149-0.01563c0.90799-0.27553,0.70436-1.37939,0.99683-2.03809h1.60156
		  c0.30278,1.19585,0.28387,1.70916,0.90112,1.97021c0.04492,0.01892,0.0813,0.05054,0.13013,0.06299
		  c0.63379,0.16406,1.2334,0.41113,1.78223,0.7334c0.32087,0.18886,0.73226,0.16372,1.01758-0.00635
		  c0.00464-0.00269,0.01001-0.00244,0.01465-0.00537l1.15723-0.71484l1.1377,1.13867l-0.70313,1.14453
		  c-0.14626,0.2366-0.18395,0.59276-0.05298,0.9054c0.01758,0.04187,0.02051,0.08655,0.04419,0.12683
		  c0.32422,0.54883,0.57617,1.14746,0.74805,1.7793c0.01904,0.06958,0.05566,0.12781,0.0874,0.18945
		  c0.0144,0.02808,0.02222,0.05847,0.03931,0.08508c0.06665,0.10388,0.14795,0.19446,0.24487,0.26599
		  c0.00659,0.00476,0.01514,0.00671,0.02173,0.01135c0.09375,0.06555,0.198,0.11169,0.30884,0.14221
		  c0.01172,0.0033,0.02026,0.01196,0.03223,0.01489l0.6582,0.15625H20.37036z M18.31982,29.74951h-5.875
		  c0.41797-1.21875,1.57715-2.09668,2.9375-2.09668S17.90186,28.53076,18.31982,29.74951z M30.39014,45.00146H19.61475
		  l1.49512-5.38281h7.78516L30.39014,45.00146z M43.99756,37.61865h-38c-1.6543,0-3-1.3457-3-3v-2.86914c8.62667,0,35.20289,0,44,0
		  v2.86914C46.99756,36.27295,45.65186,37.61865,43.99756,37.61865z"
		className="color010101 svgShape"
	  ></path>
	  <path
		fill="#0b0c16"
		d="M35.96045,20.77588c2.08008,0,3.77148-1.69531,3.77148-3.77832c0-2.08398-1.69141-3.7793-3.77148-3.7793
		  c-2.0791,0-3.77051,1.69531-3.77051,3.7793C32.18994,19.08057,33.88135,20.77588,35.96045,20.77588z M35.96045,15.21826
		  c0.97656,0,1.77148,0.79785,1.77148,1.7793c0,0.98047-0.79492,1.77832-1.77148,1.77832s-1.77051-0.79785-1.77051-1.77832
		  C34.18994,16.01611,34.98389,15.21826,35.96045,15.21826z"
		className="color010101 svgShape"
	  ></path>
	  <path
		fill="#0b0c16"
		d="M26.73486,19.44385l1.34717,0.32031c0.11182,0.32007,0.2417,0.63086,0.39063,0.93408l-0.71729,1.17236
		  c-0.24023,0.39258-0.18066,0.89941,0.14453,1.22656l2.0293,2.04004c0.19336,0.19434,0.4502,0.29492,0.70898,0.29492
		  c0.18164,0,0.36426-0.0498,0.52734-0.15039l1.17529-0.72986c0.30249,0.14783,0.61279,0.27612,0.93286,0.38586l0.3147,1.32739
		  c0.10645,0.45117,0.50879,0.76953,0.97266,0.76953h2.8623c0.46387,0,0.86621-0.31836,0.97266-0.76953l0.31982-1.35193
		  c0.31982-0.11194,0.6311-0.24304,0.93555-0.39417l1.15186,0.71484c0.16309,0.10156,0.3457,0.15039,0.52734,0.15039
		  c0.25879,0,0.51465-0.10059,0.70801-0.29395l2.02539-2.03125c0.32617-0.32715,0.38574-0.83594,0.14355-1.22949L43.479,20.64404
		  c0.14966-0.30859,0.27954-0.625,0.39014-0.95142l1.31689-0.31226c0.45117-0.10645,0.76953-0.50879,0.76953-0.97266v-2.87988
		  c0-0.46289-0.31738-0.86523-0.76758-0.97266l-1.34277-0.32068c-0.1123-0.32385-0.2439-0.63843-0.39526-0.94568l0.71167-1.15942
		  c0.24121-0.39355,0.18262-0.90039-0.14258-1.22754l-2.02148-2.03906c-0.32813-0.33105-0.84082-0.3916-1.2373-0.14551
		  l-1.17578,0.72949c-0.3042-0.14819-0.61548-0.27649-0.93506-0.38586l-0.31104-1.32898
		  c-0.10547-0.45215-0.50879-0.77246-0.97363-0.77246h-2.87012c-0.46387,0-0.86719,0.31934-0.97363,0.77051l-0.32056,1.35791
		  c-0.31738,0.11206-0.62476,0.24194-0.92358,0.39063l-1.1582-0.71826c-0.39551-0.24609-0.9082-0.18652-1.2373,0.14453
		  l-2.02148,2.03516c-0.32422,0.32715-0.38379,0.83398-0.14258,1.22754l0.72754,1.18835
		  c-0.1499,0.31067-0.27905,0.62549-0.38818,0.94617l-1.32275,0.31567c-0.4502,0.10742-0.76758,0.50977-0.76758,0.97266v2.88086
		  C25.96631,18.93408,26.28467,19.3374,26.73486,19.44385z M30.74072,10.87842c0.42244,0.14468,1.18626,1.09124,2,0.60254
		  c1.01167-0.607,1.6338-0.60216,1.94287-0.86401c0.16552-0.1369,0.29172-0.34257,0.33838-0.53735l0.26367-1.12012h1.28613
		  l0.25684,1.09766c0.19855,0.85285,0.98212,0.62111,2.30762,1.39453c0.76608,0.44579,1.38459-0.33563,2.00195-0.61523
		  l0.9082,0.91602c-0.38486,0.65142-0.98404,1.34347-0.59961,1.99219c1.23277,2.08874-0.04915,1.89647,2.50879,2.57226v1.2998
		  c-1.82581,0.56177-1.3616-0.15888-2.5249,2.70898c-0.32452,0.79047,0.44987,1.32255,0.66064,1.87891l-0.9082,0.91113
		  c-1.73895-0.99118-0.92881-0.99783-3.68335,0.1228c-0.71741,0.292-0.63033,1.05647-0.86743,1.79614h-1.28027
		  c-0.51003-1.84098,0.06379-1.39804-2.68994-2.53101c-0.71725-0.29797-1.18871,0.30412-1.87549,0.65894l-0.91504-0.91895
		  c0.97397-1.65298,0.99073-1.09651-0.11938-3.70679c-0.33426-0.78641-1.26079-0.61941-1.78589-0.85571v-1.30176
		  c2.51128-0.6163,1.20232-0.33507,2.48438-2.58398c0.38309-0.6725-0.22213-1.33183-0.61816-2.00293L30.74072,10.87842z"
		className="color010101 svgShape"
	  ></path>
	</svg>
  );
  
  export default ErrorIcon;
  