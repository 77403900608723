import React, { useEffect, useState, useRef, useCallback } from "react";
import { IoSearch } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CiFilter } from "react-icons/ci";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import DraftsPagination from "../../components/DraftsPagination";
import DraftShimmer from "../../components/DraftShimmer";
import axiosInstance from "../../services/axiosInstance";
import { formatDate } from "../../services/logics";

const AdminUserPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const dropdownRef = useRef(null);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const toggleFilterDropdown = () => {
    setFilterDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setFilterDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (filterDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterDropdownOpen]);

  const fetchUsersList = async (searchTerm, page = 1, is_active = selectedFilter) => {
    try {
      let isActiveFilter = null;

      // Determine the value for the is_active filter based on selectedFilter
      if (is_active === "ACTIVE") {
        isActiveFilter = true;
      } else if (is_active === "INACTIVE") {
        isActiveFilter = false;
      }

      const res = await axiosInstance.get(`${API_URL}/v1/admin/users`, {
        params: {
          page,
          page_size: 10,
          search: searchTerm,
          is_active: isActiveFilter,
        },
      });
      const data = res.data;
      setUsers(data.users);
      setTotalRecords(data.total);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }

    setIsFetching(false);
  };

  useEffect(() => {
    fetchUsersList(searchTerm, page, selectedFilter);
  }, [page]);

// Debounced search function to prevent multiple API calls
const debouncedSearch = useCallback(
  debounce((term, selectedFilter) => {
    setPage(1);
    fetchUsersList(term, 1, selectedFilter);
  }, 500),
  []
);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value, selectedFilter);
  };

  const handlePageChange = (newPage) => {
    if (newPage !== page) {
      setIsFetching(true);
      setPage(newPage);
      fetchUsersList(searchTerm, newPage, selectedFilter);
    }
  };


  const handleTabChange = (tab) => {
    setSelectedFilter(tab);
    setPage(1);
    setIsFetching(true);
    toggleFilterDropdown();
    fetchUsersList(searchTerm, 1, tab);
  };


  return (
    <div className="pt-2 flex flex-col font-plus-jakarta  transition-all ease-in-out duration-500 overflow-hidden ">
      {/* Top Section with Tabs and Search */}
      <div className="flex justify-between items-center mb-2 ">
        <div className="flex ">
          <button
            onClick={() => handleTabChange("ALL")}
            className={`px-4 py-1 text-sm font-medium rounded-md ${selectedFilter === "ALL" ? 'bg-[#B0FBDA] text-[#000000] shadow-md' : 'text-[#868CA2] bg-white'}`}
          >
            All
          </button>
          <button
            onClick={() => handleTabChange("ACTIVE")}
            className={`px-4 py-1 text-sm font-medium rounded-md ${selectedFilter === "ACTIVE" ? 'bg-[#B0FBDA] text-[#000000] shadow-md' : 'text-[#868CA2] bg-white'}`}
          >
            Active
          </button>
          <button
            onClick={() => handleTabChange("INACTIVE")}
            className={`px-4 py-1 text-sm font-medium rounded-md ${selectedFilter === "INACTIVE" ? 'bg-[#B0FBDA] text-[#000000] shadow-md' : 'text-[#868CA2] bg-white'}`}
          >
            In-active
          </button>
        </div>

        {/* Search Bar */}
        <div className="flex items-center gap-[14px]">
          <div className="relative w-[200px] h-[40px] border border-[#868CA2] bg-white text-[#000000] rounded-md">
            <IoSearch className="absolute right-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-[#64748B]" />
            <input
              type="text"
              placeholder="Search"
              className="focus:outline-none w-[84%] h-full ml-1 text-[#64748B] text-[15px]"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>

      {/* Users List */}
      <div className="w-full">
        {isFetching ? (
          <DraftShimmer show={false}/>
        ) : users?.length === 0 ? (
          <div className="flex justify-center py-8 text-red-500">No records found.</div>
        ) : (
          <>
            <div className="overflow-auto custom-scrollbar mb-2">
              {users.map((user) => (
                <div
                  key={user.id}
                  className="grid grid-cols-5 items-center py-3  transition-all duration-150"
                >
                  {/* Avatar + Name + Email */}
                  <div className="flex items-center col-span-3"> {/* Increased span for more space */}
                    <div className="flex-shrink-0 w-7 h-7 bg-[#2B2E3A] text-uppercase rounded-full flex items-center justify-center text-[#61F8B5] text-[13px] font-medium">
                      {user.first_name?.[0] || ''}{user.last_name?.[0] || ''}
                    </div>
                    <div className="ml-2">
                      <p className="font-medium text-[#18181B] text-xs mb-1">{user?.first_name} {user?.last_name}</p>
                      <p className="text-[#71717A] text-xs">
                        {user.email || 'No email provided'} {/* Dynamically rendered email */}
                      </p>
                    </div>
                  </div>

                  {/* Sign In Date */}
                  <div className="flex flex-col items-start col-span-1">
                    <div className="text-xs font-medium text-[#71717A] mb-1">Signed On</div>
                    <div className="text-xs text-[#18181B] font-bold">
                      {user?.updated_at ? formatDate(user?.updated_at) : ""}
                    </div>
                  </div>

                  {/* Status */}
                  <div className="flex justify-end col-span-1"> {/* Adjusted column span */}
                    <span
                      className={`inline-block  py-1 text-sm rounded-full ${user.is_active
                        ? ' text-[#039855]'
                        : 'text-[#D92D20]'
                        }`}

                    >
                      {user?.is_active ? "Active" : "In-active"}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            {/* Pagination */}
            <DraftsPagination
              currentPage={page}
              onPageChange={handlePageChange}
              totalDrafts={totalRecords} 
            />
          </>
        )}
      </div>
      </div>
  );
};

export default AdminUserPage;
