import React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';

const CombinedPieOrSpeedometerChart = ({ isSpeedometer, total, label, sentPercentage, deletedPercentage, editedPercentage }) => {
  // Data for both charts
  const refundPercentage = total?.total_actions > 0 ? (total?.refund_count / total?.total_actions) * 100 : 0;
  const orderPercentage = total?.total_actions > 0 ? (total?.order_change_count / total?.total_actions) * 100 : 0;
  const updateadressPercentage = total?.total_actions > 0 ? (total?.update_address_count / total?.total_actions) * 100 : 0;
  const data = isSpeedometer
    ? [
      { title: 'Refund', value: refundPercentage, color: '#2B2E3A' }, // Dark color
      { title: 'Order change', value: orderPercentage, color: '#96F3C3' }, // Green color
      { title: 'Update address', value: updateadressPercentage, color: '#868CA2' }, // Light grey
    ]
    : [
      { title: 'Good', value: sentPercentage, color: '#61F8B5' }, // Good drafts
      { title: 'Edited', value: editedPercentage, color: '#F9D371' }, // Edited drafts
      { title: 'Bad', value: deletedPercentage, color: '#868CA2' }, // Bad drafts
    ];

  // Custom label for the center of the pie/speedometer chart
  const CustomLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox;
    return (
      <>
        <text x={cx}  y={isSpeedometer ? cy - 46 : cy - 10} textAnchor="middle" dominantBaseline="middle" fontSize="12" fill="#868CA2">
          {label}
        </text>
        <text
          x={cx}
          y={isSpeedometer?cy-22:cy + 10}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={isSpeedometer ? '22' : '12'}
          fill={isSpeedometer ? '#2B2E3A' : '#868CA2'}
          fontWeight={isSpeedometer ? 'bold' : 'normal'}
        > {isSpeedometer ? total?.total_actions : total}
        </text>
      </>
    );
  };

  // Legend for the pie chart
  const renderLegend = () => (
    <div className="flex justify-center space-x-2 max-w-full gap-x-2 md:gap-x-1 lg:gap-x-4 mt-4">
      {/* Total Draft */}
      <div className="flex items-center">
        <div className="h-2 w-4 bg-[#2B2E3A] mr-2"></div>
        <span className="text-[11px] text-[#868CA2]">Total draft</span>
      </div>
      {/* Good Draft */}
      <div className="flex items-center">
        <div className="h-2 w-4 bg-[#6AFFC7] mr-2"></div>
        <span className="text-[11px] text-[#868CA2]">Good draft</span>
      </div>
       {/* Edited Draft */}
       <div className="flex items-center">
        <div className="h-2 w-4 bg-[#F9D371] mr-2"></div>
        <span className="text-[11px] text-[#868CA2]">Edited draft</span>
      </div>
      {/* Bad Draft */}
      <div className="flex items-center">
        <div className="h-2 w-4 bg-[#868CA2] mr-2"></div>
        <span className="text-[11px] text-[#868CA2]">Bad draft</span>
      </div>
    </div>
  );

  const renderActionLegend = () => (
    <div className="flex justify-center space-x-6 mt-[-90px]">
      {/* Total Draft */}
      <div className="flex items-center">
        <div className="h-[6px] w-[6px] rounded-full bg-[#2B2E3A] mr-1"></div>
        <span className="text-[11px] text-[#868CA2]">Refund</span>
      </div>
      {/* Good Draft */}
      <div className="flex items-center">
        <div className="h-[6px] w-[6px] rounded-full bg-[#96F3C3] mr-1"></div>
        <span className="text-[11px] text-[#868CA2]">Order change</span>
      </div>
      {/* Bad Draft */}
      <div className="flex items-center">
        <div className="h-[6px] w-[6px] rounded-full bg-[#868CA2] mr-1"></div>
        <span className="text-[11px] text-[#868CA2]">Update address</span>
      </div>
    </div>
  );

  return (
    <div className="pie-charts ">
      <div
        className="pie-chart-wrapper flex justify-center items-center"
        style={{
          outline: 'none',
          marginTop: isSpeedometer ? '10px' : '-10px', 
        }}

      >
        <PieChart width={isSpeedometer ? 240 : 180} height={isSpeedometer ? 240 : 200}>
          <Pie
            stroke="none"
            legendType="circle"
            data={data}
            dataKey="value"
            startAngle={isSpeedometer ? 180 : 90} // Semi-circle for speedometer, full circle for pie chart
            endAngle={isSpeedometer ? 0 : 450} // Semi-circle for speedometer, full circle for pie chart
            cornerRadius={isSpeedometer ? 50 : 100}
            innerRadius={isSpeedometer ? 110 : 72}
            outerRadius={isSpeedometer ? 120 : 90}
            paddingAngle={4}
            labelLine={false}
            isAnimationActive={true}
          >
            {data.map((entry, index) => (
              <Cell key={`slice-${index}`} fill={entry.color} style={{ outline: 'none' }} />
            ))}
            {/* Custom Label for the center */}
            <Label content={<CustomLabel />} position="center" />
          </Pie>
        </PieChart>
      </div>
      {isSpeedometer ?renderActionLegend(): renderLegend()}
    </div>
  );
};

export default CombinedPieOrSpeedometerChart;
