import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getStoredValue } from "../services/logics";
import axiosInstance from "../services/axiosInstance";
import { LuGlobe, LuPartyPopper } from "react-icons/lu";
import { isAuthenticated } from "../services/login";
import IntegrationPage from "./IntegrationPage";
import { FaCheck } from "react-icons/fa";
import ZenvaLogo from "../assets/SidebarAssets/ZenvaLogo.svg";
import {BREAKPOINTS} from "../services/emailUtils"

const steps = [
  { id: "language", label: "Select your default language", completed: "preferedlanguage" },
  { id: "shopifyaccount", label: "Connect shopify account", completed: "shopifyaccount" },
  { id: "emailaccount", label: "Connect email account", completed: "emailaccount" },
  { id: "uploadDocument", label: "Upload your first document", completed: "documentUploaded" },
];
const MemoizedIntegrationPage = React.memo(IntegrationPage);

const ApplicationSetup = () => {
  const navigate = useNavigate();
  const { status } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(getStoredValue("preferedlanguage") || "");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [allowedLanguages, setAllowedLanguages] = useState([]);
  const [file, setFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showCongratulations, setShowCongratulations] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const token = getStoredValue("token");
  const userId = getStoredValue("id");
  const store_id = getStoredValue("storeid");
  const isMobileScreen = window.matchMedia(BREAKPOINTS.mobile).matches

  const [completionStatus, setCompletionStatus] = useState({
    preferedlanguage: getStoredValue("preferedlanguage") !== "null",
    documentUploaded: getStoredValue("documentUploaded") === "true",
    emailaccount: getStoredValue("emailaccount") === "true",
    shopifyaccount: getStoredValue("shopifyaccount") === "true",
  });

  const refreshCompletionStatus = useCallback(() => {
    const updatedStatus = {
      preferedlanguage: getStoredValue("preferedlanguage") !== "null",
      documentUploaded: getStoredValue("documentUploaded") === "true",
      emailaccount: getStoredValue("emailaccount") === "true",
      shopifyaccount: getStoredValue("shopifyaccount") === "true",
    };
    setCompletionStatus(updatedStatus);
  }, []);

  const isCurrentStepCompleted = completionStatus[steps[currentStep]?.completed];
  const areAllStepsCompleted = Object.values(completionStatus).every((status) => status === true);
  const onboarded = getStoredValue("onboarded") === "true";

  useEffect(() => {
    const updateSession = async () => {
      await isAuthenticated();
      refreshCompletionStatus();
    };

    if (!onboarded) {
      const firstUncompletedStepIndex = steps.findIndex((step) => !completionStatus[step.completed]);
      setCurrentStep(firstUncompletedStepIndex !== -1 ? firstUncompletedStepIndex : 0);

      const stepIndexFromUrl = steps.findIndex((step) => step.id === status);
      if (stepIndexFromUrl !== -1) {
        setCurrentStep(stepIndexFromUrl);
      } else if (firstUncompletedStepIndex !== -1) {
        navigate(`/welcome-page/${steps[firstUncompletedStepIndex].id}`);
      }
    } else {
      navigate("/document-page");
    }

    updateSession();
  }, [status, navigate, onboarded, refreshCompletionStatus,]);

  const updateUserLanguage = async (language) => {
    try {
      await axiosInstance.put(`${API_URL}/v1/users/${userId}`, { prefered_language: language });
      sessionStorage.setItem("preferedlanguage", language);
      setSelectedLanguage(language);
      refreshCompletionStatus();
      toast.success(`Language updated to ${language} successfully`);
      setIsDropdownOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.detail);
    }
  };

  const handleLanguageSelect = (language) => {
    updateUserLanguage(language);
  };

  const fetchAllowedLanguages = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/v1/user/languages`);
      setAllowedLanguages(response.data?.languages);
    } catch (error) {
      toast.error(error.response?.data?.detail);
    }
  }, []);

  useEffect(() => {
    if (currentStep === 0 && !allowedLanguages.length) {
      fetchAllowedLanguages();
    }
  }, [currentStep, fetchAllowedLanguages]);

  const handleNextStep = () => {
    if (isCurrentStepCompleted && currentStep < steps.length) {
      const nextStep = currentStep + 1;
      if (nextStep === steps.length) {
        setShowCongratulations(true);
      } else {
        navigate(`/welcome-page/${steps[nextStep].id}`);
      }
      setIsDropdownOpen(false);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      const prevStep = currentStep - 1;
      navigate(`/welcome-page/${steps[prevStep].id}`);
      setIsDropdownOpen(false);
      setCurrentStep(prevStep);
    }
  };

  const updateOnboardingStatus = async () => {
    try {
      await axiosInstance.get(`${API_URL}/v1/stores/${store_id}/onboard`);
      sessionStorage.setItem("isonboarded", "true");
      localStorage.setItem("isonboarded", "true");

      toast.success("Onboarding completed successfully!");

      // Navigate to document page after success
      navigate("/document-page");
    } catch (error) {
      toast.error("Failed to update onboarding status. Please try again.");
    }
  };

  const handleDone = () => {
    if (areAllStepsCompleted) {
       updateOnboardingStatus();
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    let selectedFile = type === "drop" ? e.dataTransfer.files[0] : e.target.files[0];

    if (selectedFile && selectedFile.type !== "application/pdf") {
      toast.error("*Only .pdf files are allowed.");
      setFile(null);
      return;
    }

    setErrorMessage("");
    setFile(selectedFile);
    startUpload(selectedFile);
  };

  const startUpload = async (uploadedFile) => {
    const maxSizeInBytes = 10 * 1024 * 1024;
    if (uploadedFile.size > maxSizeInBytes) {
      setFile(null);
      toast.error("File size exceeds the limit of 10 MB.");
      return;
    }

    const formData = new FormData();
    formData.append("file", uploadedFile);

    try {
      setIsUploading(true);
      const response = await axiosInstance.post(`${API_URL}/v1/store/documents`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const data = response.data;
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        {
          id: data.id,
          name: data.name,
          status: "Processing",
          uploaded_on: data.uploaded_on,
          url: data.url,
        },
      ]);
      setTotalFiles(totalFiles + 1);
      sessionStorage.setItem("documentUploaded", "true");
      toast.success("Document uploaded successfully!");
      refreshCompletionStatus();
      setIsUploading(false);
    } catch (error) {
      toast.error(error.response?.data?.detail || "Upload failed.");
      setIsUploading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center mt-10 font-dmsans">
      <div className="flex items-center mb-8 justify-center flex-col">
      <img src={ZenvaLogo} alt="Mail Box" className="mb-3 w-[56px] mx-auto" />
      <span className="sm:text-2xl text-xl font-semibold tracking-tight text-[#1B3331] -mt-1">Zenva <span className="text-[16px]"><sup>AI</sup></span> </span>
      </div>
      {showCongratulations ? (
        <div className="mx-auto">
          <p className="text-[#000000] text-center py-6 text-[18px] sm:text-[22px] font-medium">Setup Completed!</p>

          <div className="bg-white  sm:mt-8  flex flex-col items-center justify-center ">
            <LuPartyPopper className="text-[#39F6A3] mb-6" size={isMobileScreen? 150 : 200}/>
            <p className="text-[#868CA2] text-[16px] sm:text-[18px] font-medium">You're all set to get started</p>
            <button
              className="rounded-md my-28 bg-[#96F3C3] hover:bg-[#96F3C3]/80 cursor-pointer w-[100px] h-[30px] text-[#2B2E3A] text-[12px] sm:text-[14px] font-medium"
              onClick={handleDone}
            >
              Done!
            </button>
          </div>
        </div>
      ) : (
        <div className="w-[90%] sm:w-[80%] md:w-[74%] lg:w-[540px] mx-auto flex flex-col">
          <p className="text-[#000000] text-[18px] sm:text-[22px] font-medium text-center mb-2">Let’s setup your application</p>
          <div className="flex justify-center my-10">
            {steps.map((step, index) => (
              <div key={index} className="flex items-center">
                <div
                  className={`w-[34px] h-[34px] flex items-center justify-center rounded-full text-[#000000] font-medium text-[14px] ${
                    index < currentStep
                      ? "bg-[#39F6A3]"
                      : index === currentStep
                      ? "bg-[#39F6A3]"
                      : "bg-[#DBDBDB]"
                  }`}
                >
                  {index + 1}
                </div>
                {index < steps.length - 1 && (
                  <div
                    className={`flex-1 w-[40px] sm:w-[100px] h-[2px] mx-[4px] sm:mx-[8px] ${index <= currentStep ? "" : "bg-[#DBDBDB]"}`}
                    style={
                      index <= currentStep
                        ? {
                            background:
                              "repeating-linear-gradient(to right, #B0FBDA 0, #B0FBDA 8px, transparent 8px, transparent 16px)",
                          }
                        : {}
                    }
                  ></div>
                )}
              </div>
            ))}
          </div>
          <div className="text-center flex-grow">
            <p className="text-[16px] sm:text-[22px] font-medium mb-1 mt-3 text-[#000000]">
              {steps[currentStep]?.label}
            </p>
            {steps[currentStep]?.id === "language" && (
              <p className="text-[#676969] text-[10px] sm:text-[16px] font-medium mb-4">
                Selecting a preferred language allows you to switch email threads and AI-generated drafts into your
                chosen language, making it easier to understand and respond without relying solely on the original
                email language.
              </p>
            )}
            {steps[currentStep]?.id === "shopifyaccount" && (
              <p className="text-[#676969] text-[10px] sm:text-[16px] font-medium mb-4">
                To fetch order and product details from your Shopify store mentioned in customer email queries, please
                link your Shopify store to the application for seamless integration.
              </p>
            )}
            {steps[currentStep]?.id === "emailaccount" && (
              <p className="text-[#676969] text-[10px] sm:text-[16px] font-medium mb-4">
                To enable fetching and processing of email data for draft generation, please connect your email account
                to the application.
              </p>
            )}
            {steps[currentStep]?.id === "uploadDocument" && (
              <p className="text-[#676969] text-[10px] sm:text-[16px] font-medium mb-4">
                To provide context for generating accurate drafts, please upload documents that include your store
                policies and other relevant information.
              </p>
            )}
            <div>
              {steps.map((step, index) => (
                <div
                  key={step.id}
                  className={`step ${currentStep === index ? "visible" : "hidden"} transition-opacity duration-500`}
                >
                  {step.id === "language" && (
                    <div>
                      <div
                        className="relative border border-gray-300 rounded-lg flex mx-auto px-3 cursor-pointer py-auto h-[44px] w-[86%] sm:w-[320px]"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsDropdownOpen(!isDropdownOpen);
                        }}
                      >
                        <span className="flex items-center gap-2">
                          <LuGlobe className="text-[#000000]" size={20} />
                          <span className="text-[14px] sm:text-[16px] text-[#101828] font-normal">
                            {selectedLanguage !== "null" ? selectedLanguage : "Default Language"}
                          </span>
                        </span>
                      </div>

                      {isDropdownOpen && (
                        <ul className="shadow-md rounded-lg w-[86%] sm:w-[320px] mx-auto mt-1 text-left text-[14px] sm:text-[16px]">
                          {allowedLanguages.map((lang) => (
                            <li
                              key={lang}
                              className={`px-3 py-2 h-[40px] hover:bg-[#F9FAFB] cursor-pointer flex items-center justify-between ${
                                selectedLanguage === lang ? "bg-[#F9FAFB] text-[#101828]" : "text-[#101828]"
                              }`}
                              onClick={() => handleLanguageSelect(lang)}
                            >
                              <span>{lang}</span>
                              {selectedLanguage === lang && <FaCheck className="text-[#39F6A3]" size={14} />}
                            </li>

                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                  {step.id === "uploadDocument" && (
                    <div className="flex flex-col items-center justify-center  my-[30px]">
                      <input
                        type="file"
                        accept=".pdf"
                        onChange={(e) => handleDrop(e, "input-file")}
                        className="hidden"
                        id="fileInput"
                        disabled={!!file} 
                      />
                      <div className="flex justify-center">
                        <label
                          htmlFor="fileInput"
                          className={`${file ? "bg-white text-[#2B2E3A] border border-[#E1E1E6] min-w-[250px] sm:w-[430px] h-[60px]" : "bg-[#96F3C3] text-[#000000] w-[200px] sm:w-[282px]"
                          } font-medium rounded-xl text-[14px] sm:text-[18px] cursor-pointer min-h-[48px] text-center flex items-center justify-center`}
                        >
                          {file ? file.name : "Upload Document"}
                        </label>
                      </div>
                      {!file && <p className="text-[#868CA2] text-[10px] sm:text-xs mt-2">
                        Only PDF files are accepted, with a maximum file size of 10 MB.
                      </p>}
                    </div>
                  )}

                  {step.id === "emailaccount" && currentStep === steps.findIndex((s) => s.id === "emailaccount") && (
                    <div className="justify-center cursor-pointer min-h-[44px] my-1 pb-3 mx-2 sm:mx-6 lg:mx-0">
                      <MemoizedIntegrationPage setupMode="emailaccount" refreshCompletionStatus={refreshCompletionStatus} />
                    </div>

                  )}

                  {step.id === "shopifyaccount" && currentStep === steps.findIndex((s) => s.id === "shopifyaccount") && (
                    <div className="justify-center cursor-pointer min-h-[44px] my-1 pb-3 mx-2 sm:mx-6 lg:mx-0">
                      <MemoizedIntegrationPage setupMode="shopifyaccount" refreshCompletionStatus={refreshCompletionStatus} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className={`flex ${currentStep===0? 'justify-end':'justify-between'} pt-20 my-20`}>
            {currentStep < steps.length && (
              <>
                {currentStep > 0 && (
                  <button
                    className="rounded-md w-[100px] h-[30px] text-[#2B2E3A] text-[12px] sm:text-[14px] font-medium hover:bg-gray-100 border border-[#D0D5DD]"
                    onClick={handlePreviousStep}
                  >
                    Previous
                  </button>
                )}
                <button
                  className={`rounded-md w-[100px] h-[30px] text-[#2B2E3A] text-[12px] sm:text-[14px] font-medium ${
                    isCurrentStepCompleted
                      ? "bg-[#96F3C3] hover:bg-[#96F3C3]/80 cursor-pointer"
                      : "bg-[#96F3C3]/40 text-gray-400 cursor-not-allowed"
                  }`}
                  onClick={isCurrentStepCompleted ? handleNextStep : null}
                  disabled={!isCurrentStepCompleted}
                >
                  Next
                </button>
              </>
            )}
          </div>


        </div>
      )}
    </div>
  );
};

export default ApplicationSetup;
