import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../services/axiosInstance";
import { RxHamburgerMenu } from "react-icons/rx";
import { TbAlertTriangle } from "react-icons/tb";
import { FaUserCircle, FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { LuChevronDown,LuLogOut,LuGlobe } from "react-icons/lu";
import { AiOutlineClose } from "react-icons/ai";
import { isAuthenticated } from "../services/login";
import { openNotification, closeNotification } from '../redux/notificationSlice';
import { toggleSidebar } from '../redux/sidebarSlice';
import { LuStore } from "react-icons/lu";
import ListButtonIcon from "../assets/DraftsPageAssets/ListButtonIcon.jsx"
import { toast } from "react-toastify";
import { MdKeyboardArrowRight } from "react-icons/md";
import {getStoredValue} from "../services/logics.js"
const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState( getStoredValue("profile"));
  const storeid = getStoredValue("storeid")
  const [email, setEmail] = useState(getStoredValue("email"));
  const API_URL = process.env.REACT_APP_API_URL;
  const isSigninPage = (window.location.pathname === "/signin" || window.location.pathname === "/invite");
  const [isDropDown, setIsDropDown] = useState(false);
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const defaultLanguage = sessionStorage.getItem("preferedlanguage")
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [isClicked, setIsClicked] = useState(false);
  const [isClickedStore, setIsClickedStore] = useState(false);
  const [isClickedLanguage, setIsClickedLanguage] = useState(false);
  const [isNotification, setNotification] = useState(false);
  const [authStatus, setAuthStatus] = useState('checking');
  const isLoggedIn = getStoredValue("isLoggedIn");
  const [storesList, setStoresList] = useState([]);
  const [allowedLanguages, setAllowedLanguages] = useState([]);
  const { documents, integration } = useSelector((state) => state.notification);
  const is_super_admin = getStoredValue("is_super_admin")
  const userId = getStoredValue('id')
  const documentUploaded = getStoredValue("documentUploaded") === "true";
  const emailaccount = getStoredValue("emailaccount") === "true";
  const shopifyaccount = getStoredValue("shopifyaccount") === "true";
  useEffect(() => {
    if (documents || integration) {
      const timer = setTimeout(() => {
        dispatch(openNotification());
        setNotification(true);
      }, 2000);

      // Cleanup the timer if the component unmounts or dependencies change
      return () => clearTimeout(timer);
    }
    else{dispatch(closeNotification());}
  }, [documents, integration, dispatch]);

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuth = await isAuthenticated();
        setAuthStatus(isAuth ? "authenticated" : "unauthenticated");
      } catch (error) {
        console.error("Authentication error:", error);
        setAuthStatus("unauthenticated");
      }
    };
    if (isLoggedIn==="true") {

    checkAuth();
    } 
  }, [isLoggedIn]);
  const handleClick = () => {
    // Set the notification state to false and dispatch the closeNotification action
    setNotification(false);
    dispatch(closeNotification());
  };

  useEffect(() => {
    setUserProfile(getStoredValue("profile"));
    setEmail(getStoredValue("email"))
  }, [location.pathname]);

  const handleLogout = () => {
    // Clear token from local storage
    localStorage.clear();
    sessionStorage.clear();
    setIsDropDown(false);
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 2000); 
    localStorage.setItem("isLoggedIn", "false");
    sessionStorage.setItem("isLoggedIn", "false");
    // Redirect to login route
    window.location.href = "/signin";
  };

  const handleOutsideClick = (e) => {
    // Check if the click is outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropDown(false);
      setIsClickedStore(false);
      setIsClickedLanguage(false);
    }
  };

  useEffect(() => {
    if (isDropDown || isClickedStore || isClickedLanguage) {
      // Add event listener to detect clicks outside when dropdown is open
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      // Remove event listener when dropdown is closed
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isDropDown, isClickedStore, isClickedLanguage]);

  // Get firstname: first check sessionStorage, then fallback to localStorage
  const firstname = sessionStorage.getItem("firstname") && sessionStorage.getItem("firstname") !== "null"
    ? sessionStorage.getItem("firstname")
    : (localStorage.getItem("firstname") && localStorage.getItem("firstname") !== "null"
      ? localStorage.getItem("firstname")
      : "");

  // Get lastname: first check sessionStorage, then fallback to localStorage
  const lastname = sessionStorage.getItem("lastname") && sessionStorage.getItem("lastname") !== "null"
    ? sessionStorage.getItem("lastname")
    : (localStorage.getItem("lastname") && localStorage.getItem("lastname") !== "null"
      ? localStorage.getItem("lastname")
      : "");

  const storename = sessionStorage.getItem("storename") && sessionStorage.getItem("storename") !== "null"
      ? sessionStorage.getItem("storename")
      : (localStorage.getItem("storename") && localStorage.getItem("storename") !== "null"
        ? localStorage.getItem("storename")
        : "");
    
  const fullname = `${firstname + " " + lastname}`

  const getPageTitle = (pathname) => {
    switch (true) {
      case pathname.includes("/admin/user-page"):
        return "User Management";
      case pathname.includes("/admin/store-page"):
        return "Store Management";
      case pathname.includes("/admin/dashboard-page"):
        return "Dashboard";
    }
  };

  const fetchStoresList = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/user/stores`,
      );
      const data = response.data;
      setStoresList(data)

    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };

  const fetchAllowedLanguages = async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/v1/user/languages`,
      );
      const data = response.data;
      setAllowedLanguages(data?.languages)

    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };


  const updateUserLanguage = async (language) => {
    try {
      const response = await axiosInstance.put(
        `${API_URL}/v1/users/${userId}`,
        {
          prefered_language:language
        }
      );
      const data = response.data;
      sessionStorage.setItem("preferedlanguage",language)
      setIsClickedLanguage(false);
      toast.success(`Language updated to ${language} successfully`);
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
      setIsClickedLanguage(false);
    }
  };

  const handleToggleStore = () => {
    setIsDropDown(false);
    setIsClickedStore(!isClickedStore);
    setIsClickedLanguage(false);
    fetchStoresList();
  };

  const handleToggleLanguage = () => {
    setIsClickedLanguage(!isClickedLanguage);
    setIsClickedStore(false);
    fetchAllowedLanguages();
  };
  const handleSwitchStore = async (store_id) => {
    try {

      const res = await axiosInstance.post(
        `${API_URL}/v1/auth/stores/${store_id}/switch`
      );

      const data = res.data;

      // sessionStorage
      sessionStorage.setItem("email", data.email);
      sessionStorage.setItem("token", data.token);
      sessionStorage.setItem("expiry_in", data.expires_in);
      sessionStorage.setItem("is_super_admin", data.is_super_admin);
      // Reload or navigate to refresh the UI for the new store data
      window.location.href = '/';
    } catch (error) {
      toast.error(`${error.response?.data?.detail ||error.response?.data?.error || "Failed to switch store"}`);
    }


  };

  const closeTab = () => {
    sessionStorage.clear();
    window.opener = null;
    window.open('about:blank', '_self');
    window.close();
    setTimeout(() => {
      if (!window.closed) {
        alert('Please close this tab manually.');
      }
    }, 100);
  };
  const isWelcomePage = location.pathname.includes('/welcome-page');
  return (
    !(location.pathname.includes("/signin")) && window.location.pathname !== "/invite"  && (
      <>
       {/* Conditional Notification Div Below the Header */}
        {(is_super_admin === "True" && storeid !== "undefined") &&
          (<div className={`flex bg-gradient-to-r from-green-400 via-green-300 to-green-200 text-[#1f2937] font-semibold  text-[12px] sm:text-[14px] w-full sticky top-0 min-h-12 z-20  px-3 lg:px-8 font-dmsans items-center`}>
            <div className="flex items-center justify-start flex-grow ml-2">  {fullname} logged into store&nbsp;:&nbsp;{storename}</div>
            <button className="text-[#2B2E3A] flex items-center lg:space-x-2 justify-self-end p-2 rounded-md hover:bg-[#f0fdfa]/80" onClick={closeTab}>
            <LuLogOut size={16} style={{ strokeWidth: 3 }} />
            <span>Exit from store</span>
            </button>
          </div>
          )
        }
        {(isNotification && authStatus !== "checking" && (documents || integration)) &&
          location.pathname.includes("document") && (
            <div className={`flex bg-[#fcd34d] text-[#1f2937] text-[10px] sm:text-[12px] w-full sticky min-h-8 z-20  px-3 lg:px-8 mb-2 font-dmsans items-center`}
              style={{
                top: (is_super_admin === "True" && storeid !== "undefined") ? '48px' : '0',
              }}>
              <div className="flex items-center justify-center flex-grow">
                {/* Triangle Icon */}
                <TbAlertTriangle className="mr-2 text-opacity-50 shrink-0 w-[12px] h-[12px]" />

                {/* Notification Message */}
                <span className="text-center">
                To begin the draft generation process, please confirm that a
                  <>
                    <span
                      className="underline cursor-pointer text-[#2e1065] mx-1 font-bold"
                      onClick={() => navigate("/document-page")}
                    >
                      document,
                    </span>
                  </>
                  <>
                    a
                    <span
                      className="underline cursor-pointer text-[#2e1065] mx-1 font-bold"
                      onClick={() => navigate("/integration-page")}
                    >
                      shopify store, and an email account
                    </span>
                  </>
                  have been set up. If these are already in place, you can ignore this message.
                </span>
              </div>

              {/* Close Button */}
              <button className="text-[#2B2E3A] justify-self-end" onClick={handleClick}>
                <AiOutlineClose size={12} />
              </button>
            </div>
        )}

      <header
        className="sticky top-0 z-10 h-14 lg:h-16 bg-white border-b border-black border-opacity-10 flex items-center px-[10px] sm:px-[20px] lg:px-[32px] transition-all duration-300 font-dmsans"
      >
        <div className={` ${!isWelcomePage && (isSidebarOpen ? "lg:ml-[210px] xl:ml-[230px]" : "lg:ml-[70px]")} flex justify-between w-full`} ref={dropdownRef}>
          {/* Left Side: Hamburger Menu and Store Name */}
          <div className="flex items-center ">
            <RxHamburgerMenu className={`w-[20px] h-[20px] ${isWelcomePage && 'hidden' } lg:hidden text-[#1B1A22] block mr-3 cursor-pointer hover:bg-gray-200`}  onClick={handleToggleSidebar}/>
              {(is_super_admin === 'False' || (is_super_admin === "True" && storeid !== "undefined")) && (
                <div className="relative">
                  <button
                    className="flex items-center text-[#000000] font-medium"
                    onClick={handleToggleStore}
                    disabled={is_super_admin !== "False"}
                  >
                    <LuStore className="text-[#39F6A3] w-[20px] h-[20px]" />
                    <span className="ml-1 lg:ml-2 text-[12px] lg:text-base">{storename}</span>
                    {is_super_admin === 'False' && (
                      <LuChevronDown style={{ marginLeft: '8px' }} className="shrink-0" /> )}
                  </button>

                  {/* Store Dropdown */}
                  {isClickedStore && (
                    <div className="absolute left-0 top-8 sm:top-10  bg-white border border-[#F2F4F7] rounded-md shadow-lg z-10 min-w-[210px] max-w-[250px] lg:w-[250px] py-1">
                      {storesList.map((store, index) => (
                        <div
                          className={`flex px-3 w-full overflow-hidden gap-2 h-[40px] items-center cursor-pointer ${storeid === store?.id ? 'bg-[#EEFEF7]' : ''}`}
                          key={index}
                          onClick={() => handleSwitchStore(store?.id)}
                        >
                          <LuStore className="text-[#39F6A3] w-[20px] h-[20px]" />
                          <div className="flex-1">
                            <span className="block text-[#000000] font-medium text-xs lg:text-[13px] break-words whitespace-normal">
                              {store?.name}
                            </span>
                          </div>
                          {storeid === store?.id && (
                            <div className="flex-shrink-0">
                              <ListButtonIcon isActive={false} />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {(is_super_admin === "True" && storeid === "undefined") && (
                <div className="text-xl font-medium text-[#000000]">
                  {getPageTitle(location.pathname)}
                </div>
              )}

            </div>

            {/* Right Side: Profile and Notifications */}
            <div className="flex items-center space-x-4 lg:space-x-6">
            <div className="relative flex items-center cursor-pointer"  onClick={() => { setIsDropDown(!isDropDown); setIsClickedStore(false); }}>
              {userProfile ? (
                <img
                  src={userProfile}
                  alt="User Avatar"
                  className="w-8 h-8 rounded-full object-cover"
                />
              ) : (
                <FaUserCircle className="w-8 h-8 text-[#39F6A3] rounded-full" />
              )}

              {/* <span className="ml-2 text-gray-900 font-medium">{fullname}</span> */}
              {/* <LuChevronDown className="lg:ml-1 lg:text-black hidden lg:block" onClick={() => setIsDropDown(!isDropDown)} /> */}

              {isDropDown && (
                <div className="absolute right-0 top-10 z-10 w-[250px] bg-[#FFFFFF] border border-[#F2F4F7] rounded-md shadow-lg py-1"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="flex items-center p-2">
                    {userProfile ? (
                      <img
                        src={userProfile}
                        alt="User Avatar"
                        className="w-8 h-8 rounded-full object-cover"
                      />
                    ) : (
                      <FaUserCircle className="w-8 h-8 text-[#39F6A3] rounded-full" />
                    )}
                    <div className="ml-2 max-w-full overflow-hidden">
                      <span className="block text-[#344054] font-medium text-xs lg:text-[14px] break-words">{fullname}</span>
                      <span className="block text-[#667085] font-medium text-xs lg:text-[14px] break-all whitespace-normal">{email}</span>
                    </div>
                  </div>
                    {(is_super_admin === 'False' || (is_super_admin === "True" && storeid !== "undefined")) && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleLanguage();
                      }}
                      className={`${isClickedLanguage ? 'bg-[#D7FDED] text-[#000000]' : 'hover:bg-[#f3f4f6] text-[#868CA2]'} mt-[8px] py-[6px] text-left font-dmsans px-3 w-full text-xs lg:text-[13px] font-medium transition-colors duration-200 ease-in-out flex items-center justify-between`}
                    >
                      <span className="flex items-center gap-1">
                        <LuGlobe className="text-[#868CA2]" />
                        <span>Default language</span>
                      </span>
                      <MdKeyboardArrowRight size={17} className="text-[#868CA2]" />
                    </button>
                    )}
                    {/* Language Dropdown */}
                    {isClickedLanguage && allowedLanguages?.length > 0 && (
                      <div className="md:absolute md:right-full md:top-0 md:mt-16 bg-white md:border md:border-[#F2F4F7] md:rounded-md md:shadow-md md:w-40 py-2 md:z-20 md:mr-[1px]  md:h-[180px] md:overflow-y-auto custom-scrollbar">
                        {allowedLanguages.map((language, index) => (
                          <div
                            className={`flex text-xs lg:text-[13px] items-center justify-between px-3 py-2 cursor-pointer ${language === defaultLanguage ? 'bg-[#EEFEF7]' : ''} hover:bg-[#f3f4f6]`}
                            key={index}
                            onClick={() => updateUserLanguage(language)}
                          >
                            <span className="text-[#000000] font-medium  break-words whitespace-normal">
                              {language}
                            </span>
                            {language === defaultLanguage && (
                              <span className="text-[#000000]"><FaCheck size={12} /></span>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent dropdown from closing when clicking on the button itself
                      handleLogout(); // Add your logout logic here
                    }}
                    className={`${isClicked ? 'bg-[#D7FDED] text-[#EB1F3A]' : 'hover:bg-[#f3f4f6]  text-[#EB1F3A]'} mt-[8px] py-1 text-left font-dmsans px-3 w-full text-xs lg:text-[14px]  font-medium transition-colors duration-200 ease-in-out`}
                  >
                    Log out
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      </>
    )
  );
};

export default Navbar;
