import React, { useEffect, useRef, useState } from "react";
import '@mdxeditor/editor/style.css';
import '../../components/ExamplePageComponents/CustomTheme.css';
import {
    MDXEditor,
    toolbarPlugin,
    listsPlugin,
    linkDialogPlugin,
    linkPlugin,
    headingsPlugin,
} from '@mdxeditor/editor';
import FeaturedIcon from "../../assets/FeaturedIcon.svg";
import { IoIosArrowRoundBack } from "react-icons/io";
import LoadingAnimation from "../../assets/LoadingAnimation.svg";
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
import ExampleDetailSkeleton from "../../components/ExamplePageComponents/ExampleDetailSkeleton";
import { IoSearch } from "react-icons/io5";
import {BREAKPOINTS} from "../../services/emailUtils"
import DownArrowIcon from '../../assets/ExamplesPageAssets/DownArrowIcon.svg';
import { useParams, useNavigate,useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import debounce from "lodash/debounce";
import { toast } from "react-toastify";
import CustomToolbar from '../../components/ExamplePageComponents/CustomToolbar';
import PopupModal from "../../components/PopupModal";
import TickIcon from "../../assets/ExamplesPageAssets/TickIcon.svg";
import axiosInstance from "../../services/axiosInstance";


const CreateExamplePage = () => {
    const editorRef = useRef(null);
    const dropdownRef = useRef(null); // Ref for Action Name dropdown
    const variablesDropdownRef = useRef(null); // Ref for Variables dropdown
    const location = useLocation();
    const createDraftData = location.state?.data;
    const draft_id=location.state?.id;
    const filter=location.state?.filter;
    const [selectedOption, setSelectedOption] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [headingMarkdown, setHeadingMarkdown] = useState("");
    const [contentMarkdown, setContentMarkdown] = useState("");
    const [initialHeading, setInitialHeading] = useState("");
    const [initialContent, setInitialContent] = useState("");
    const [initialOption, setInitialOption] = useState("");
    const [showSentModal, setShowSentModal] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);
    const [isFetchingExampleTypes, setIsFetchingExampleTypes] = useState(false);
    const [isFetchingExampleDetails, setIsFetchingExampleDetails] = useState(false);
    const [isSendingExample, setIsSendingExample] = useState(false);
    const [isFetchingVariables, setIsFetchingVariables] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchVar, setSearchVar] = useState("");
    const [variables, setVariables] = useState([]);
    const [showVariables, setShowVariables] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [inputWidth, setInputWidth] = useState(344);
    const [hasChanges, setHasChanges] = useState(false); // Track changes
    const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false); // New state to track initial load
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [hasEditorInteracted, setHasEditorInteracted] = useState(false);
    const isSmallScreen = window.matchMedia(`${BREAKPOINTS.mobile}, ${BREAKPOINTS.mobileLarge}`).matches;

    // Validation states
    const [actionNameError, setActionNameError] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [messageError, setMessageError] = useState(false);

    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();

    const allPlugins = [
        toolbarPlugin({ toolbarContents: () => <CustomToolbar showVariables={showVariables} setShowVariables={setShowVariables} handleshowVariables={handleshowVariables}/> }),
        listsPlugin(),
        headingsPlugin(),
        linkPlugin(),
        linkDialogPlugin(),
    ];

    useEffect(() => {
        fetchExampleTypes();
        fetchExampleDetails();
        fetchVariableDetails();
    }, [id]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasChanges) {
                event.preventDefault();
                event.returnValue = ""; // Required for Chrome to show the prompt
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [hasChanges]);

    useEffect(() => {
        // Close dropdowns when clicking outside
        const handleClickOutside = (event) => {
            setTimeout(() => {
            if (
                (dropdownRef.current && !dropdownRef.current.contains(event.target)) ||
                (variablesDropdownRef.current && !variablesDropdownRef.current.contains(event.target))
            ) {
                setIsDropdownOpen(false);
                setShowVariables(false);
            }
        }, 300);
        };

        if (isDropdownOpen || showVariables) {
            document.addEventListener("mousedown", handleClickOutside);
          } else {
            document.removeEventListener("mousedown", handleClickOutside);
          }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen,showVariables]);

    const fetchExampleTypes = async (searchTerm = "") => {
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/store/draft/example-types`, {
                params: { search: searchTerm },
           
            });
            setOptions(response.data.types);
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
        setIsFetchingExampleTypes(false);
    };

    const fetchVariableDetails = async (searchVar) => {
        setIsFetchingVariables(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/store/draft/example-variables`, {
                params: {
                    search: searchVar,
                },
               
            });
            const data = response.data;
            setVariables(data?.variables);
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
        setIsFetchingVariables(false);
    };

    const handleCancel = () => {
        setShowDiscardModal(false);
    };
    const handleDelete = () => {
        setShowDeleteModal(false);
    };
    const handleSaveModal = () => {
        setShowSaveModal(false);
    };

    const handleSaveExample = () => {
        // Validate fields before saving
        if (!selectedOption) { setActionNameError(true); setShowSaveModal(false); }
        if (!headingMarkdown) { setTitleError(true); setShowSaveModal(false); }
        if (!contentMarkdown) { setMessageError(true); setShowSaveModal(false); }

        if (selectedOption && headingMarkdown && contentMarkdown) {
            if (id) {
                patchExampleDetails(selectedOption, headingMarkdown, contentMarkdown);
            } else {
                handleCreateExample();
            }
            setShowSaveModal(false);
        }
    };

    const fetchExampleDetails = async () => {
        setIsFetchingExampleDetails(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/store/draft/examples${id ? `/${id}` : ""}`);
            const data = response.data;
            setSelectedOption(createDraftData?createDraftData?.suggested_type : data.type);
            setHeadingMarkdown(createDraftData?createDraftData?.suggested_title:data.name);
            setContentMarkdown((createDraftData?createDraftData?.updated_content :data.template ) || "");

            // Set initial values to track changes
            setInitialOption(data.type);
            setInitialHeading(data.name);
            setInitialContent(data.template || "");
            // Mark the initial load as complete
            setIsInitialLoadComplete(true);
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
        setIsFetchingExampleDetails(false);
    };

    const patchExampleDetails = async (type, name, template) => {
        if (!id) return;
        try {
            setIsSendingExample(true);
            await axiosInstance.patch(`${API_URL}/v1/store/draft/examples/${id}`, {
                type: type,
                name: name,
                template: template
            }, );
            setHasChanges(false); // Reset change tracking after successful save
            toast.success("Example updated successfully");
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
        setIsSendingExample(false);
    };

    const handlenavigation = async () => {
        setTimeout(() => {
            navigate(`/email-page/detail/${draft_id}?filter=${filter}`);
        }, 3000); // 3000 milliseconds = 3 seconds
    }

    const handleCreateExample = async () => {
        try {
            setIsSendingExample(true);
            await axiosInstance.post(`${API_URL}/v1/store/draft/examples`, {
                type: selectedOption,
                name: headingMarkdown,
                template: contentMarkdown
            });
            setHasChanges(false); // Reset change tracking after successful save
            toast.success("Example created successfully");
            if (draft_id) {handlenavigation();}
        } catch (error) {
            toast(error.response?.data?.detail);
        }
        setIsSendingExample(false);
    };

    const handleDeleteExample = async () => {
        if(id){
        try {
            setIsDeleting(true)
            await axiosInstance.delete(`${API_URL}/v1/store/draft/examples/${id}`);
            toast.success("Example Deleted successfully");
            setShowDeleteModal(false);
            navigate("/examples-page");
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }}if (draft_id) {handlenavigation();}
        else{ navigate("/examples-page");}
        setIsDeleting(false)
    };

    const debouncedFetchExampleTypes = debounce((searchTerm) => {
        fetchExampleTypes(searchTerm);
    }, 1000);

    const handleSelectChange = (option) => {
        setSelectedOption(option);
        setActionNameError(false); // Reset error on valid input
        setIsDropdownOpen(!isDropdownOpen);
        checkForChanges(option, headingMarkdown, contentMarkdown);
        setSearchQuery("");
        debouncedFetchExampleTypes();
    };

    const handleHeadingChange = (value) => {
        setHeadingMarkdown(value);
        setTitleError(false); // Reset error on valid input
        let offsetWidth;
        if (window.matchMedia(BREAKPOINTS.tablet).matches) {
            offsetWidth = 100;
        } else {
            offsetWidth = 450;
        }
        const newWidth = Math.max(344, Math.min(value.length * 2 + 16, window.innerWidth - offsetWidth));
        setInputWidth(newWidth);
        checkForChanges(selectedOption, value, contentMarkdown);
    };

    const handleContentChange = (newMarkdown) => {
        setContentMarkdown(newMarkdown);
        setMessageError(false); // Reset error on valid input
        if (!hasEditorInteracted) {
            setHasEditorInteracted(true);
        }
        checkForChanges(selectedOption, headingMarkdown, newMarkdown);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        debouncedFetchExampleTypes(event.target.value);
    };

    const handleSearchVarChange = (event) => {
        setSearchVar(event.target.value);
        fetchVariableDetails(event.target.value);
    };

    const checkForChanges = (option, heading, content) => {
        if (isInitialLoadComplete) {
            if (
                option !== initialOption ||
                heading !== initialHeading ||
                (hasEditorInteracted &&content !== initialContent)
            ) {
                setHasChanges(true);
            } else {
                setHasChanges(false);
            }
        }
    };

    const handleBackButtonClick = () => {
        if (hasChanges) {
            setShowDiscardModal(true);
        } else {
            navigate(-1);
        }
    };

    const handleCloseSendModal = () => {
        setShowSentModal(false);
        navigate("/examples-page");
    };
    const handleshowVariables =() =>
    {
        setShowVariables(!showVariables);
        setSearchVar("");
        fetchVariableDetails();

    }

    const handleConfirmDiscard = () => {
        setShowDiscardModal(false);
        setHasChanges(false); // Reset changes tracking
        navigate(-1); // Proceed with navigation
    };

    return (
        <div className={`py-2 lg:mb-0 mb-[16px]  flex flex-col font-dmsans ${isSidebarOpen ? 'lg:ml-[240px]' : 'lg:ml-[90px]'} transition-all ease-in-out duration-500 min-h-screen`}>
            <div className="bg-white px-6  w-full  lg:h-[700px] mx-auto relative min-w-[300px] lg:min-w-[600px]">
                <div className="flex lg:mb-6">
                    <div className="flex lg:items-center lg:mx-0 -mx-[5px]">
                        <button
                            data-testid="back-button"
                            className="mr-[6px] pb-2 flex"
                            onClick={handleBackButtonClick}
                        >
                            <IoIosArrowRoundBack className="lg:w-7 lg:h-7 w-6 h-6 text-[#2B2E3A]" />
                        </button>
                        <div className="flex">
                            <h2 className="lg:text-[18px] text-base font-dmsans font-medium text-[#000000] mb-1">Example</h2>
                        </div>
                    </div>
                </div>
                {(isFetchingExampleTypes || isFetchingExampleDetails) ? (
                    <ExampleDetailSkeleton  show={true}/>
                ) : (
                    <div className={`${isSidebarOpen ? 'lg:px-6 lg:ml-4' : ' lg:px-12'}`}>
                        <div className="mb-4 flex lg:flex-row flex-col w-full md:w-[490px] h-[40px] relative">
                            <label htmlFor="actionName" className="mt-2 text-[14px] lg:text-[16px] font-medium text-[#000000] lg:w-[146px]">Action name</label>
                            <div className="flex flex-col" ref={dropdownRef}>
                                <button  id="actionName"
                                    className={`border ${actionNameError ? 'border-red-500' : 'border-gray-800'} md:w-[344px] p-2 text-[14px] focus:outline-none flex items-center justify-between ${selectedOption && 'bg-[#39F6A333]'}`}
                                    onClick={() => handleSelectChange()}
                                >
                                    {selectedOption ? (<span>{selectedOption}</span>) : (<span className="text-[#00000080] ">Action name</span>)}
                                    <img src={DownArrowIcon} alt="Down Arrow" className="ml-2" />
                                </button>
                                {actionNameError && (
                                    <p className="text-red-500 text-[10px] ml-1">Action name is required.</p>
                                )}
                            </div>
                            {isDropdownOpen && (
                                <div className="absolute lg:ml-36 top-full h-[148px] bg-white border border-gray-800 lg:mt-2 mt-[30px] rounded-md md:w-[344px] w-full z-10  overflow-y-auto custom-scrollbar overflow-x-hidden">
                                    <div className="relative border border-gray-400 mb-2 w-[94%] lg:w-[320px] h-[26px] mt-2 mx-auto flex items-center">
                                        <IoSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            className="pl-8 w-full h-full border-none focus:outline-none"
                                        />
                                    </div>
                                    <ul>
                                        {options.map((option) => (
                                            <li
                                                key={option}
                                                onClick={() => handleSelectChange(option)}
                                                className="mb-2 cursor-pointer ml-3 lg:w-[320px] w-full h-[18px] text-sm hover:bg-[#39F6A333]"
                                            >
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div className="mb-5 flex w-full lg:flex-row flex-col h-[40px] lg:mt-[0px] mt-[40px]">
                            <label htmlFor="title" className="mt-2 text-[14px] lg:text-[16px] font-medium text-[#000000] lg:w-[146px]">Title</label>
                            <div className="flex flex-col w-full lg:w-[80%] xl:max-w-[84%]">
                                <input
                                    id="title"
                                    type="text"
                                    placeholder="Title"
                                    value={headingMarkdown}
                                    onChange={(e) => handleHeadingChange(e.target.value)}
                                    className={`mt-1 text-[14px] lg:text-[16px] py-2 px-2 border ${titleError ? 'border-red-500' : 'border-[#D9D9D9]'} bg-white focus:outline-none`}
                                    style={{
                                        minWidth: `${isSmallScreen?'100%':'344px'}`,
                                        maxWidth: '100%',
                                        width:  `${isSmallScreen? '100%' :`${inputWidth}px`}`,
                                    }}
                                />
                                {titleError && (
                                    <p className="text-red-500 text-[10px] ml-1">Title is required.</p>
                                )}
                            </div>
                        </div>

                        <div className="flex mb-4 w-full lg:flex-row flex-col relative">
                            <label htmlFor="message" className="mt-2 text-[14px] lg:text-[16px] font-medium text-[#000000] lg:w-[146px]">Message</label>
                            <div className={`border ${messageError ? 'border-red-500' : 'border-gray-300'} rounded-md lg:w-[90%] w-full lg:ml-1 lg:h-[374px] h-[450px] relative font-dmsans`}>
                                <div data-testid="message-editor"  className="flex flex-col gap-2">
                                    <MDXEditor
                                        ref={editorRef}
                                        markdown={contentMarkdown || ""}
                                        className="full-demo-mdxeditor mdx-editor relative font-dmsans whitespace-pre-wrap  break-words lg:text-[16px] text-sm"
                                        contentEditableClassName={`prose max-w-full whitespace-pre-wrap  break-words overflow-y-auto custom-scrollbar font-dmsans lg:h-[320px] h-[400px]  ._linkDialogEditForm_uazmk_540 button._secondaryButton_uazmk_507  button._primaryButton_uazmk_506 ._linkDialogPopoverContent_uazmk_600 ${!contentMarkdown && 'text-gray-400'}`}
                                        plugins={allPlugins}
                                        placeholder="Type here..."
                                        onChange={handleContentChange}
                                    />
                                    {messageError && (
                                        <p className="text-red-500 text-[10px] ml-1">Message is required.</p>
                                    )}
                                </div>
                                {showVariables &&
                                    <div className="absolute top-0 right-0 sm:left-0 transform sm:translate-x-[338px] translate-y-[32px] bg-white border border-gray-800 mt-2 rounded-md w-[230px] h-[188px] z-10 overflow-y-auto custom-scrollbar overflow-x-hidden">
                                        <div className="relative border border-gray-400 mb-3 w-[94%] lg:w-[210px] h-[28px] mt-2 mx-auto flex items-center" >
                                            <IoSearch className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                                            <input
                                                type="text"
                                                placeholder="Search Variables"
                                                value={searchVar}
                                                onChange={handleSearchVarChange}
                                                className="pl-8 w-full h-full border-none focus:outline-none lg:text-[16px] text-[14px]"
                                            />
                                        </div>
                                        <ul>
                                            {variables.map((variable) => (
                                                <li
                                                    key={variable}
                                                    className="mb-2 cursor-pointer ml-3 w-[210px] h-[18px] text-sm hover:bg-[#39F6A333]"
                                                    onClick={handleshowVariables}
                                                >
                                                    <button
                                                        onClick={() => editorRef.current?.insertMarkdown(`**&lt;${variable}&gt;**`)}
                                                        className="w-full text-left"
                                                    >
                                                        {variable}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="flex justify-end gap-2 mt-6">
                            <button
                                type="button"
                                className="mr-2  bg-[#FFFFFF] border border-[#D0D5DD] text-[#2B2E3A] rounded-md shadow-sm w-[90px] lg:h-[38px] h-[30px] items-center justify-center lg:text-base text-xs"
                                onClick={() => setShowDeleteModal(true)}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                onClick={handleSaveExample}
                                disabled={isSendingExample}
                                className="px-4  text-[#2B2E3A] rounded-md shadow-sm bg-[#88FAC8] w-[90px] lg:h-[38px] h-[30px] text-center flex items-center justify-center lg:text-base text-xs"
                            >
                                {isSendingExample ? (
                                    <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-14" />
                                ) : (
                                    "Save"
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {/* Show sent Modal */}
            {showSentModal && (
                <PopupModal closeModal={handleCloseSendModal} className="bg-[#F9F9F9]">
                    <div className="flex flex-col justify-center items-center h-[180px] w-[328px]">
                        <img src={TickIcon} alt="Success Tick" className="h-[48px] w-[48px] mb-4" />
                        <p className="text-[#000000] font-dmsans text-lg mb-3">Successfully saved</p>
                        <button
                            className="w-[74px] h-[32px] rounded-[10px] text-center bg-[#39F6A366] text-[14px]"
                            onClick={handleCloseSendModal}
                        >
                            Close
                        </button>
                    </div>
                </PopupModal>
            )}

            {/* Show discard Modal */}
            {showDiscardModal  && (
                <PopupModal closeModal={handleCancel} className="bg-[#F9F9F9]">
                    <div className="flex flex-col justify-center items-center w-[300px] sm:h-[166px] sm:w-[360px]">
                        <p className="text-[#000000] font-dmsans text-base sm:text-lg mb-8 text-center">You have unsaved changes. Are you sure you want to discard them?</p>
                        <div className="flex space-x-8">
                            <button
                                className="text-[12px] font-dmsans bg-[#F3F3F3] text-[#2B2E3A] rounded-md shadow-sm w-[74px] h-[30px] items-center justify-center"
                                onClick={handleCancel}
                            >
                                Cancel
                            </button>
                            <button
                                className="w-[74px] h-[30px] rounded-[10px] bg-[#39F6A366] text-[14px]"
                                onClick={handleConfirmDiscard} // Handle discard confirmation
                            >
                                Discard
                            </button>
                        </div>
                    </div>
                </PopupModal>
            )}

            {/* Show delete Modal */}
            {showDeleteModal && (
                <PopupModal closeModal={handleDelete} className="bg-[#F9F9F9]">
                    <div className="flex flex-col justify-center items-center  w-[300px] sm:h-[200px] sm:w-[360px]">
                        <img src={FeaturedIcon} alt="" className="mb-3" />
                        <div className="text-[#101828] font-medium text-base sm:text-lg mb-2">Delete example</div>
                        <p className="text-[#667085] font-dmsans text-sm sm:text-[15px] font-normal mb-6 text-center">
                            Are you sure, you want to delete it?
                        </p>
                        <div className="flex justify-center space-x-6 w-full">
                            <button
                                className="text-sm sm:text-base font-dmsans bg-[#F3F3F3] text-[#344054] rounded-md shadow-sm w-[150px] h-[36px] sm:h-[44px] flex items-center justify-center"
                                onClick={handleDelete}
                            >
                                Cancel
                            </button>
                            <button
                                data-testid="delete-button"
                                type="button"
                                onClick={handleDeleteExample}
                                disabled={isDeleting}
                                className="w-[150px] h-[36px] sm:h-[44px] rounded-md bg-[#D92D20] text-sm sm:text-base font-medium text-[#FFFFFF] text-center flex items-center justify-center"
                            >
                                {isDeleting ? (
                                    <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10" />
                                ) : (
                                    "Delete"
                                )}
                            </button>
                        </div>
                    </div>
                </PopupModal>
            )}

            {/* Show save Modal */}
            {showSaveModal && (
                <PopupModal closeModal={handleSaveModal} className="bg-[#F9F9F9]">
                    <div className="flex flex-col justify-center items-center h-[166px] w-[340px]">
                        <p className="text-[#000000] font-dmsans text-[16px] mb-8">Do you want to save changes?</p>
                        <div className="flex space-x-8">
                            <button
                                className="text-[12px] font-dmsans  bg-[#F3F3F3] text-[#2B2E3A] rounded-md shadow-sm w-[74px] h-[30px] items-center justify-center"
                                onClick={handleSaveModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="w-[74px] h-[30px] rounded-[10px] bg-[#39F6A366] text-[14px]"
                                onClick={handleSaveExample}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </PopupModal>
            )}
        </div>
    );
};

export default CreateExamplePage;
