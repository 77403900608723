import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../services/axiosInstance";
import { useLocation } from "react-router-dom";

const OAuthFlowButton = ({ text, email, accountType }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const isWelcomePage = location.pathname.includes("/welcome-page");
  // Google Sign-In and Integration Process
  const handleGoogleSignIn = async () => {
    localStorage.setItem("accountType", accountType);
    sessionStorage.setItem("accountType", accountType);
    window.isOAuthFlow = true; // Set OAuth flag to allow unrestricted navigation

    try {
      let apiUrl = `${API_URL}/v1/store/email/connect?account_type=${accountType}`;

      if (isWelcomePage) {
        const redirectUri = `${window.location.origin}/welcome-page/emailaccount`;
        apiUrl += `&redirect_uri=${redirectUri}`;
      }
      if (text === "Reconnect" && email) {
        apiUrl += `&email=${encodeURIComponent(email)}`;
      }

      // Call backend to get the Google OAuth authorization URL for integration
      const response = await axiosInstance.get(apiUrl);
      const authorizationUrl = response.data.authorization_url;

      // Redirect the user to Google OAuth authorization URL
      window.location.href = authorizationUrl;
    } catch (error) {
      console.error("Error fetching Google OAuth URL:", error);
      window.isOAuthFlow = false; 
    }
  };

  return (
    <div className="bg-white overflow-y-auto">
      <div className="max-w-full transition-all duration-300 items-center justify-center">
        {/* Google Sign In Button */}
        <div className="flex justify-center">
          {
            text === "Reconnect" ? (
              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="w-[150px] h-[36px] md:h-[44px] rounded-md bg-[#039855] text-[14px] md:text-[16px] text-[white] font-medium text-center flex items-center justify-center"
              >
               Reconnect
              </button>
            ) : (
              <button
                className="py-[10px] w-[74px] md:w-[86px] bg-[#FFFFFF] border border-[#E7EAEE] gap-2 flex justify-center items-center md:h-[40px] h-[36px]  border-opacity-10 rounded-[6px]"
                onClick={handleGoogleSignIn}
              >
                <div className="text-[12px] md:text-[14px] font-dmsans leading-normal text-[#1E293B]">
                    {text}
                  </div>
              </button>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default OAuthFlowButton;