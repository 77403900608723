import React, { useState, useRef,useEffect } from 'react';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa6';
import VisaCardIcon from '../assets/DraftsPageAssets/VisaCardIcon.svg';
import { IoIosArrowRoundBack } from "react-icons/io";
import { formatCustomDateTime } from "../services/logics";
import { toast } from "react-toastify";
import WhiteLoadingAnimation from "../assets/WhiteLoadingAnimation.svg";
import {BREAKPOINTS} from "../services/emailUtils"
import axiosInstance from "../services/axiosInstance.js";
import SucessModal from "../components/SucessModal.jsx";
import { LuGift } from "react-icons/lu";
import PopupModal from './PopupModal.jsx';
import { FaCircleCheck } from "react-icons/fa6";
import DownArrowIcon from '../assets/ExamplesPageAssets/DownArrowIcon.svg';
import {getGiftCardValue,extractNameAndEmail,arrayBufferToBase64,getDisputeValue} from "../services/emailUtils.js"
const CustomNumberInput = ({ value, onChange, min, max, showPercentageSymbol, isChanged }) => {
  const [blocked, setBlocked] = useState(false);
  const handleIncrement = () => {
    if (value < max) {
      onChange(value + 1);
      setBlocked(false);
    } else {
      setBlocked(true);
    }
  };

  const handleDecrement = () => {
    if (value > min) {
      onChange(value - 1);
      setBlocked(false);
    }
  };

  return (
    <div className="relative flex items-center w-[50px] mx-auto">
      <input
        type="number"
        value={value}
        onChange={(e) => {
          const newValue = parseInt(e.target.value);
          if (newValue <= max && newValue >= min) {
            onChange(newValue);
            setBlocked(false);
          } else if (newValue > max) {
            setBlocked(true);
          }
        }}
        className={`no-arrows w-full text-left pl-1 border border-[#1212121A] rounded-md focus:outline-none h-[23px] pr-${showPercentageSymbol ? '6' : '2'} ${isChanged ? 'bg-[#F1F9F7]' : ''}`}
        min={min}
        max={max}
      />
      {showPercentageSymbol && (
        <span className="absolute right-4 text-[#000000] text-[11px]">%</span>
      )}
      <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex flex-col justify-center h-full">
        <button
          type="button"
          onClick={handleIncrement}
          className="flex"
          disabled={value >= max}
        >
          <FaAngleUp className={`text-[#000000] w-2 h-2.4 ${value >= max ? 'text-gray-400' : ''}`} />
        </button>
        <button
          type="button"
          onClick={handleDecrement}
          className="flex"
          disabled={value <= min}
        >
          <FaAngleDown className={`text-[#000000] w-2 h-2.4 ${value <= min ? 'text-gray-400' : ''}`} />
        </button>
      </div>
    </div>
  );
};
const RefundConfirmation = ({ showRefundModal, setShowRefundModal, orderData, refundDetails, draftData, updateDraft, draftEmail, setRefundSucess}) => {
  const [showRefundSucessModal, setShowRefundSucessModal] = useState(false);
  const products = orderData?.[0]?.line_items || [];
  const [isCreatingRefund, setIsCreatingRefund] = useState(false);
  const [isEditingPayment, setIsEditingPayment] = useState(false); // Controls the modal
  const [isUsingGiftCard, setIsUsingGiftCard] = useState(getGiftCardValue(refundDetails)); // Track whether using gift card or not
  const [tempIsUsingGiftCard, setTempIsUsingGiftCard] = useState(getGiftCardValue(refundDetails)); // Temporary state for modal
  const [expiryDate, setExpiryDate] = useState(""); // Store expiry date
  const API_URL = process.env.REACT_APP_API_URL;
  const isMobileScreen=window.matchMedia(BREAKPOINTS.mobile).matches
  // Store the initial quantity and refund percentages in useRef
  const initialQuantities = useRef(products.map(product => product.current_quantity));
  const initialRefundPercents = useRef(products.map(product => refundDetails?.percentage || 0));
  const [transactionData, settransactionData] = useState([]);
  const [selectedNumbers, setSelectedNumbers] = useState(initialQuantities.current);
  const [refundPercents, setRefundPercents] = useState(initialRefundPercents.current);
  const [refundMessages, setRefundMessages] = useState(Array(products.length).fill(""));
  // Get today's date in UTC in 'yyyy-mm-dd' format
  const todayUTC = new Date().toISOString().split('T')[0];
  const [errorMessage, setErrorMessage] = useState('');
  // Refund Dispute state
  const [disputeIssuesOptions, setDisputeIssuesOptions] = useState([]);
  const [disputeSolutionOptions, setDisputeSolutionOptions] = useState([]);
  // State to store the selected dispute issue and solution
  const [selectedDisputeIssue, setSelectedDisputeIssue] = useState({ id: "", label: "" });
  const [selectedDisputeSolution, setSelectedDisputeSolution] = useState({ id: "", label: "" });
  const [isIssueDropdownOpen, setIsIssueDropdownOpen] = useState(false);
  const [isSolutionDropdownOpen, setIsSolutionDropdownOpen] = useState(false);
  const issueDropdownRef = useRef(null);
  const solutionDropdownRef = useRef(null);
  const handleQuantityInputChange = (index, value) => {
    const newSelectedNumbers = [...selectedNumbers];
    newSelectedNumbers[index] = Math.min(Math.max(0, value), products[index].current_quantity);
    setSelectedNumbers(newSelectedNumbers);
  };
  const email = draftData?.store_email ? extractNameAndEmail(draftData.store_email)?.email || "" : "";
  // Dropdown toggle handlers
  const toggleIssueDropdown = () => setIsIssueDropdownOpen(!isIssueDropdownOpen);
  const toggleSolutionDropdown = () => setIsSolutionDropdownOpen(!isSolutionDropdownOpen);

  // Handlers for selecting dropdown options
  const handleDisputeIssueSelect = (issue) => {
    setSelectedDisputeIssue(issue);
    setIsIssueDropdownOpen(false); // Close dropdown after selection
  };

  const handleDisputeSolutionSelect = (solution) => {
    setSelectedDisputeSolution(solution);
    setIsSolutionDropdownOpen(false); // Close dropdown after selection
  };

  useEffect(() => {
    // Add click event listener
    const handleClickOutside = (event) => {
      if (issueDropdownRef.current && !issueDropdownRef.current.contains(event.target)) {
        setIsIssueDropdownOpen(false);
      }
      if (solutionDropdownRef.current && !solutionDropdownRef.current.contains(event.target)) {
        setIsSolutionDropdownOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const fetchAttachmentData = async (messageId, attachmentId) => {
    try {
      const res = await axiosInstance.get(
        `${API_URL}/v1/store/gmail/${messageId}/attachment/${attachmentId}`,
 {
          params: {email: email,},
          responseType: 'arraybuffer', // Handle binary data
        }
      );
          // // Convert the binary data (ArrayBuffer) to a base64 encoded string
          const base64String = arrayBufferToBase64(res.data);
          return { data: base64String};
    } catch (error) {
      toast.error('Failed to fetch attachment');
      return null;
    }
  };

  const handletransactionsData = async () => {
      try {
        const response = await axiosInstance.get(
          `${API_URL}/v1/store/shopify/transactions?order_id=${orderData?.[0]?.id}`,
          
        );
        const data = response.data;
        settransactionData(data);
      } catch (error) {
        console.log(error);
      }
  };


  const handleRefundPercentChange = (index, value) => {
    const originalPercent = refundDetails.percentage;
    const cappedValue = Math.min(Math.max(0, value), 100);
    const newRefundPercents = [...refundPercents];
    const newRefundMessages = [...refundMessages];

    newRefundPercents[index] = cappedValue;

    if (cappedValue !== originalPercent) {
      const difference = cappedValue - originalPercent;
      newRefundMessages[index] = difference > 0
        ? `*${difference}% higher`
        : `*${Math.abs(difference)}% lower`;
    } else {
      newRefundMessages[index] = "";
    }

    setRefundPercents(newRefundPercents);
    setRefundMessages(newRefundMessages);
  };


  const getEachDiscountAmountForLineItem = (lineItem, discountApplications, method) => {
    // Accumulate total 'each' allocation discount for the given line item
    const totalEachDiscount = lineItem.discount_allocations.reduce((total, allocation) => {
      const discountApplication = discountApplications[allocation.discount_application_index];

      // Check if the allocation method is 'each'
      if (discountApplication && discountApplication?.target_selection === method) {
        return total + parseFloat(allocation.amount);
      }
      return total;
    }, 0);

    return totalEachDiscount.toFixed(2); // Return the total formatted to 2 decimal places
  };

  // Helper function to calculate unit rate after discount
  const calculateUnitRateAfterDiscount = (product, discountApplications) => {
    const eachDiscountAmount = getEachDiscountAmountForLineItem(product, discountApplications, 'explicit');
    return (product?.price - eachDiscountAmount / product?.quantity).toFixed(2);
  };
  // Function to calculate discount across a product
  const calculateAcrossDiscountForProduct = (product, discountApplications, selectedNumber) => {
    const discountAmount = getEachDiscountAmountForLineItem(product, discountApplications, 'all');

    const calculatedAmount = (selectedNumber * discountAmount / product?.quantity);
    return (Math.ceil(calculatedAmount * 100) / 100).toFixed(2); // Format to 2 decimal places
  };

  const createRefund = async () => {
    setIsCreatingRefund(true);
    const getLocationIdForProduct = (productId, orderData) => {
      if (!orderData?.[0]?.fulfillments?.length) {
        return null;
      }
  
      for (const fulfillment of orderData?.[0].fulfillments) {
        const foundItem = fulfillment.line_items.find(
          (item) => item.product_id === productId
        );
  
        if (foundItem) {
          return fulfillment.location_id || null;
        }
      }
  
      return null;
    };
  
    const refundLineItems = products.map((product, index) => {
      const isUnfulfilled = product.fulfillment_status === null;
      const restockType = isUnfulfilled ? "cancel" : "return";
      const locationId = getLocationIdForProduct(product.product_id, orderData); // Ensure the product ID is passed correctly
  
      return {
        line_item_id: product.id,
        quantity: selectedNumbers[index],
        restock_type: restockType,
        ...(restockType === "return" && locationId ? { location_id: locationId } : {}),
        ...(restockType === "cancel" && { location_id: orderData?.[0]?.location_id } ),
      };
    });
    const transactions = products.map((product, index) => ({
      parent_id: transactionData[0]?.id,
      amount: ((
        (calculateUnitRateAfterDiscount(product, orderData?.[0]?.discount_applications)) * selectedNumbers[index]
        -
        calculateAcrossDiscountForProduct(product, orderData?.[0]?.discount_applications, selectedNumbers[index])
      ) * refundPercents[index] / 100).toFixed(2),
      kind: "refund",
      gateway: transactionData[0]?.gateway
    }));
    // Find the last attachment and capture the corresponding mail content ID
  let lastAttachment = null;
  let mailContentId = null;
  draftData?.emails?.forEach((mail) => {
    if (mail?.attachments && mail?.attachments.length > 0) {
      lastAttachment = mail?.attachments[mail.attachments.length - 1]; // Get the last attachment
      mailContentId = mail?.id; // Capture the mail content (message) ID
    }
  });

  let attachmentData = null;
  if (lastAttachment) {
    // Fetch the attachment data for the last attachment
    attachmentData = await fetchAttachmentData(mailContentId, lastAttachment.attachmentId);
  }
    const dispute_data = {
      dispute_issue:selectedDisputeIssue.id,  // Send the ID
      dispute_solution:selectedDisputeSolution.id,  // Send the ID
      ...(attachmentData ? { attachment: { name: lastAttachment.filename, data: attachmentData.data } } : {})
    };

    const payload = {
      thread_id:draftData?.emails[draftData?.emails?.length - 1]?.id,
      draft_id:draftData?.id,
      order_id: orderData?.[0]?.id,
      currency: orderData?.[0]?.currency,
      notify: true,
      note: refundDetails.reason,
      shipping: {
        full_refund: true
      },
      refund_line_items: refundLineItems,
      transactions: transactions,
      dispute_data:dispute_data,
    };

    const giftCardPayload = {
      draft_id:draftData?.id,
      thread_id: draftData?.emails[draftData?.emails?.length - 1]?.id,
      order_id: String(orderData?.[0]?.id),
      currency: orderData?.[0]?.currency,
      amount: totalRefundAmount,
      expires: expiryDate,
      // dispute_data:dispute_data,
    };
    
    let response;
    try {

      if (isUsingGiftCard) {
        // Call the gift card refund API
        response = await axiosInstance.post(`${API_URL}/v1/store/shopify/order/create_giftcard`, giftCardPayload);
      } else {
        // Call the regular refund API

        response = await axiosInstance.post(`${API_URL}/v1/store/shopify/create_refund`, payload);
      }
      const data = response.data;
      if (response.status === 200) {
        setShowRefundSucessModal(true);
        toast.success('Refund created successfully');
        setRefundSucess(true)
        updateDraft(draftEmail, true);
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || 'An error occurred while creating the refund.');
    }
    setIsCreatingRefund(false);
  };

  const handleCloseRefundModal = () => {
    setShowRefundSucessModal(false);
    setShowRefundModal(!showRefundModal);
  };

  const handleOpenPaymentEdit = () => {
    setTempIsUsingGiftCard(isUsingGiftCard); // Set temporary state based on the actual state
    setIsEditingPayment(true);
  };

  const handlePaymentUpdate = () => {
    setIsUsingGiftCard(tempIsUsingGiftCard); // Update actual state only on clicking "Update"
    setIsEditingPayment(false);
  };

  const handleCloseModal = () => {
    setIsEditingPayment(false); 
  };

  const validateDate = (date) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    // If the date format is wrong
    if (!dateRegex.test(date)) {
      setErrorMessage('Date must be in the format YYYY-MM-DD');
      return false;
    }
    // If the date is less than the minimum allowed date (todayUTC)
    if (date < todayUTC) {
      setErrorMessage('Date cannot be earlier than today');
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setExpiryDate(selectedDate);
    validateDate(selectedDate);
  };
  useEffect(() => {
    fetchDropdownOptions()
    handletransactionsData();
  },[]);

  const fetchDropdownOptions = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/v1/servicepoints/get-dispute-options`);
      const { dispute_issues, dispute_solutions } = response.data;

      // Store both ID and label for each option
      const formattedIssues = Object.entries(dispute_issues).map(([id, label]) => ({ id, label }));
      const formattedSolutions = Object.entries(dispute_solutions).map(([id, label]) => ({ id, label }));

      setDisputeIssuesOptions(formattedIssues);
      setDisputeSolutionOptions(formattedSolutions);

    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };
  useEffect(() => {
    const issueId = draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.dispute_data?.dispute_issue?.toString();
    const solutionId = draftData?.emails[draftData?.emails?.length - 1]?.drafts?.[0]?.actions_data?.dispute_data?.dispute_solution?.toString();
    // Find the matching issue and solution based on their ids
    const matchedIssue = disputeIssuesOptions?.find(issue => issue.id === issueId);
    const matchedSolution = disputeSolutionOptions?.find(solution => solution.id === solutionId);

    if (matchedIssue) {
      setSelectedDisputeIssue(matchedIssue);
    }
    if (matchedSolution) {
      setSelectedDisputeSolution(matchedSolution);
    }

  }, [ disputeIssuesOptions,disputeSolutionOptions]);
  const totalAmount = products.reduce((acc, product) =>
    acc + (
      (
        calculateUnitRateAfterDiscount(product, orderData?.[0]?.discount_applications)) * product.quantity
    ), 0).toFixed(2);

// Calculate total across discount
const totalAcrossDiscount = products.reduce((acc, product, index) =>
  acc + parseFloat(calculateAcrossDiscountForProduct(product, orderData?.[0]?.discount_applications, selectedNumbers[index])), 0).toFixed(2);

  const totalRefundCost = products.reduce((acc, product, index) =>
    acc + (
      (
        (
          (calculateUnitRateAfterDiscount(product, orderData?.[0]?.discount_applications)) * selectedNumbers[index]
        ) -
        (
          calculateAcrossDiscountForProduct(product, orderData?.[0]?.discount_applications, selectedNumbers[index])
        )
      )
    ), 0).toFixed(2);
  const totalRefundAmount = products.reduce((acc, product, index) =>
    acc + (
      ((
        (calculateUnitRateAfterDiscount(product, orderData?.[0]?.discount_applications)) * selectedNumbers[index]) -
        (calculateAcrossDiscountForProduct(product, orderData?.[0]?.discount_applications, selectedNumbers[index]))
      ) * (refundPercents[index] / 100) // Apply the refund percentage for each product
    ), 0).toFixed(2);

  const textStyle = "text-xs font-['DM Sans']";
  const boldTextStyle = `${textStyle} font-bold text-[#2b2e3a] break-words`;
  const labelStyle = `${textStyle} text-[#868ca2] font-medium w-[100px]`;
  const headerStyle = `${textStyle} font-bold text-[#121212] text-[10px] capitalize leading-[14.40px] py-2 border border-[#E1E1E6] text-center bg-[#E7E8EC]`;
  const tableDataStyle = `py-3 text-[#000000] text-[12px] font-normal border-r border-l border-[#E1E1E6] align-top`;

  const isQuantityChanged = (index) => selectedNumbers[index] !== initialQuantities.current[index];
  const isRefundPercentChanged = (index) => refundPercents[index] !== initialRefundPercents.current[index];

  return (
    <div className={`relative bg-white flex flex-col mx-[10px] w-full`}>
      <div className="flex items-center justify-between mt-3 space-x-6 h-[26px]">
        <div className="flex items-center text-[#2B2E3A] text-[14px] lg:text-[16px] font-medium font-dmsans">
          <button className="flex items-center mr-2" onClick={handleCloseRefundModal}>
            <IoIosArrowRoundBack className="w-5 h-5 lg:w-7 lg:h-7 text-[#2B2E3A]" />
          </button>
          Refund confirmation
        </div>
      </div>

      <div className="flex sm:justify-end sm:w-full sm:pr-2 mt-[18px] lg:mt-0">
        <div className="flex items-center">
          <span className="text-[#868CA2] text-sm font-medium">Status</span>
          <div className="flex items-center ml-2">
            <div className="w-2 h-2 lg:h-2.5 lg:w-2.5 bg-[#28FB03] rounded-full mr-2 animate-pulse"></div>
            <span className="text-[#2B2E3A] text-sm font-medium">{orderData?.[0]?.financial_status}</span>
          </div>
        </div>
      </div>

      <div className="relative w-[94%] sm:w-[62%] lg:w-[286px] flex items-center justify-between lg:mt-0 mt-[4px]">
        <div className="sm:ml-[8px] text-[#2b2e3a] lg:mb-0 mb-[20px] text-[12px] font-bold font-['DM Sans'] leading-[30px]">
          Refund to
        </div>
        <button onClick={handleOpenPaymentEdit} className="sm:ml-4 text-[#2E9EFF] underline text-sm lg:mb-0 mb-[20px] ">
          Edit
        </button>
      </div>

      {/* Payment method display */}
      <div className="absolute  sm:left-[10px] top-[114px] lg:top-[94px] rounded-lg border-solid border-[1.782px] border-gray-950 flex items-center px-2 py-2 w-[92%] sm:w-[60%] lg:w-[280px]">
        {isUsingGiftCard ? (
          <div className="flex-grow text-[#000000CC] text-sm font-medium font-['DM Sans']">
            Gift card refund
          </div>
        ) : (
          <div className="flex-grow text-[#000000CC] text-sm font-medium font-['DM Sans']">
            XXXX XXXX XXXX 2345
          </div>
        )}
        <div className="">
          {isUsingGiftCard ? <LuGift /> :
            <img className="h-[17px]" src={VisaCardIcon} alt={"Visa"} />}
        </div>
      </div>
      <div className="mt-[40px] sm:mt-12 lg:mt-16 sm:mx-[8px] min-h-[100px] lg:min-h-[40px]">
        {/* Heading for Raise Dispute */}
        <h2 className="text-sm font-medium text-[#2B2E3A]">Raise dispute</h2>

        {/* Flexbox container for both dropdowns */}
        <div className="flex gap-3 lg:gap-0 lg:flex-row flex-col lg:justify-between mt-2 w-full ">
          {/* Dispute Issue Dropdown */}
          <div className="relative w-full  sm:w-[60%] lg:w-[44%] xl:w-[36%]" ref={issueDropdownRef}>
            <div className="relative">
              <button
                className="w-full flex justify-between items-center py-2 px-[8px] sm:px-3 border border-[#E7EAEE] rounded-md bg-white text-left text-[#000000] text-sm min-h-[40px]"
                onClick={toggleIssueDropdown}
              >
                <span>{selectedDisputeIssue?.label || "Dispute Issue"}</span>
                <img src={DownArrowIcon} alt="Down Arrow" className="ml-2" />
              </button>
              {isIssueDropdownOpen && (
                <ul className="absolute w-full border border-gray-300 rounded-md bg-white z-10 max-h-40 overflow-y-auto custom-scrollbar">
                  {disputeIssuesOptions?.map((issue, index) => (
                    <li key={index} className="py-1 px-3 hover:bg-gray-200 cursor-pointer text-[#191D23] text-sm" onClick={() => handleDisputeIssueSelect(issue)}>{issue.label}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Dispute Solution Dropdown */}
          <div className="relative w-full sm:w-[60%] lg:w-[44%]  xl:w-[36%]" ref={solutionDropdownRef}>
            <div className="relative">
              <button className="w-full flex justify-between items-center py-2 px-[8px] sm:px-3 border border-[#E7EAEE] rounded-md bg-white text-left text-[#000000] text-sm min-h-[40px]" onClick={toggleSolutionDropdown}>
                <span>{selectedDisputeSolution?.label || "Dispute Solution"}</span>
                <img src={DownArrowIcon} alt="Down Arrow" className="ml-2" />
              </button>
              {isSolutionDropdownOpen && (
                <ul className="absolute w-full border border-gray-300 rounded-md bg-white z-10 max-h-40 overflow-y-auto custom-scrollbar">
                  {disputeSolutionOptions?.map((solution, index) => (
                    <li
                      key={index}
                      className="py-1 px-3 hover:bg-gray-200 cursor-pointer text-[#191D23] text-sm"
                      onClick={() => handleDisputeSolutionSelect(solution)}
                    >
                      {solution.label}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>



      <div className="absolute mt-[320px] sm:mt-[360px] lg:mt-[260px] w-full sm:px-[6px]">
        <div className="overflow-y-auto custom-scrollbar h-[500px]  lg:h-[530px] pr-0.5">
          <table className="table-auto w-full border-collapse min-w-[1000px] lg:min-w-[900px] xl:min-w-[600px]">
            <thead>
              <tr className="bg-[#E7E8EC]">
                <th className={headerStyle} style={{ width: isMobileScreen?'8%' :'6%' }}>S. No.</th>
                <th className={headerStyle} style={{ width: isMobileScreen?'20%':'25%' }}>Product name</th>
                <th className={headerStyle} style={{ width: '6%' }}>Original QTY.</th>
                <th className={headerStyle} style={{ width: '8%' }}>Unit rate</th>
                <th className={headerStyle} style={{ width: '8%' }}>Total rate</th>
                <th className={headerStyle} style={{ width: '6%' }}>Previous refund QTY</th>
                <th className={headerStyle} style={{ width: '8%' }}>Refund QTY.</th>
                <th className={headerStyle} style={{ width: '8%' }}>Order discount</th>
                <th className={headerStyle} style={{ width: isMobileScreen?'12%':'8%' }}>Total refund cost</th>
                <th className={headerStyle} style={{ width: '8%' }}>Refund %</th>
                <th className={headerStyle} style={{ width: isMobileScreen?'14%': '12%' }}>Total refund amount</th>
              </tr>
            </thead>
            <tbody className="h-[440px] space-y-1">
              {products.map((product, index) => (
                <tr key={product.id} className={` border-b border-[#E1E1E6] ${index === 0 ? 'pt-4' : ''}`}>
                  <td className={`${tableDataStyle} text-center  ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>{index + 1}</td>
                  <td className={`${tableDataStyle} pl-4 text-[11px] ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>{product.name}</td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>
                    {product.quantity}
                  </td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>{calculateUnitRateAfterDiscount(product,orderData?.[0]?.discount_applications)}</td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`} style={{ overflowWrap: 'break-word' }}>{(calculateUnitRateAfterDiscount(product, orderData?.[0]?.discount_applications) * product.quantity).toFixed(2)}</td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>{product.quantity - product.current_quantity}</td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'} relative`}>
                    <CustomNumberInput
                      value={selectedNumbers[index]}
                      onChange={(value) => handleQuantityInputChange(index, value)}
                      min={0}
                      max={product.current_quantity}
                      isChanged={isQuantityChanged(index)}  // Change background if modified
                    />
                  </td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>
                  {calculateAcrossDiscountForProduct(product, orderData?.[0]?.discount_applications, selectedNumbers[index])}
                  </td>

                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`} style={{ overflowWrap: 'break-word' }}>
                    {(
                      (calculateUnitRateAfterDiscount(product, orderData?.[0]?.discount_applications)) * selectedNumbers[index]
                      -
                      (
                        calculateAcrossDiscountForProduct(product, orderData?.[0]?.discount_applications, selectedNumbers[index])
                      )
                    ).toFixed(2)}
                  </td>
                  <td className={`${tableDataStyle} text-center relative ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`}>
                    <CustomNumberInput
                      value={parseInt(refundPercents[index], 10)}
                      onChange={(value) => handleRefundPercentChange(index, value)}
                      min={0}
                      max={100}
                      showPercentageSymbol
                      isChanged={isRefundPercentChanged(index)}
                    />
                    {refundMessages[index] && (<span className="absolute text-[#FF0101] text-[8px] left-1/2 top-[40px] transform -translate-x-1/2"> {refundMessages[index]}</span> )}
                  </td>
                  <td className={`${tableDataStyle} text-center ${selectedNumbers[index] === 0 ? 'text-[#868CA2]' : 'text-black'}`} style={{ overflowWrap: 'break-word' }}>
                    {(
                      (
                        (calculateUnitRateAfterDiscount(product, orderData?.[0]?.discount_applications)) * selectedNumbers[index]
                        -
                        calculateAcrossDiscountForProduct(product, orderData?.[0]?.discount_applications, selectedNumbers[index])

                      ) * refundPercents[index] / 100
                    ).toFixed(2)}
                  </td>
                </tr>
              ))}
              <tr className="h-[50px] font-bold text-[#000000] text-xs ">
                <td colSpan={4} className="text-center pr-24 py-2 border-l border-b border-t border-[#EFE4E4]">TOTAL</td>
                <td colSpan={1} className="text-center text-[13px] py-2 border-b border-t border-[#EFE4E4]" style={{ width: '90px', overflowWrap: 'break-word' }}>
                  {totalAmount}
                </td>
                <td colSpan={2} className='border-b border-t border-[#EFE4E4]'></td>
                <td colSpan={1} className="text-center text-[13px] py-2 border-b border-t border-[#EFE4E4]" style={{ width: '90px', overflowWrap: 'break-word' }}>
                  {totalAcrossDiscount}
                </td> 
                <td colSpan={1} className="text-center text-[13px] py-2 border-b border-t border-[#EFE4E4]" style={{ width: '90px', overflowWrap: 'break-word' }}>
                  {totalRefundCost}
                </td>
                <td colSpan={1} className='border-b border-t border-[#EFE4E4]'></td>
                <td colSpan={1} className="text-center px-2 text-[13px] py-2 border-b border-r border-t border-[#EFE4E4]" style={{ width: '90px', overflowWrap: 'break-word' }}>
                  {totalRefundAmount}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Buttons Container */}
        <div className="absolute mt-2 lg:py-0 py-8 right-6 flex justify-end">
          <button
            className="text-[#2B2E3ACC] bg-[#FFFFFF] border boder-[#2B2E3A80] rounded-lg shadow-sm font-dmsans px-6 py-2 mr-4  text-[12px] lg:text-sm h-[36px]"
            onClick={handleCloseRefundModal}
          >
            Cancel
          </button>
          <button
            className="bg-[#96F3C3] text-[#000000] text-[12px] lg:text-sm font-dmsans font-medium px-6 py-2 rounded-lg shadow-sm w-auto flex items-center justify-center h-[36px]"
            onClick={createRefund}
            disabled={isCreatingRefund}
          >
            {isCreatingRefund ? (
              <img src={WhiteLoadingAnimation} alt="Loading..." className="h-10 w-10 py-0" />
            ) : (
              'Confirm refund & Send email'
            )}
          </button>
        </div>
      </div>
      {showRefundSucessModal && (
        <SucessModal text={'Refund placed successfully'} handleModal={handleCloseRefundModal} />
      )}

      {/* Popup Modal for Payment Method */}
      {isEditingPayment && (
        <PopupModal closeModal={handleCloseModal} className="bg-white rounded-lg shadow-md lg:w-auto w-[96%] p-6">
          <div className="flex flex-col justify-center items-start lg:w-[640px] font-dmsans">
            <div className="text-[#101828] font-medium text-base lg:text-lg mb-4">Refund method</div>

            {/* Payment method options */}
            <div className="flex flex-col space-y-3 w-full text-[#667085] font-dmsans text-[14px] lg:text-[15px] font-normal mb-6">
              {/* Visa Card Option */}
              <label className={`flex items-center justify-between w-full p-[8px] lg:p-3 border-2 rounded-lg ${!tempIsUsingGiftCard ? 'bg-[#F5FFFC] border-[#A5FFE4]' : 'border-[#EAECF0]'}`}>
                <div className="flex items-center space-x-2">
                  <div className='w-[38px] lg:w-[46px] h-[30px] lg:h-[34px] border border-[#F2F4F7] bg-white text-black rounded-md flex items-center justify-center'>
                    <img src={VisaCardIcon} alt="Visa Icon" className="h-[16px]" />
                  </div>
                  <span className="text-[#1B1A22]">Visa ending in 2345</span>
                </div>

                {/* Checkmark */}
                <div className="relative">
                  {!tempIsUsingGiftCard ? (
                    <FaCircleCheck className='text-[#6CE9A6] h-4 w-4' />
                  ) : (
                    <div className="flex items-center justify-center border border-black h-4 w-4 rounded-full"></div>
                  )}

                  <input type="radio" name="paymentMethod" value="card" checked={!tempIsUsingGiftCard} onChange={() =>{ setTempIsUsingGiftCard(false);setErrorMessage('');setExpiryDate('');}} className="hidden" />
                </div>
              </label>

              <label className={`flex flex-col w-full p-[8px] lg:p-3 border-2 rounded-lg ${tempIsUsingGiftCard ? 'bg-[#F5FFFC] border-[#A5FFE4]' : 'border-[#EAECF0]'}`}>
                <div className="flex items-center">
                  {/* Icon aligned to the left */}
                  <div className='w-[40px] lg:w-[50px] h-[34px] border border-[#F2F4F7] bg-white text-black rounded-md flex items-center justify-center'>
                    <LuGift size={20} />
                  </div>

                  {/* Gift Card Text and Radio */}
                  <div className="ml-3 flex items-center justify-between w-full">
                    <span className="text-[#344054] font-semibold">Gift card</span>
                    {tempIsUsingGiftCard ? (
                      <FaCircleCheck className='text-[#6CE9A6] h-4 w-4' />
                    ) : (
                      <div className="flex items-center justify-center border border-black h-4 w-4 rounded-full"></div>
                    )}
                    <input type="radio" name="paymentMethod" value="giftcard" checked={tempIsUsingGiftCard} onChange={() => {
                      setTempIsUsingGiftCard(true);
                      setErrorMessage('');setExpiryDate('');
                    }}
                      className="hidden" />
                  </div>
                </div>

                {/* Expiry and Label Fields */}
                {tempIsUsingGiftCard && (
                  <div className="mt-1 flex lg:flex-row flex-col lg:justify-between items-center mx-4  pl-[20px] lg:pl-10">
                    {/* Label Input */}
                    <div className="flex flex-col space-y-1 w-full lg:w-[64%] h-[78px]">
                      <label className="text-xs text-[#344054] font-medium">Label on card</label>
                      <input
                        type="text"
                        defaultValue="Refund"
                        className="border border-[#EAECF0] rounded-md py-2 px-2 w-full focus:outline-none cursor-default"
                        readOnly
                      />
                    </div>

                    {/* Expiry Input */}
                    <div className="flex flex-col space-y-1 w-full lg:w-[36%] lg:ml-4  h-[78px]">
                      <label className="text-xs text-[#344054] font-medium">Expiry</label>
                      <input
                        type="date"
                        id="dateInput"
                        min={todayUTC}
                        max="3000-12-31"
                        onChange={handleDateChange}
                        value={expiryDate}
                        className={`border border-[#EAECF0] bg-[#FFFFFF] rounded-md py-2 px-[8px] lg:px-4 w-full focus:outline-none focus:ring-2 focus:ring-[#A5FFE4] cursor-pointer ${errorMessage ? 'border-red-500' : ''}`}
                      />
                      {errorMessage && <p className="text-[#FF0101] text-[9px] justify-center px-2 items-center">*{errorMessage}</p>}
                    </div>
                  </div>
                )}
              </label>

            </div>

            {/* Action buttons */}
            <div className="flex justify-end w-full gap-4">
              <button
                className="text-[#1B1A22] bg-transparent border border-[#344054] text-[14px] lg:text-[16px] rounded-md w-[150px] h-[38px] font-medium hover:bg-gray-100"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handlePaymentUpdate}
                className="w-[150px] h-[38px] rounded-md bg-[#96F3C3] text-[14px] lg:text-[16px]  text-[#1B1A22] font-medium "
              >
                Update
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default RefundConfirmation;