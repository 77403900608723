import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { formatDate } from "../../services/logics";
import { toast } from "react-toastify";
import axiosInstance from "../../services/axiosInstance";
import LoadingAnimation from "../../assets/LoadingAnimation.svg";
import DownArrowIcon from '../../assets/ExamplesPageAssets/DownArrowIcon.svg';
import { useSelector } from "react-redux";
import { AccountSPageShimmer } from "../../components/DocumentSkeleton"
import WhiteLoadingAnimation from "../../assets/WhiteLoadingAnimation.svg";
const AdminStorePageDetails = () => {
  const navigate = useNavigate();
  const { id: storeId } = useParams();
  const API_URL = process.env.REACT_APP_API_URL;
  const [storeData, setStoreData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isFetchingUser, setIsFetchinguser] = useState(true);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [inputEmail, setInputEmail] = useState("");
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const [storeMetrics, setStoreMetrics] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [inviteListData, setInviteListData] = useState([]);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isRoleChanging, setIsRoleChanging] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null); // State to track which user is being updated
  const [openDropdownId, setOpenDropdownId] = useState(null); // Track the open dropdown by user ID
  const dropdownRef = useRef(null);
  // const adminCount = usersData.filter(userData => userData.role_name === 'admin')?.length;
  const [isFetchingInvites, setFetchingInvites] = useState(true);
  const[isLoging,setIsLoging]=useState(false)


  useEffect(() => {
    const fetchStoreData = async () => {
    setIsFetching(true);
      try {
        const res = await axiosInstance.get(`${API_URL}/v1/admin/stores/${storeId}`, {
        });
        const data = res.data;
        setStoreData(data);
      } catch (e) {
        toast.error("Error occured while fetching data");
      }
      setIsFetching(false);
    };
    fetchStoreData();
    fetchStoreUsers();
    fetchInviteList();
  }, []);

  const handleSendInvite = async () => {
    setSendingInvite(true);
    const email = inputEmail;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email && !emailRegex.test(email)) {
      toast.error("Enter a valid email address");
      setSendingInvite(false);
      return;
    }
    const details = {
      email: email,
      store_id: storeId,
      role: "admin"
    };
    try {
      const res = await axiosInstance.post(`${API_URL}/v1/admin/invites/send`, details, {
      });
      // Clear the input field after successfully sending the invite
      setInputEmail("");
      // Set inviteSent to true
      setInviteSent(true);
      fetchStoreUsers();
      fetchInviteList();
      // Display success message
      toast.success("Invite sent successfully");
    } catch (error) {
      setInputEmail("");
      toast.error(`${error.response?.data?.detail}`);
    }

    setSendingInvite(false);
  };

  // Add event listener to close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownId(null);
      }
    }
    const delayedFunction = (event) => {
      setTimeout(() => {
        handleClickOutside(event);
      }, 300);
    };

    if (openDropdownId) {
      document.addEventListener("mousedown", delayedFunction);
    }
    return () => {
      document.removeEventListener("mousedown", delayedFunction);
    };
  }, [openDropdownId]);
  const deleteStoreHandler = async () => {
    try {
      const res = await axiosInstance.put(
        `${API_URL}/v1/admin/stores/${storeId}`,
        {
          is_active: false,
        },
      );
      const data = res.data;
      setStoreData((prev) => ({ ...prev, is_active: data.is_active }));
      toast.success(`${data.name} suspended`);
    } catch (error) {
      toast.error(`${error.response?.data?.detail || "Error while suspending Store"}`);
    }
  };

  const handleReactivateStore = async () => {
    try {
      const res = await axiosInstance.put(
        `${API_URL}/v1/admin/stores/${storeId}`,
        {
          is_active: true,
        },
      );
      const data = res.data;
      setStoreData((prev) => ({ ...prev, is_active: res.data.is_active }));
      toast.success(`${data.name} reactivated`);
    } catch (error) {
      toast.error(`${error.response?.data?.detail || "Error reactivating user"}`);
    }
  };

  useEffect(() => {
    const fetchStoreMetrics = async () => {
      try {
        const res = await axiosInstance.get(
          `${API_URL}/v1/admin/stores/${storeId}/metrices`
        );
        const data = res.data;
        setStoreMetrics(data);
      } catch (e) {
        toast.error("Error occured while fetching data");
      }
      setIsFetchinguser(false);
    };
    fetchStoreMetrics();
  }, []);

  const fetchStoreUsers = async () => {
    setIsLoadingUser(true)
    try {
      const res = await axiosInstance.get(`${API_URL}/v1/admin/stores/${storeId}/users`, {
      });
      setUsersData(res?.data)
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }

    setIsLoadingUser(false);
  };
  const handleRoleChange = async (newRole, userId, storeId) => {
    setLoadingUserId(userId); // Set the loading user ID when starting the role change
    setIsRoleChanging(true)
    try {
      // API call to update the role
      const response = await axiosInstance.put(
        `${API_URL}/v1/admin/stores/${storeId}/users/${userId}?role=${newRole}`
      );
      toast.success('Role updated successfully');
      fetchStoreUsers()

    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setIsRoleChanging(false)
    setLoadingUserId(null); // Remove loading state after completion

  };

  const fetchInviteList = async () => {
    setFetchingInvites(true)
    try {
      const res = await axiosInstance.get(`${API_URL}/v1/admin/stores/${storeId}/invites`, {
      });
      setInviteListData(res?.data)
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
    setFetchingInvites(false)

  };

  const handleResendInvite = async (inviteId) => {
    try {
      await axiosInstance.post(`${API_URL}/v1/admin/invites/${inviteId}/resend`);
      toast.success("Invite resent successfully");
      fetchInviteList();
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };

  const handleDeleteInvite = async (inviteId) => {
    try {
      await axiosInstance.delete(`${API_URL}/v1/admin/invites/${inviteId}`);
      fetchInviteList();
      toast.success("Invite canceled successfully");
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };

  const handleRemoveUser = async (userId, storeId) => {
    try {
      await axiosInstance.delete(`${API_URL}/v1/admin/stores/${storeId}/users/${userId}`);
      toast.success("User removed successfully");
      fetchStoreUsers(); // Refresh user data
    } catch (error) {
      toast.error(`${error.response?.data?.detail}`);
    }
  };
  const handleLoginStore = async () => {
    setIsLoging(true)
    try {

      const res = await axiosInstance.post(
        `${API_URL}/v1/auth/admin/stores/${storeId}/login`
      );

      const data = res.data;
      const baseUrl = window.location.origin;
      // Construct the URL with the token as a query parameter
      const signInUrl = `${baseUrl}/signin?token=${data.token}&email=${encodeURIComponent(data.email)}&expiry_in=${data.expires_in}&is_super_admin=${data.is_super_admin}`;
      const newTab = window.open(signInUrl, "_blank");

    } catch (error) {
      toast.error(`${error.response?.data?.detail ||error.response?.data?.error || "Failed to switch store"}`);
    }
    setIsLoging(false)
  };
  return (
    <div className="bg-[#FFFFFF] font-dmsans mb-12">
      {/* Back Button aligned to the left */}
      <div className={`transition-all duration-300 ${isSidebarOpen ? "ml-[260px]" : "ml-[112px]"} flex justify-start mt-2`}>
        <button className={`flex gap-[6px]`} onClick={() => navigate(-1)}>
          <IoIosArrowRoundBack className="w-6 h-6" />
          <span className="text-[#1B1A22] font-dmsans font-medium text-sm mt-[2px]">Back</span>
        </button>
      </div>

      {/* Centered Content (Store Details and other sections) */}
      <div className={`transition-all duration-300 ${isSidebarOpen ? "ml-[230px]" : "ml-[100px]"} flex justify-center`}>
        <div className="max-w-4xl w-full px-4 py-2">
          <div className="flex flex-col items-center space-y-4">
            <div className="w-full">
              <div className="flex flex-row w-full mb-4 space-x-6">
                {isFetchingUser ? <div className="bg-gray-300 w-[250px] h-[110px] rounded-lg animate-pulse"></div> :
                  <div className="flex flex-col items-start w-[250px] bg-[#FFFFFF] h-[110px] p-4 border border-[#868CA280] rounded-md">
                    <span className="font-semibold text-[#000000] font-dmsans text-[15px]">DRAFT CREATED</span>
                    <span className="font-bold font-plus-jakarta text-xl mt-1">{storeMetrics?.[0]?.total_drafts}</span>
                  </div>}
                  {isFetchingUser ? <div className="bg-gray-300 w-[250px] h-[110px] rounded-lg animate-pulse"></div> :
                  <div className="flex flex-col items-start w-[250px] bg-[#FFFFFF] h-[110px] p-4 border border-[#868CA280] rounded-md">
                    <span className="font-semibold text-[#000000] font-dmsans text-[15px]">DRAFT EDITED</span>
                    <span className="font-bold font-plus-jakarta text-xl mt-1">{storeMetrics?.[0]?.edited_drafts}</span>
                  </div>}
                {isFetchingUser ? <div className="bg-gray-300 w-[250px] h-[110px] rounded-lg animate-pulse"></div> :

                  <div className="flex flex-col  items-start w-[250px] bg-[#FFFFFF] h-[110px] p-4 border border-[#868CA280] rounded-md">
                    <span className="font-semibold text-[#000000] font-dmsans text-[15px]">DRAFT SENT</span>
                    <span className="font-bold font-plus-jakarta text-xl mt-1">{storeMetrics?.[0]?.sent_drafts}</span>
                  </div>}
                {isFetchingUser ? <div className="bg-gray-300 w-[250px] h-[110px] rounded-lg animate-pulse"></div> :

                  <div className="flex flex-col  items-start w-[250px] bg-[#FFFFFF] h-[110px] p-4 border border-[#868CA280] rounded-md">
                    <span className="font-semibold text-[#000000] font-dmsans text-[15px]">DRAFT DELETED</span>
                    <span className="font-bold font-plus-jakarta text-xl mt-1">{storeMetrics?.[0]?.deleted_drafts}</span>
                  </div>}
              </div>
              {/* Store Details */}
              <div className="py-2 mb-2 relative">
                <div className="flex justify-between items-center bg-[#D7FDED85] py-1">
                  <div className="text-base sm:text-lg text-[#1B1A22] font-medium pl-2">Store details</div>
                </div>

                {/* Profile Info */}
                <div className="grid grid-cols-1 gap-3 mt-3 relative pl-2">
                  <div className="flex items-center justify-between border-b border-[#868CA233] py-1">
                    <span className="text-sm font-medium text-[#868CA2] w-[30%]">Store ID</span>
                    {isFetching  ? <div className="w-[120px] h-4 bg-gray-300 rounded animate-pulse"></div>:
                    <div
                      className={`mt-1 text-[#2B2E3A] font-medium sm:text-[15px] min-w-[200px] sm:min-w-[300px] max-w-[70%] pr-1 bg-[#FFFFFF] focus:outline-none border-none
                  text-right text-sm`}
                      suppressContentEditableWarning={true}
                    >
                      {storeData?.store_id ? `#${storeData.store_id}` : ''}
                    </div>}
                  </div>

                  <div className="flex items-center justify-between border-b border-[#868CA233] py-1">
                    <span className="text-sm font-medium text-[#868CA2] w-[30%]">Store name</span>
                    {isFetching ? <div className="w-[120px] h-4 bg-gray-300 rounded animate-pulse"></div>:
                    <div
                      className={`mt-1 text-[#2B2E3A] text-right font-medium sm:text-[15px] min-w-[200px] sm:min-w-[300px] max-w-[70%] pr-1 bg-[#FFFFFF] focus:outline-none border-none
                  text-sm`}
                      suppressContentEditableWarning={true}
                    >
                      {storeData?.name}
                    </div>}
                  </div>

                  <div className="flex items-center justify-between border-b border-[#868CA233] py-1">
                    <span className="text-sm font-medium text-[#868CA2] w-[30%]">Client name</span>
                    {isFetching  ? <div className="w-[120px] h-4 bg-gray-300 rounded animate-pulse"></div>:
                    <div
                      className={`mt-1 text-[#2B2E3A] font-medium sm:text-[15px] min-w-[200px] sm:min-w-[300px] max-w-[70%] pr-1  bg-[#FFFFFF] focus:outline-none border-none
                      text-right text-sm`}
                      suppressContentEditableWarning={true}
                    >
                      {storeData?.client?.name}
                    </div>}
                  </div>

                  <div className="flex items-center justify-between border-b border-[#868CA233] py-1">
                    <span className="text-sm font-medium text-[#868CA2] w-[30%]">Synced on</span>
                    {isFetching  ? <div className="w-[120px] h-4 bg-gray-300 rounded animate-pulse"></div>:
                    <div
                      className={`mt-1 text-[#2B2E3A] font-medium sm:text-[15px] min-w-[200px] sm:min-w-[300px] max-w-[70%] pr-1 bg-[#FFFFFF] focus:outline-none border-none
                  text-right text-sm`}
                      suppressContentEditableWarning={true}
                    >
                      {storeData?.created_at ? formatDate(storeData?.created_at) : ""}
                    </div>}
                  </div>

                  <div className="flex items-center justify-between border-b border-[#868CA233] py-1">
                    <span className="text-sm font-medium text-[#868CA2] w-[30%]">Status</span>
                    {isFetching ? <div className="w-[120px] h-4 bg-gray-300 rounded animate-pulse"></div>:
                    <div className="text-right font-medium sm:text-[15px] min-w-[200px] sm:min-w-[300px] max-w-[70%] pr-1 bg-[#FFFFFF] border-none text-sm">
                      <span
                        className={`inline-block px-3 py-1 text-sm rounded-full ${storeData?.is_active
                          ? 'bg-[#57F0010D] text-[#59D912]'
                          : 'bg-[#4141410D] text-[#414141]'
                          }`}
                        style={{ minWidth: '80px', textAlign: 'center' }} // Ensure a consistent width for all badges
                      >
                        {storeData?.is_active ? "Active" : "In-active"}
                      </span>
                    </div>}
                  </div>
                </div>
              </div>

              {/* Store Users Section */}
              <div className="py-2 sm:py-6 relative">
                <div className="flex justify-between items-center bg-[#D7FDED85] py-1">
                  <div className="text-base sm:text-lg text-[#1B1A22] font-medium pl-2">Store users</div>
                </div>

                <div className="grid grid-cols-1 gap-2 mt-3 pl-2">
                  {/* Invite Users Section */}
                  <div className="flex flex-col gap-[8px] mb-2">
                    <h5 className="font-medium text-sm">Invite users</h5>
                    <div className="flex items-center border border-[#FCFEFF] rounded-md bg-[#F5F7F8] h-[36px] sm:h-[48px]">
                      <input
                        type="email"
                        placeholder="Enter email"
                        className="pl-3 bg-[#F5F7F8] w-[85%] h-full text-[#000000] text-[15px] focus:outline-none"
                        value={inputEmail}
                        onChange={(e) => (setInputEmail(e.target.value), setInviteSent(false))}
                      />
                      <button
                        className="ml-4 h-[30px] text-[#5D5C6A] w-[102px] text-xs sm:text-[15px] rounded-lg bg-white flex justify-center items-center"
                        onClick={handleSendInvite}
                        disabled={sendingInvite}
                      >
                        {sendingInvite ? (
                          <img src={LoadingAnimation} alt="Loading..." className="h-12 w-12" />
                        ) : (
                          "Send invite"
                        )}
                      </button>
                    </div>

                    {isLoadingUser ?
                      <AccountSPageShimmer items={2} />
                      :
                      usersData?.map((userData, index) => (
                        <div key={index} className="flex justify-between items-center bg-[#FFFFFF] h-[52px] lg:h-[60px] ">
                          {/* Profile Image */}
                          <div className="flex shrink-0 items-center">
                            <img
                              src={userData.user.profile_image}
                              alt={``}
                              className="w-7 h-7 lg:h-10 lg:w-10 rounded-full"
                            />
                            <div className="lg:ml-3 ml-2">
                              {/* Display Full Name */}
                              <div className="font-medium text-[#000000] text-sm lg:text-base">
                                {userData?.user?.first_name} {userData?.user?.last_name}
                              </div>
                              {/* Display Email */}
                              <div className="text-[#868CA2] text-xs lg:text-[13px]">{userData?.user?.email}</div>
                            </div>
                          </div>

                          {/* Role Display and Dropdown */}
                          <div className="relative " >
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setOpenDropdownId(openDropdownId === userData?.id ? null : userData?.id);
                              }}
                              className="border border-[#E7EAEE] bg-white text-[#000000] text-xs w-[90px] h-[30px] lg:w-[96px] lg:h-[40px] rounded-lg focus:outline-none flex items-center justify-center"
                              // disabled={userData?.role_name === 'admin' && adminCount === 1}
                            >
                              {loadingUserId === userData?.id && isRoleChanging ? (
                                <img src={LoadingAnimation} alt="Loading" className="lg:h-12 lg:w-16 h-10 w-10" />
                              ) : (
                                <>
                                  <span>{userData.role_name.charAt(0).toUpperCase() + userData.role_name.slice(1)}</span>
                                  <img src={DownArrowIcon} alt="Down Arrow"
                                    className="ml-2"
                                  />
                                </>
                              )}

                            </button>

                            {openDropdownId === userData.id && (
                              <ul className="absolute mt-2 bg-white border border-gray-200 right-0 lg:right-auto rounded-md shadow-md w-40 z-10" ref={dropdownRef}>
                                {userData.role_name === "admin" ? (
                                  <li
                                    onClick={() => {
                                      handleRoleChange("member", userData?.id, userData?.store_id);
                                      setOpenDropdownId(null);
                                    }}
                                    className="block px-2 py-2 text-xs text-gray-700 cursor-pointer hover:bg-gray-100"
                                  >
                                    Change role to member
                                  </li>
                                ) : (
                                  <li
                                    onClick={() => {
                                      handleRoleChange("admin", userData?.id, userData?.store_id);
                                      setOpenDropdownId(null);
                                    }}
                                    className="block px-2 py-2 text-xs text-gray-700 cursor-pointer hover:bg-gray-100"
                                  >
                                    Change role to admin
                                  </li>
                                )}
                                <li
                                  onClick={() => {
                                    handleRemoveUser(userData?.id, userData?.store_id);
                                    setOpenDropdownId(null);
                                  }}
                                  className="block px-2 py-2 text-xs text-gray-700 cursor-pointer hover:bg-gray-100"
                                >
                                  Remove from store
                                </li>
                              </ul>
                            )}
                          </div>

                        </div>
                      ))}
                    {/* Pending Invites Section */}
                    {isFetchingInvites ?
                      <AccountSPageShimmer items={2} />
                      :
                      inviteListData?.length > 0 && (
                        <div>
                          <div className="grid grid-cols-1 ">
                            {inviteListData?.map((invite) => (
                              <div key={invite?.id} className="flex justify-between items-center bg-[#FFFFFF] h-[52px] lg:h-[60px] ">
                                {/* Display invitee email and pending status */}
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 w-7 h-7 lg:h-10 lg:w-10 bg-[#1B1A22] text-uppercase rounded-full flex items-center justify-center text-[#39F6A3] text-base font-medium">
                                    {invite.email?.[0] || ''}
                                  </div>
                                  <div className="lg:ml-3 ml-2">
                                    <div className="font-medium text-[#868CA2] text-xs lg:text-sm">
                                      {invite.email}    <span className="text-[#7B7B7B] text-[10px] lg:text-xs lg:ml-5 font-normal">Pending</span>
                                    </div>
                                  </div>
                                </div>

                                {/* Resend and Cancel buttons */}
                                <div className="flex space-x-3">
                                  <button
                                    className="bg-transparent text-[10px] lg:text-xs border border-[#D0D5DD] text-[#344054] h-[28px] lg:h-[32px] w-[52px] lg:w-[88px] rounded-md"
                                    onClick={() => handleDeleteInvite(invite?.id)}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    className="bg-[#D7FDED] text-[10px] lg:text-xs text-[#1B1A22] h-[28px] lg:h-[32px] w-[52px] lg:w-[88px] rounded-md"
                                    onClick={() => handleResendInvite(invite?.id)}
                                  >
                                    Resend
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                {/* Buttons */}
                <div className="flex justify-center mt-20 py-2 space-x-4">
                 {(usersData?.length !==0 && storeData?.is_active) && <button
                    type="button"
                    className="flex items-center justify-center px-2 w-[140px] h-[40px] text-[#2B2E3A] text-[12px] lg:text-[14px] border rounded-lg border-[#D0D5DD] bg-[#88FAC8]"
                    onClick={handleLoginStore}
                    disabled={isLoging}
                  >
                    {isLoging ? (
                      <img
                        src={WhiteLoadingAnimation}
                        alt="Loading..."
                        className="h-12 w-12"
                      />
                    ) : (
                      "Login to store"
                    )}
                  </button>}

                  {/* Deactivate Button */}
                  {storeData?.is_active ? (
                    <button
                      onClick={deleteStoreHandler}
                      className="mt-auto px-4 py-2 border border-[#D0D5DD] text-[#2B2E3A] text-sm rounded-md hover:bg-[#F5F7F8]"                  >
                      Deactivate store
                    </button>
                  ) : (
                    <button
                      onClick={handleReactivateStore}
                      className="mt-auto px-4 py-2 border border-[#D0D5DD] text-[#2B2E3A] text-sm rounded-md hover:bg-[#F5F7F8]"                  >
                      Activate store
                    </button>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default AdminStorePageDetails;
