import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { debounce } from 'lodash';
import axiosInstance from '../../services/axiosInstance';

const ExampleDropdown = ({ handleExampleDraft }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [examplesData, setExamplesData] = useState([]);
  const [filteredExamples, setFilteredExamples] = useState([]);
  const [selectedExample, setSelectedExample] = useState(null);
  const [isFetching, setIsFetching] = useState(false); // Track fetching status
  const dropdownRef = useRef(null); // Create a ref for the dropdown

  // Debounced search function
  const debouncedFetchExamples = useCallback(
    debounce((term) => {
      fetchExamplesData(term);
    }, 500),
    []
  );

  // Handle search term changes
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedFetchExamples(value);
  };

  // Fetch examples when the dropdown is clicked
  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      fetchExamplesData(searchTerm);
    }
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // Filter examples based on the search term
  useEffect(() => {
    if (examplesData?.length > 0) {
      const filtered = examplesData.filter((example) =>
        example.name?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
      setFilteredExamples(filtered);
    }
  }, [searchTerm, examplesData]);

  // Fetch examples data from API
  const fetchExamplesData = async (searchTerm = '') => {
    setIsFetching(true); // Start fetching
    try {
      const response = await axiosInstance.get('/v1/store/draft/examples', {
        params: {
          search: searchTerm,
          page_size: 1000,
        },
      });
      setExamplesData(response.data.items);
    } catch (error) {
      console.error('Error fetching examples:', error);
    } finally {
      setIsFetching(false); // Stop fetching
    }
  };

  return (
    <div className="relative w-[100px] sm:w-[200px] lg:w-[260px]" ref={dropdownRef}>
      <div
        className="flex items-center  lg:gap-2 px-2 py-1 bg-white border rounded cursor-pointer"
        onClick={handleDropdownClick}
      >
        <IoDocumentTextOutline className="lg:block hidden h-4 w-4 text-[#66BB9B] shrink-0" />
        <span className='lg:text-[12px] text-[10px]'>Select example</span>
        <FaAngleDown className="ml-auto w-[8px] h-[8px] lg:h-3 lg:w-3" />
      </div>

      {isOpen && (
        <div className="absolute z-10 w-[200px] lg:right-auto right-0 md:w-full mt-1 bg-white border rounded shadow-md">
          {/* Search Box */}
          <div className="p-2">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full px-2 py-1 border rounded bg-white focus:outline-none"
            />
          </div>

          {/* Show shimmer animation when fetching */}
          {isFetching  ? (
            <ul className="max-h-[180px] overflow-y-auto">
              {[...Array(4)].map((_, index) => (
                <li key={index} className="px-2  py-2">
                  <div className="w-full h-4 bg-gray-300 animate-pulse rounded"></div>
                </li>
              ))}
            </ul>
          ) : (
            /* Example List */
            <ul className="max-h-[180px] min-h-[80px] overflow-y-auto custom-scrollbar">

              {filteredExamples?.length > 0 ? (
                filteredExamples.map((example, index) => (
                  <li
                    key={index}
                    className="pl-2 pr-[1px] py-2 font-dmsans hover:bg-gray-100 cursor-pointer text-[#868CA2] text-[10px] lg:text-xs hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#2B7B65] hover:via-[#66BB9B] hover:to-[#99F2C8]"
                    onClick={() => {
                      setSelectedExample(example);
                      setIsOpen(false);

                      // Call handleExampleDraft with example_id when an example is selected
                      handleExampleDraft(example.id); // Ensure you pass the correct property for `example_id`
                    }}
                  >
                    {example.name}
                  </li>
                ))
              ) : (
                <li className="px-4 text-center py-2 text-gray-500">No examples found</li>
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default ExampleDropdown;
