import React, { useEffect, useState } from "react";
import '@mdxeditor/editor/style.css';
import '../../components/ExamplePageComponents/CustomTheme.css';
import {
    MDXEditor,
    listsPlugin,
    linkDialogPlugin,
    linkPlugin,
    headingsPlugin
} from '@mdxeditor/editor';
import { IoIosArrowRoundBack } from "react-icons/io";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import ExampleDetailSkeleton from "../../components/ExamplePageComponents/ExampleDetailSkeleton";
import axiosInstance from "../../services/axiosInstance";
import { getStoredValue } from "../../services/logics";
const allPlugins = [
    listsPlugin(),
    headingsPlugin(),
    linkPlugin(),
    linkDialogPlugin(),
];

const ExampleDetailPage = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [isFetchingExampleDetails, setIsFetchingExampleDetails] = useState(false);
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [headingMarkdown, setHeadingMarkdown] = useState("");
    const [contentMarkdown, setContentMarkdown] = useState("");
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const role = getStoredValue("role");

    const fetchExampleDetails = async () => {
        setIsFetchingExampleDetails(true);
        try {
            const response = await axiosInstance.get(
                `${API_URL}/v1/store/draft/examples/${id}`);
            const data = response.data;
            setSelectedOption(data.type);
            setHeadingMarkdown(data.name);
            setContentMarkdown(data.template);
        } catch (error) {
            toast.error(`${error.response?.data?.detail}`);
        }
        setIsFetchingExampleDetails(false);
    };

    useEffect(() => {
        fetchExampleDetails();
    }, [id]);

    return (
        <div className={`py-2 overflow-y-auto flex font-dmsans flex-col ${isSidebarOpen ? 'lg:ml-[230px] px-6' : 'lg:ml-[60px] px-6 lg:px-12'} transition-all ease-in-out duration-500 `}>
            <div className="flex lg:items-center mb-4 lg:mx-2 -mx-[5px]">
                <button
                    className="lg:pb-2 mr-[6px] flex"
                    onClick={() => navigate(-1)}
                >
                    <IoIosArrowRoundBack className="lg:w-7 lg:h-7 w-6 h-6 text-[#2B2E3A]" />
                </button>
                <div className="flex">
                    <h2 className="lg:text-[18px] text-base font-dmsans fon-tmedium text-[#000000] mb-1">Example</h2>
                </div>
            </div>
            {isFetchingExampleDetails ? (
                <ExampleDetailSkeleton show={false}/>
            ) : (
                <>
                    <div className={`${isSidebarOpen ? 'lg:px-6 lg:ml-4' : 'lg:px-12'}`}>
                        <div className="mb-4 flex lg:flex-row flex-col lg:w-[490px] lg:h-[40px] w-full relative">
                            <label htmlFor="actionName" className="mt-2  lg:text-base text-[12px] font-medium text-[#868CA2] lg:text-[#000000] lg:w-[146px]">Action name</label>
                            <div className="flex flex-col">
                                <div
                                    className=" lg:w-[344px] lg:p-2 lg:text-base lg:mt-[0px] mt-[14px] text-[14px] text-[#000000] flex items-center"
                                >
                                    {selectedOption}
                                </div>

                            </div>
                        </div>

                        <div className="mb-4 flex lg:flex-row flex-col w-full lg:h-[40px] ">
                            <label htmlFor="title" className="lg:mt-2 lg:text-base text-[12px] font-medium text-[#868CA2] lg:text-[#000000] lg:w-[146px]">Title</label>
                            <div className="flex flex-col lg:max-w-[84%] lg:mt-[0px] mt-[14px] lg:py-2 lg:px-2 lg:text-base text-[14px] ">
                                <div>{headingMarkdown}</div>

                            </div>
                        </div>

                        <div className="flex mb-3 w-full relative lg:flex-row flex-col">
                            <label htmlFor="message" className="lg:mt-2 lg:text-base text-[12px] font-medium text-[#868CA2] lg:text-[#000000] lg:w-[146px]">Message</label>
                            <div className={` rounded-md lg:w-[90%] relative font-dmsans lg:mt-[0px] mt-[14px]`}>
                                <div data-testid="message-editor" className="flex flex-col gap-2 ">
                                    <MDXEditor
                                        markdown={contentMarkdown}
                                        plugins={allPlugins}
                                        readOnly={true}
                                        className="full-demo-mdxeditor mdx-editor whitespace-pre-wrap  break-words lg:text-base text-[14px] "
                                        contentEditableClassName="prose max-w-full font-dmsans whitespace-pre-wrap  break-words p-[0px] lg:p-[12px]"
                                    />
                                </div>

                            </div>
                        </div>

                    </div>

                    {role?.toLowerCase() === "admin" && <div className="flex justify-end pb-6 gap-2 ">
                        <button
                            type="button"
                            onClick={() => navigate(`/create-example/${id}`)}
                            className="px-4 gap-1 text-[#2B2E3A] font-dmsans rounded-md shadow-sm bg-[#88FAC8] w-[90px] h-[30px] lg:h-[34px] text-center flex items-center justify-center"
                        >

                            Edit
                        </button>
                    </div>}
                </>
            )}
        </div>
    );
};

export default ExampleDetailPage;
