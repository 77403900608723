import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  // const error = useRouteError();  
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-[10px] lg:px-[0px]">
      <div className="max-w-md text-center border-red-400 border-[1px] p-5 bg-red-100 rounded-xl">
        <h1 className=" font-dmsans text-2xl sm:text-4xl font-bold text-gray-800 mb-4">
          Ooops!The page you're looking for doesn't exist.
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          {/* {error.statusText || error.message} */}
        </p>
        <button
          onClick={handleGoBack}
          className="bg-primary-300 text-secondary-400  font-dmsans px-6 py-3 rounded-md hover:bg-primary-700 transition-colors duration-300"
        >
          Go back
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;